/** @jsxImportSource theme-ui */
import * as React from "react";
import { nessiePropCheckerWrapper } from "./nessiePropCheckerWrapper";
import {
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
  NessieFunctionComponent,
  NessieComponentProps,
} from "./propTypes";
import { NessieThemeColors } from "./theme";

import { RAW_cssValue } from "../../nessie/stylingLib";
import UnstyledButton from "../../components/buttons/UnstyledButton";

type NessieIconSizes = keyof typeof SIZES;

type RoundedIconProps = NessieComponentProps & {
  icon: React.ReactElement;
  size?: NessieIconSizes;
  color?: NessieThemeColors;
  backgroundColor?: NessieThemeColors;
  cursor?: string;
  onClick?: React.MouseEventHandler;
  "data-test-name"?: string;
};

export let RoundedIcon: NessieFunctionComponent<RoundedIconProps> = ({
  icon,
  size = "m",
  backgroundColor,
  cursor,
  onClick,
  ["data-test-name"]: dataTestName,
}) => {
  let padding = "s";
  if (size === "l") padding = "s";
  if (size === "m") padding = "s";
  if (size === "s") padding = "xs";
  if (size === "xs") padding = RAW_cssValue("5px");
  const ButtonOrDivComponent = onClick ? UnstyledButton : "div";
  return (
    <ButtonOrDivComponent
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor,
        borderRadius: RAW_cssValue("50%"),
        padding,
        cursor,
        position: "relative",
      }}
    >
      {React.isValidElement(icon) && icon}
    </ButtonOrDivComponent>
  );
};

const SIZES = { xs: "12px", s: "18px", m: "24px", l: "36px", xl: "48px", xxl: "56px" };
RoundedIcon.displayName = "RoundedIcon";

RoundedIcon.nessiePropTypes = {
  backgroundColor: colorNessieResponsivePropType,
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
};

// ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking
if (process.env.NODE_ENV === "development") {
  RoundedIcon = nessiePropCheckerWrapper(RoundedIcon);
}
