/** @jsxImportSource theme-ui */
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { StylingLibCSSObject } from "../../nessie/stylingLib";

type SwitchProps = { checked: boolean; onCheckedChange: (value: boolean) => void; id?: string; disabled?: boolean };

export const Switch = ({ checked, ...rest }: SwitchProps) => {
  return (
    <SwitchPrimitive.Root defaultChecked checked={checked} sx={switchRootStyle} {...rest}>
      <SwitchPrimitive.Thumb sx={switchThumbStyle} />
    </SwitchPrimitive.Root>
  );
};

const switchRootStyle: StylingLibCSSObject = {
  all: "unset",
  width: "54px",
  height: "30px",
  backgroundColor: "dt_taro50",
  borderRadius: "dt_radius_l",
  position: "relative",
  ":focus-visible": {
    outline: "2px solid rgb(0, 95, 204)",
  },
  '&[data-state="checked"]': { backgroundColor: "dt_kiwi60" },
  ":disabled": {
    cursor: "not-allowed",
  },
};

const switchThumbStyle: StylingLibCSSObject = {
  display: "block",
  width: "24px",
  height: "24px",
  backgroundColor: "white",
  borderRadius: "dt_radius_l",
  transition: "transform 100ms",
  transform: "translateX(3px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(27px)" },
};
