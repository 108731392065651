/** @jsxImportSource theme-ui */
import * as React from "react";
import ReactModal from "react-modal";
import { NessieSpaceSizes, theme } from "./theme";
import { Title } from "./Text";
import Stack from "./evergreenLib/Stack";
import { ClassNames, GlobalCSS, CSSInterpolation, RAW_cssValue } from "../../nessie/stylingLib";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import translate from "../../utils/translate";
import { CloseIcon } from "../icons";

type ModalProps = {
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose?: () => void;
  overlayStyleOverrides?: CSSInterpolation;
  "data-test-name"?: string;
  label?: string;
};

export const Modal = ({
  children,
  isOpen,
  onRequestClose,
  shouldCloseOnOverlayClick,
  overlayStyleOverrides = {},
  label,
  ...otherProps
}: React.PropsWithChildren<ModalProps>) => {
  return (
    <>
      {/* Avoid the body to scroll behind the modal*/}
      <GlobalCSS
        styles={{
          ".ReactModal__Body--open": {
            overflow: "hidden",
          },
        }}
      />
      <Stack value={theme.stackingOrder.OVERLAY}>
        {(zIndex) => (
          <ClassNames>
            {({ css, cx }) => {
              // This overrides styles for the div react-modal uses for the overlay
              const overlayClassName = css({
                backgroundColor: theme.colors.shaleOpacity1,
                position: "fixed",
                // outline: "none",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
              });

              // This overrides styles for the div react-modal uses to render the content.
              const contentClassName = css({
                // padding - So when the window is raelly small we still see a bit of the overlay on the sides.
                // For some reason the bottom padding does not work ?
                padding: "22px",
                position: "relative",
                // outline: "none",
                display: "flex",
                flexDirection: "column",
                flexShrink: 0,
                flexGrow: 1,
              });

              return (
                <ReactModal
                  data={
                    otherProps["data-test-name"] && {
                      "test-name": otherProps["data-test-name"],
                    }
                  }
                  ariaHideApp={false}
                  onRequestClose={onRequestClose}
                  overlayClassName={cx(overlayClassName, css({ zIndex }), css(overlayStyleOverrides))}
                  className={contentClassName}
                  isOpen={isOpen}
                  shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                  contentLabel={label}
                >
                  {children}
                </ReactModal>
              );
            }}
          </ClassNames>
        )}
      </Stack>
    </>
  );
};

// -----------------------------------
// Modal Content

type ModalContentProps = {
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  padding?: NessieSpaceSizes;
};

export const ModalContent = ({
  children,
  width,
  maxWidth,
  minWidth,
  padding = "l",
}: React.PropsWithChildren<ModalContentProps>) => (
  <div
    sx={{
      // Just setting a min height for easy debugging when working with a modal.
      minHeight: "100px",
      padding,
      border: "1px solid",
      borderColor: "dt_taro30",
      backgroundColor: "dt_white",
      borderRadius: RAW_cssValue("42px"),
      // Since this item is going to live inside a flex container the margin will behave as specified for
      // flex items. Check more here: https://stackoverflow.com/questions/32551291/in-css-flexbox-why-are-there-no-justify-items-and-justify-self-properties/33856609#33856609
      margin: "auto",
      width: width ? width : "100%",
      maxWidth: maxWidth ? maxWidth : "500px",
      minWidth: minWidth ? minWidth : undefined,
      position: "relative",
    }}
  >
    {children}
  </div>
);

// --------------------------------------------
// ModalConfirDialog

export const ModalConfirmDialog = ({ children }: { children?: React.ReactNode }) => (
  <ModalContent maxWidth="500px">{children}</ModalContent>
);

// --------------------------------------------
// ModalFooterActions
export const ModalFooterActions = ({ children }: { children?: React.ReactNode }) => {
  if (React.Children.count(children) > 1) {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          position: "relative",
          flexWrap: "wrap",
        }}
      >
        {React.Children.map(children, (node) => (
          <div sx={{ position: "relative" }}>{node}</div>
        ))}
      </div>
    );
  } else {
    return (
      <div sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative" }}>
        {React.Children.map(children, (node) => node)}
      </div>
    );
  }
};

// ---------------------------------------------
// Modal Title
export const ModalTitle = ({
  children,
  as,
}: {
  children?: React.ReactNode;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}): JSX.Element => (
  <Title as={as} textAlign="center">
    {children}
  </Title>
);

// -------------------------------------------------
// Modal close button

const CLOSE_POSITION = 22;

type ModalCloseButtonProps = {
  right?: boolean;
  onClick?: () => void;
  closePosition?: number;
};

export const ModalCloseButton = ({ onClick, right = false, closePosition = CLOSE_POSITION }: ModalCloseButtonProps) => {
  return (
    <UnstyledButton
      sx={{
        position: "absolute",
        zIndex: 1,
        top: closePosition,
        ...(right ? { right: closePosition, float: "right" } : { left: closePosition }),
      }}
      data-test-name="nessieModalCloseButton"
      aria-label={translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
      onClick={onClick}
    >
      <CloseIcon color="dt_taro60" />
    </UnstyledButton>
  );
};

type ModalCircledCloseButtonProps = {
  right?: boolean;
  onClick?: () => void;
};

export const ModalCircledCloseButton = ({ onClick, right = false }: ModalCircledCloseButtonProps) => (
  <UnstyledButton
    sx={{
      position: "absolute",
      zIndex: 1,
      ...(right ? { right: CLOSE_POSITION, float: "right" } : { left: CLOSE_POSITION }),
      top: CLOSE_POSITION,
      padding: "dt_xxs",
    }}
    data-test-name="nessieModalCircledCloseButton"
    aria-label={translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
    onClick={onClick}
  >
    <CircledCloseIcon />
  </UnstyledButton>
);

// ----------------------------------------
// CircledCloseIcon

export const CircledCloseIcon = () => (
  <div
    sx={{
      backgroundColor: "dt_taro10",
      display: "inline-block",
      cursor: "pointer",
      borderRadius: RAW_cssValue("50%"),
      padding: "dt_m",
      lineHeight: 0,
      position: "relative",
      ":hover": {
        backgroundColor: "dt_taro30",
      },
    }}
  >
    <CloseIcon sx={{ ":hover": { color: "dt_taro40" } }} />
  </div>
);
