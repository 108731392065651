/** @jsxImportSource theme-ui */
// eslint-disable-next-line no-restricted-imports
import { useBreakpointIndex } from "@theme-ui/match-media";

// TODO: Get these from the theme
const BREAKPOINTS_KEYS = [
  "dt_viewport_xs",
  "dt_viewport_s",
  "dt_viewport_m",
  "dt_viewport_l",
  "dt_viewport_xl",
] as const;

/**
 * Returns the current theme breakpoint name based on the page dimensions
 */
export const useThemeBreakpoint = ():
  | "dt_viewport_xs"
  | "dt_viewport_s"
  | "dt_viewport_m"
  | "dt_viewport_l"
  | "dt_viewport_xl" => {
  const breakpointIndex = useBreakpointIndex();
  const breakpointName = BREAKPOINTS_KEYS[breakpointIndex];

  if (!breakpointName) {
    throw new Error("The current breakpoint doesn't match a know breakpoint name");
  }

  return breakpointName;
};
