/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type MathIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let MathIcon: NessieFunctionComponent<MathIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.006 3H5.994A2.987 2.987 0 003 5.994v12.012A2.987 2.987 0 005.994 21h12.012A2.987 2.987 0 0021 18.006V5.994C21 4.334 19.63 3 18.006 3zm-8.08 14.14c.144.145.216.289.216.433 0 .144-.072.289-.216.433-.144.144-.289.216-.433.216-.144 0-.289-.072-.433-.216L8.014 16.96l-1.19 1.19a.463.463 0 01-.361.145.58.58 0 01-.397-.18c-.144-.145-.216-.29-.216-.434 0-.144.036-.288.144-.36l1.19-1.19-1.118-1.119a.463.463 0 01-.144-.36c0-.145.072-.29.216-.434.145-.144.289-.216.433-.216.144 0 .289.036.36.144l1.12 1.118 1.117-1.118c.145-.144.289-.216.47-.216.144 0 .288.072.432.18.108.108.18.253.18.433a.698.698 0 01-.216.469L8.916 16.13l1.01 1.01zm.757-8.765a.686.686 0 01-.469.18H8.627v1.515c0 .217-.072.36-.18.47a.623.623 0 01-.469.18c-.18 0-.325-.037-.433-.18a.623.623 0 01-.18-.47V8.555H5.669c-.144 0-.252-.072-.36-.18a.597.597 0 01-.18-.433c0-.18.035-.325.18-.433.108-.108.216-.18.36-.18h1.696V5.74c0-.144.072-.252.18-.36.108-.108.253-.145.433-.145.216 0 .36.037.469.145.108.108.18.216.18.36V7.33h1.587c.217 0 .361.072.47.18.108.108.18.253.18.433-.036.18-.072.325-.18.433zm7.72 9.523a.58.58 0 01-.397.18h-3.643c-.108 0-.217-.072-.325-.18-.108-.108-.144-.253-.144-.433s.036-.325.144-.433c.108-.108.216-.18.325-.18h3.643c.18 0 .325.072.397.18.108.108.144.253.144.433s-.036.325-.144.433zm0-2.525a.579.579 0 01-.397.18h-3.643c-.108 0-.217-.072-.325-.18-.108-.108-.144-.253-.144-.433s.036-.325.144-.433c.108-.108.216-.18.325-.18h3.643c.18 0 .325.036.397.18.108.108.144.252.144.433 0 .18-.036.325-.144.433zm.252-6.998a.58.58 0 01-.396.18h-3.896c-.108 0-.217-.072-.325-.18-.108-.108-.144-.253-.144-.433s.036-.325.144-.433c.108-.108.216-.18.325-.18h3.896c.18 0 .324.072.396.18.108.108.145.253.145.433s-.037.325-.145.433z"
          />
          <mask maskUnits="userSpaceOnUse" x={3} y={3} width={18} height={18}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.006 3H5.994A2.987 2.987 0 003 5.994v12.012A2.987 2.987 0 005.994 21h12.012A2.987 2.987 0 0021 18.006V5.994C21 4.334 19.63 3 18.006 3zm-8.08 14.14c.144.145.216.289.216.433 0 .144-.072.289-.216.433-.144.144-.289.216-.433.216-.144 0-.289-.072-.433-.216L8.014 16.96l-1.19 1.19a.463.463 0 01-.361.145.58.58 0 01-.397-.18c-.144-.145-.216-.29-.216-.434 0-.144.036-.288.144-.36l1.19-1.19-1.118-1.119a.463.463 0 01-.144-.36c0-.145.072-.29.216-.434.145-.144.289-.216.433-.216.144 0 .289.036.36.144l1.12 1.118 1.117-1.118c.145-.144.289-.216.47-.216.144 0 .288.072.432.18.108.108.18.253.18.433a.698.698 0 01-.216.469L8.916 16.13l1.01 1.01zm.757-8.765a.686.686 0 01-.469.18H8.627v1.515c0 .217-.072.36-.18.47a.623.623 0 01-.469.18c-.18 0-.325-.037-.433-.18a.623.623 0 01-.18-.47V8.555H5.669c-.144 0-.252-.072-.36-.18a.597.597 0 01-.18-.433c0-.18.035-.325.18-.433.108-.108.216-.18.36-.18h1.696V5.74c0-.144.072-.252.18-.36.108-.108.253-.145.433-.145.216 0 .36.037.469.145.108.108.18.216.18.36V7.33h1.587c.217 0 .361.072.47.18.108.108.18.253.18.433-.036.18-.072.325-.18.433zm7.72 9.523a.58.58 0 01-.397.18h-3.643c-.108 0-.217-.072-.325-.18-.108-.108-.144-.253-.144-.433s.036-.325.144-.433c.108-.108.216-.18.325-.18h3.643c.18 0 .325.072.397.18.108.108.144.253.144.433s-.036.325-.144.433zm0-2.525a.579.579 0 01-.397.18h-3.643c-.108 0-.217-.072-.325-.18-.108-.108-.144-.253-.144-.433s.036-.325.144-.433c.108-.108.216-.18.325-.18h3.643c.18 0 .325.036.397.18.108.108.144.252.144.433 0 .18-.036.325-.144.433zm.252-6.998a.58.58 0 01-.396.18h-3.896c-.108 0-.217-.072-.325-.18-.108-.108-.144-.253-.144-.433s.036-.325.144-.433c.108-.108.216-.18.325-.18h3.896c.18 0 .324.072.396.18.108.108.145.253.145.433s-.037.325-.145.433z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MathIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  MathIcon = nessiePropCheckerWrapper(MathIcon);
}

export default MathIcon;
