/** @jsxImportSource theme-ui */
import get from "lodash/get";
import { isTesting } from "../../../utils/env";
import { NessieTheme } from "../../components/theme";
import { StylingLibCSSObject } from "..";

type NessieThemeKey = keyof NessieTheme;
type NessieThemeValue = NessieTheme[NessieThemeKey];

export type CSSPropName = keyof StylingLibCSSObject;
export type CSSPropValue = StylingLibCSSObject[CSSPropName];

type ValidationContext = {
  errors: {
    cssPropName: CSSPropName;
    cssPropValue: CSSPropValue;
    options: string[];
    scale: string;
  }[];
};

/**
 * Checks if the specified `cssPropName` is restricted to use design tokens from the theme,
 * and if so, validates that the specified `cssPropValue` is a valid design token.
 * If the provided `cssPropValue` is not valid, it throws an error.
 */
export const validateCssPropValue = (
  cssPropName: CSSPropName,
  cssPropValue: CSSPropValue | undefined,
  validationContext: ValidationContext,
) => {
  // check if prop needs to lookup theme values
  if (cssPropValue != null && SCALES[cssPropName]) {
    // f.i width has no scale constraint therefore we won't enter this branch
    // Maybe it was already a function? what to do in that case?
    // <div css={{ color: ['red' , *** (theme) => '' ], backgroundColor: (theme) => '' }} />

    let prevScaleValue: NessieThemeValue | undefined = undefined;

    // to validate prop, we need to return a function that will be invoked by emotion with the theme.
    // this will allows us to check if the provided cssPropValue is defined in the theme.
    return (theme: NessieTheme) => {
      const scale: NessieThemeValue = get(theme, SCALES[cssPropName] /* space, colors, etc */);

      // Only run validation whenever the scale value changes, as this
      // can be re-rendered by the NessieValidationErrorPanel components.
      // If for some reason the scale value changes (because the theme changed),
      // then reset the errors and re-run the validation
      if (prevScaleValue === undefined || scale != prevScaleValue) {
        prevScaleValue = scale;
        // @stylingLib-revisit: figure out how to reset the validation errors without
        // affecting other prop validation errors for the same element
        // validationContext.errors = [];

        if (Array.isArray(cssPropValue)) {
          cssPropValue.forEach((toCheck) => {
            // ignore `null` values that are being used in responsive values array
            if (toCheck !== null) {
              validateThemeScaleValue(scale, cssPropName, toCheck, validationContext);
            }
          });
        } else {
          validateThemeScaleValue(scale, cssPropName, cssPropValue, validationContext);
        }
      }

      return cssPropValue;
    };
  }

  return cssPropValue;
};

/**
 * Checks if `cssPropValue` is an existing design token name from the theme.
 * If the value is not found, it throws an error
 */
const validateThemeScaleValue = (
  scale: NessieThemeValue,
  cssPropName: CSSPropName,
  cssPropValue: CSSPropValue,
  validationContext: ValidationContext,
) => {
  const scaleValue =
    typeof cssPropValue === "string" || typeof cssPropValue === "number" ? get(scale, cssPropValue) : null;

  // check if value is `null` or `undefined` so that we allow theme values to be other "falsy" values
  if (scaleValue == null) {
    validationContext.errors.push({
      cssPropName,
      cssPropValue,
      // eslint-disable-next-line local-rules/no-dynamic-tokens
      options: Object.keys(scale).filter((key) => key.startsWith("dt_")),
      scale: SCALES[cssPropName],
    });

    // When running the unit tests, we haven't found a way to check for the error button/panel,
    // so we are throwing an error here and checking for that instead
    if (isTesting) {
      throw new Error("Cannot use non-theme value");
    }
  }
};

// commenting out the theme scales that are not implemented yet
const SCALES: Record<CSSPropName, NessieThemeKey> = {
  color: "colors",
  backgroundColor: "colors",
  borderColor: "colors",
  margin: "space",
  marginTop: "space",
  marginRight: "space",
  marginBottom: "space",
  marginLeft: "space",
  // marginX: "space",
  // marginY: "space",
  padding: "space",
  paddingTop: "space",
  paddingRight: "space",
  paddingBottom: "space",
  paddingLeft: "space",
  // paddingX: "space",
  // paddingY: "space",
  // top: "space",
  // right: "space",
  // bottom: "space",
  // left: "space",
  gridGap: "space",
  gridColumnGap: "space",
  gridRowGap: "space",
  gap: "space",
  columnGap: "space",
  rowGap: "space",

  // fontFamily: "fonts",
  // fontSize: "fontSizes",
  // fontWeight: "fontWeights",
  // lineHeight: "lineHeights",
  // letterSpacing: "letterSpacings",

  // border: "borders",
  // borderTop: "borders",
  // borderRight: "borders",
  // borderBottom: "borders",
  // borderLeft: "borders",

  // borderWidth: "borderWidths",
  // borderStyle: "borderStyles",

  borderRadius: "radii",
  borderTopRightRadius: "radii",
  borderTopLeftRadius: "radii",
  borderBottomRightRadius: "radii",
  borderBottomLeftRadius: "radii",

  // borderTopWidth: "borderWidths",

  borderTopColor: "colors",

  // borderTopStyle: "borderStyles",
  // borderBottomWidth: "borderWidths",

  borderBottomColor: "colors",

  // borderBottomStyle: "borderStyles",
  // borderLeftWidth: "borderWidths",

  borderLeftColor: "colors",

  // borderLeftStyle: "borderStyles",
  // borderRightWidth: "borderWidths",

  borderRightColor: "colors",

  // borderRightStyle: "borderStyles",

  outlineColor: "colors",

  // boxShadow: "shadows",
  // textShadow: "shadows",
  // zIndex: "zIndices",
  // width: "sizes",
  // minWidth: "sizes",
  // maxWidth: "sizes",
  // height: "sizes",
  // minHeight: "sizes",
  // maxHeight: "sizes",
  // flexBasis: "sizes",
  // size: "sizes",

  // svg
  fill: "colors",
  stroke: "colors",
} as const;
