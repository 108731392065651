/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type SkillsAddIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let SkillsAddIcon: NessieFunctionComponent<SkillsAddIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.858 9.32c-.222-.045-.49-.045-.712-.045-3.385 0-6.146 2.717-6.057 6.101 0 .98.223 1.915.668 2.762h-.044l-.25.191c-1.156.886-1.656 1.268-2.155 1.768a.506.506 0 01-.357.134.506.506 0 01-.356-.134c-.4-.4-.801-.712-1.559-1.291a66.134 66.134 0 01-1.571-1.203l-.344-.267C4.94 15.644 2 13.328 2 9.988 2 6.737 4.494 5 6.81 5c1.603 0 3.028.713 4.141 2.049C12.065 5.713 13.534 5 15.093 5c2.182 0 4.454 1.514 4.765 4.32zm-.2 1.18c.056.011.111.022.156.022A4.894 4.894 0 0124 15.376a4.885 4.885 0 01-4.899 4.9c-2.004 0-3.696-1.159-4.453-2.85v-.045c-.022-.067-.045-.123-.067-.179a2.99 2.99 0 01-.067-.178c0-.022-.011-.033-.022-.044-.011-.011-.022-.023-.022-.045-.023-.067-.045-.144-.067-.222a5.432 5.432 0 00-.067-.223v-.045a1.583 1.583 0 01-.089-.445v-.09c-.045-.177-.045-.355-.045-.534a4.885 4.885 0 014.9-4.898h.4c.045 0 .1.01.156.022zm.022 5.456h1.648c.312 0 .579-.268.579-.624a.59.59 0 00-.58-.579h-1.602V13.15a.58.58 0 00-.49-.58h-.09a.59.59 0 00-.578.58V14.798h-1.603a.59.59 0 00-.58.579c0 .222.09.4.223.49.09.044.178.088.312.088h1.603v1.604a.59.59 0 00.58.579.59.59 0 00.578-.58v-1.602z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={5} width={22} height={16}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.858 9.32c-.222-.045-.49-.045-.712-.045-3.385 0-6.146 2.717-6.057 6.101 0 .98.223 1.915.668 2.762h-.044l-.25.191c-1.156.886-1.656 1.268-2.155 1.768a.506.506 0 01-.357.134.506.506 0 01-.356-.134c-.4-.4-.801-.712-1.559-1.291a66.134 66.134 0 01-1.571-1.203l-.344-.267C4.94 15.644 2 13.328 2 9.988 2 6.737 4.494 5 6.81 5c1.603 0 3.028.713 4.141 2.049C12.065 5.713 13.534 5 15.093 5c2.182 0 4.454 1.514 4.765 4.32zm-.2 1.18c.056.011.111.022.156.022A4.894 4.894 0 0124 15.376a4.885 4.885 0 01-4.899 4.9c-2.004 0-3.696-1.159-4.453-2.85v-.045c-.022-.067-.045-.123-.067-.179a2.99 2.99 0 01-.067-.178c0-.022-.011-.033-.022-.044-.011-.011-.022-.023-.022-.045-.023-.067-.045-.144-.067-.222a5.432 5.432 0 00-.067-.223v-.045a1.583 1.583 0 01-.089-.445v-.09c-.045-.177-.045-.355-.045-.534a4.885 4.885 0 014.9-4.898h.4c.045 0 .1.01.156.022zm.022 5.456h1.648c.312 0 .579-.268.579-.624a.59.59 0 00-.58-.579h-1.602V13.15a.58.58 0 00-.49-.58h-.09a.59.59 0 00-.578.58V14.798h-1.603a.59.59 0 00-.58.579c0 .222.09.4.223.49.09.044.178.088.312.088h1.603v1.604a.59.59 0 00.58.579.59.59 0 00.578-.58v-1.602z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SkillsAddIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  SkillsAddIcon = nessiePropCheckerWrapper(SkillsAddIcon);
}

export default SkillsAddIcon;
