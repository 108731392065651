import includes from "lodash/includes";

export const isTesting = process.env.NODE_ENV === "test";
export const isTest = isTesting;
export let isDev = process.env.NODE_ENV === "development";
//NOTE: This seeems to be creating a problem when running in dev mode in gatsby.
//      I'm setting is Prod always to true to make it work
// export const isProd = process.env.NODE_ENV === "production";

export const isProd = true;
export const isProduction = isProd;
export const isServer = typeof window === "undefined";
export const isClient = !isServer;

const host = !isServer && window && window.location && window.location.hostname ? window.location.hostname : "";
export const isHomeSite =
  includes(host, "home.classdojo") || includes(host, "home2.classdojo") || includes(host, "home3.classdojo");

export function overrideIsDev(override: boolean) {
  isDev = override;
}
