/** @jsxImportSource theme-ui */
import { BodyText, Button, Modal, ModalConfirmDialog, ModalFooterActions, ModalTitle, Space } from "../../nessie";

type ConfirmModalProps = {
  title: string;
  bodyText?: React.ReactNode;
  confirmText: string;
  cancelText: string;
  danger?: boolean;
  "data-test-name"?: string;
  confirm: () => void;
  close: () => void;
};

export const ConfirmModal = ({
  title,
  bodyText,
  confirmText,
  cancelText,
  danger,
  confirm,
  close,
  ["data-test-name"]: dataTestName,
}: ConfirmModalProps) => {
  return (
    <Modal data-test-name={dataTestName} isOpen={true}>
      <ModalConfirmDialog>
        <ModalTitle>{title}</ModalTitle>
        <Space size="l" />
        <BodyText>{bodyText}</BodyText>
        <Space size="l" />
        <ModalFooterActions>
          <Button kind="tertiary" onClick={close}>
            {cancelText}
          </Button>
          <Button
            data-test-name={`${dataTestName}-primaryButton`}
            kind={danger ? "destructive" : "primary"}
            onClick={() => {
              confirm();
              close();
            }}
          >
            {confirmText}
          </Button>
        </ModalFooterActions>
      </ModalConfirmDialog>
    </Modal>
  );
};
