/** @jsxImportSource theme-ui */
import * as React from "react";
import palette from "../commonStyles/Palette";
import { RAW_cssValue } from "../../nessie/stylingLib";

/**
 * A scrollable list group (named after Bootstrap ListGroup)
 *
 * Handles hover and selected states. Handles scrolling when content overflows
 * container.
 */

const OUTER_BORDER_RADIUS = 5;
const INNER_BORDER_RADIUS = 4;

type ListItem = {
  node: React.ReactNode;
  isSelected?: boolean;
  key?: string;
};

type ListGroupProps = {
  items: ListItem[];
  style?: React.CSSProperties;
};

const ListGroup = ({ items, style }: ListGroupProps) => {
  return (
    <ul sx={styles.container} style={style} role="listbox">
      {items.map((item, i) => {
        const { isSelected, node } = item;

        return (
          <li
            aria-selected={isSelected}
            role="option"
            key={item.key || i}
            sx={{
              "&:hover": styles.hovered,
              "&[aria-selected='true']": styles.selected,
            }}
          >
            {node}
          </li>
        );
      })}
    </ul>
  );
};

export default ListGroup;

const styles = {
  container: {
    border: `solid 1px ${palette.gray20}`,
    overflowY: "auto" as const,
    borderRadius: RAW_cssValue(OUTER_BORDER_RADIUS),
    background: "white",
    listStyle: "none",
    padding: 0,
    margin: 0,
    "li:first-child": {
      borderTopLeftRadius: RAW_cssValue(INNER_BORDER_RADIUS),
      borderTopRightRadius: RAW_cssValue(INNER_BORDER_RADIUS),
    },
    "li:last-child": {
      borderBottomLeftRadius: RAW_cssValue(INNER_BORDER_RADIUS),
      borderBottomRightRadius: RAW_cssValue(INNER_BORDER_RADIUS),
    },
  },
  selected: {
    boxShadow: `inset 0 0 0 1px ${palette.dojoBlue}`,
    backgroundColor: "dt_aqua10",
  },
  hovered: {
    backgroundColor: "dt_blueberry20",
  },
};
