/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type SkillRemoveIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let SkillRemoveIcon: NessieFunctionComponent<SkillRemoveIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.028 8.268c.22 0 .484 0 .704.044-.308-2.772-2.552-4.268-4.708-4.268-1.54 0-2.992.704-4.092 2.024-1.1-1.32-2.508-2.024-4.092-2.024-2.288 0-4.752 1.716-4.752 4.928 0 3.3 2.904 5.588 5.06 7.26l.339.264c.632.492 1.14.888 1.553 1.188.748.572 1.144.88 1.54 1.276a.5.5 0 00.352.132.5.5 0 00.352-.132c.494-.494.987-.872 2.129-1.747l.247-.19h.044a5.81 5.81 0 01-.66-2.727c-.044-3.344 2.684-6.028 5.984-6.028zm.704 1.232a.863.863 0 01-.154-.022c-.055-.011-.11-.022-.154-.022h-.396a4.826 4.826 0 00-4.84 4.84c0 .176 0 .352.044.528v.088c0 .132.044.308.088.44v.044a4.815 4.815 0 004.708 3.74 4.826 4.826 0 004.84-4.84c0-2.42-1.804-4.444-4.136-4.796zm-2.816 5.368h4.312c.308 0 .528-.264.572-.572a.582.582 0 00-.572-.572h-4.312a.582.582 0 00-.572.572c0 .308.264.572.572.572z"
          />
          <mask maskUnits="userSpaceOnUse" x={1} y={4} width={22} height={16}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.028 8.268c.22 0 .484 0 .704.044-.308-2.772-2.552-4.268-4.708-4.268-1.54 0-2.992.704-4.092 2.024-1.1-1.32-2.508-2.024-4.092-2.024-2.288 0-4.752 1.716-4.752 4.928 0 3.3 2.904 5.588 5.06 7.26l.339.264c.632.492 1.14.888 1.553 1.188.748.572 1.144.88 1.54 1.276a.5.5 0 00.352.132.5.5 0 00.352-.132c.494-.494.987-.872 2.129-1.747l.247-.19h.044a5.81 5.81 0 01-.66-2.727c-.044-3.344 2.684-6.028 5.984-6.028zm.704 1.232a.863.863 0 01-.154-.022c-.055-.011-.11-.022-.154-.022h-.396a4.826 4.826 0 00-4.84 4.84c0 .176 0 .352.044.528v.088c0 .132.044.308.088.44v.044a4.815 4.815 0 004.708 3.74 4.826 4.826 0 004.84-4.84c0-2.42-1.804-4.444-4.136-4.796zm-2.816 5.368h4.312c.308 0 .528-.264.572-.572a.582.582 0 00-.572-.572h-4.312a.582.582 0 00-.572.572c0 .308.264.572.572.572z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SkillRemoveIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  SkillRemoveIcon = nessiePropCheckerWrapper(SkillRemoveIcon);
}

export default SkillRemoveIcon;
