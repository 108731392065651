/** @jsxImportSource theme-ui */
export const Next = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1716 9.1715L14.1005 2.10043C13.3195 1.31939 12.0531 1.31939 11.2721 2.10043C10.491 2.88148 10.491 4.14781 11.2721 4.92886L16.3431 9.99993H2C0.895432 9.99993 0 10.8954 0 11.9999C0 13.1045 0.89543 13.9999 2 13.9999H16.3431L11.2721 19.071C10.491 19.852 10.491 21.1184 11.2721 21.8994C12.0531 22.6805 13.3195 22.6805 14.1005 21.8994L21.1716 14.8284L24 11.9999L21.1716 9.1715Z"
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="1" width="24" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1716 9.1715L14.1005 2.10043C13.3195 1.31939 12.0531 1.31939 11.2721 2.10043C10.491 2.88148 10.491 4.14781 11.2721 4.92886L16.3431 9.99993H2C0.895432 9.99993 0 10.8954 0 11.9999C0 13.1045 0.89543 13.9999 2 13.9999H16.3431L11.2721 19.071C10.491 19.852 10.491 21.1184 11.2721 21.8994C12.0531 22.6805 13.3195 22.6805 14.1005 21.8994L21.1716 14.8284L24 11.9999L21.1716 9.1715Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
};

export const Prev = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82843 9.1715L9.8995 2.10043C10.6805 1.31939 11.9469 1.31939 12.7279 2.10043C13.509 2.88148 13.509 4.14781 12.7279 4.92886L7.65685 9.99993H22C23.1046 9.99993 24 10.8954 24 11.9999C24 13.1045 23.1046 13.9999 22 13.9999H7.65685L12.7279 19.071C13.509 19.852 13.509 21.1184 12.7279 21.8994C11.9469 22.6805 10.6805 22.6805 9.8995 21.8994L2.82843 14.8284L0 11.9999L2.82843 9.1715Z"
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="1" width="24" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.82843 9.1715L9.8995 2.10043C10.6805 1.31939 11.9469 1.31939 12.7279 2.10043C13.509 2.88148 13.509 4.14781 12.7279 4.92886L7.65685 9.99993H22C23.1046 9.99993 24 10.8954 24 11.9999C24 13.1045 23.1046 13.9999 22 13.9999H7.65685L12.7279 19.071C13.509 19.852 13.509 21.1184 12.7279 21.8994C11.9469 22.6805 10.6805 22.6805 9.8995 21.8994L2.82843 14.8284L0 11.9999L2.82843 9.1715Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
};
