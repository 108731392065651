/** @jsxImportSource theme-ui */
import { useMemo } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import translate from "../../utils/translate";
import logEvent from "../../utils/log_event";
import { translatablePropTypes } from "../../prop_shapes/translatable";
import { palette, standardFont, unselectable } from "../../styles";

/**
 * Button group component. Has two modes:
 * If props.value is an array, behave like a set of checkboxes (i.e. multiple selection)
 * Else, behave like a radio button (i.e. single selection)
 */

type ButtonGroupOption<T> = {
  value: T;
  // translate properties
  str?: string; //| string[];
  text?: string | React.ReactNode;
  tmpText?: string;
  fallback?: string;
};

type ButtonGroupProps<T> = {
  value: T | T[];
  options: ButtonGroupOption<T>[];
  optionStyles?: React.CSSProperties[];
  amplitudeKeys?: string[];
  fullWidth?: boolean;
  onChange?: (selected: T | T[]) => void;
  style?: React.CSSProperties;
};

const ButtonGroup = <T,>({
  value,
  options = [],
  optionStyles = [],
  amplitudeKeys = [],
  fullWidth,
  onChange,
  style,
}: ButtonGroupProps<T>): JSX.Element => {
  const _handleClick = (optionValue: T, index: number) => {
    if (!onChange) {
      return;
    }

    if (Array.isArray(value)) {
      if (value.includes(optionValue)) {
        onChange(value.filter((v) => v !== optionValue));
      } else {
        onChange(value.concat([optionValue]));
      }
    } else {
      onChange(optionValue);
    }

    if (amplitudeKeys && amplitudeKeys[index]) {
      logEvent(amplitudeKeys[index]);
    }
  };

  return (
    <div style={style}>
      {options.map((option, index) => (
        <Button
          key={index}
          option={option}
          index={index}
          buttonCount={options.length}
          onClick={_handleClick}
          isActive={isActiveOption(option, value)}
          fullWidth={fullWidth}
          style={optionStyles[index]}
        />
      ))}
    </div>
  );
};

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // unique identifier, used to determine if option is selected
      value: PropTypes.any.isRequired,
      ...translatablePropTypes,
    }),
  ),
  optionStyles: PropTypes.array,
  amplitudeKeys: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
};

export default ButtonGroup;

type ButtonProps<T> = {
  option: ButtonGroupOption<T>;
  index: number;
  buttonCount: number;
  onClick: (optionValue: T, index: number) => void;
  isActive?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
};

const Button = <T,>({
  option,
  index,
  buttonCount,
  onClick,
  fullWidth,
  isActive,
  style,
}: ButtonProps<T>): JSX.Element => {
  const buttonStyles = useMemo(
    () => ({
      ...styles.base,
      ...(fullWidth && { width: `${100 / buttonCount}%` }),
      ...(isActive
        ? { color: "white", backgroundColor: palette.dojoBlue, borderColor: palette.dojoBlue }
        : {
            color: palette.dojoBlue,
            backgroundColor: "white",
            borderColor: palette.dojoBlue,
          }),
      ...(index === 0 ? styles.left : index === buttonCount - 1 ? styles.right : styles.middle),
      ...style,
    }),
    [buttonCount, fullWidth, index, isActive, style],
  );

  return (
    <button style={buttonStyles} onClick={() => onClick(option.value, index)}>
      {translate(option)}
    </button>
  );
};

function isActiveOption<T>(option?: { value: T }, activeValue?: T | T[]) {
  if (Array.isArray(activeValue)) {
    return option && activeValue.includes(option.value);
  } else {
    return option && activeValue === option.value;
  }
}

const buttonBorderRadius = ".5rem";

const styles = {
  base: {
    ...standardFont,
    ...unselectable,
    display: "inline-block",
    border: "none",
    fontWeight: 600,
    background: "transparent",
    borderColor: palette.dojoBlue,
    borderStyle: "solid",
    color: palette.dojoBlue,
    padding: "0.9rem 1.6rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
  },
  left: {
    borderTopWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    borderRightWidth: "1px",
    borderRadius: `${buttonBorderRadius} 0 0 ${buttonBorderRadius}`,
  },
  middle: {
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: 0,
    borderBottomWidth: "1px",
  },
  right: {
    borderTopWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    borderRadius: `0 ${buttonBorderRadius} ${buttonBorderRadius} 0`,
  },
};
