/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import isNumber from "lodash/isNumber";
import isArray from "lodash/isArray";
import commonStyles from "../commonStyles";

/**
 *  DEPRECATED: DO NOT USE THIS COMPONENT ANYMORE, USE INSTEAD OUR COMMON STYLE
 *  VARIABLES FOR OBTAINING MARGINS/PADDINGS
 *
 *
 *  Generic wrapper for any type of component, the sole reason of this component
 *  is to provide standarized margins/paddings, also allows the configuration of them
 *  but always relying on our internal and standard gutter size.
 *
 *  DEFAULT BEHAVIOUR, by default Container will apply only paddings to the same of
 *  our standard gutterSize, NO margins.
 *
 *  REMEMBER the values provided for configuration are "multipliers" of our standard
 *  gutterSize, therefore "1" will mean the default value "0" will disable it and
 *  any number > 1 will multiple our standard gutterSize "n" times.
 *
 *  By default the container is a block element but can be changed to be a
 *  inline-block element through the prop "inlineBlock={true}"
 *
 *  The following options are available for configuration trhough props:
 *  - Enable default margins: margin={true}
 *  - Disable either paddings or margins: padding={false} / margin={false}
 *
 *  - Custom configuration, it accepts an array where we simulate the same
 *    behaviour css has, if you provide 2 values it will apply for top/bottom
 *    left/right otherwise it will use each value on each direction (just like css).
 *    i.e:
 *      padding={[2, 0]} => top/bottom 2 times the size; left/right no padding.
 *      padding={[2]} => top/right/bottom/left 2 times the size.
 *      padding={2} => same as above.
 *      margin={[1, 2, 3, 4]} top 1, right 2, bottom 3, left 4.
 *
 *  - Individual modifiers: Both padding and margin accept individual modifiers
 *    through props (paddingTop, marginLeft, etc). Individual modifiers have
 *    precedence over previous ones.
 *    The individual modifier accepts:
 *      marginTop={false} => just disable the margin top
 *      marginTop={number} => set the margin top to the given number
 */
const propTypes = {
  // Margin
  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  marginTop: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  // Padding
  padding: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  paddingTop: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  paddingRight: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  paddingBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  paddingLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

  width: PropTypes.any,
  height: PropTypes.any,

  inlineBlock: PropTypes.any,
  relative: PropTypes.any,
  textCenter: PropTypes.any,

  style: PropTypes.object,
  styles: PropTypes.array,

  children: PropTypes.node,
};

const omittableProps = Object.keys(propTypes);

type ContainerProps = Omit<typeof Container.defaultProps, "padding" | "margin" | "inlineBlock"> & {
  margin?: boolean | React.CSSProperties["margin"];
  marginTop?: boolean | React.CSSProperties["marginTop"];
  marginRight?: boolean | React.CSSProperties["marginRight"];
  marginBottom?: boolean | React.CSSProperties["marginBottom"];
  marginLeft?: boolean | React.CSSProperties["marginLeft"];

  padding?: boolean | React.CSSProperties["padding"];
  paddingTop?: boolean | React.CSSProperties["paddingTop"];
  paddingRight?: boolean | React.CSSProperties["paddingRight"];
  paddingBottom?: boolean | React.CSSProperties["paddingBottom"];
  paddingLeft?: boolean | React.CSSProperties["paddingLeft"];

  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];

  inlineBlock?: boolean;
  relative?: boolean;
  textCenter?: boolean;

  style?: React.CSSProperties;
  styles?: React.CSSProperties[];
  children: React.ReactNode;

  onClick?: (...p: any) => void;
};

// keep this as a class components, otherwise FlipMove component in messaging's ChannelList
// crashes while trying to access the this component prototype
class Container extends React.Component<ContainerProps & React.HTMLProps<HTMLDivElement>> {
  static propTypes = propTypes;
  static defaultProps = { margin: false, padding: true, inlineBlock: false };

  render(): JSX.Element {
    const innerStyles = getStyles(this.props);
    let styles = [innerStyles];

    if (this.props.style) styles.push(this.props.style);
    if (this.props.styles) styles = styles.concat(this.props.styles);

    return (
      <div data-display-name="container" {...omit(this.props, omittableProps)} style={Object.assign({}, ...styles)}>
        {this.props.children}
      </div>
    );
  }
}

export default Container;

// Set the prop with all the modifiers specified to the given value.
// Example: setProp(styles, "margin", ["Top", "Bottom"], "1rem")
// Use capital letters for the modifier to make it compatible with React.
function setProp(styles: React.CSSProperties, propName: string, propModifiers: string[], val: unknown) {
  propModifiers.forEach((modifier) => {
    // cast styles to generic object hash so we can access it using dynamic prop name
    (styles as Record<string, unknown>)[propName + modifier] = val;
  });
}

const getStyles = function (props: ContainerProps): React.CSSProperties {
  const gutterSize = commonStyles.layout.gutterSize;
  const gutterSizeREM = `${gutterSize}rem`;

  const styles: React.CSSProperties = {};

  if (props.width) styles.width = `${props.width}rem`;
  if (props.height) styles.height = `${props.height}rem`;

  if (props.inlineBlock) styles.display = "inline-block";
  if (props.relative) styles.position = "relative";
  if (props.textCenter) styles.textAlign = "center";

  setProp(styles, "margin", ["Top", "Right", "Bottom", "Left"], gutterSizeREM);
  setProp(styles, "padding", ["Top", "Right", "Bottom", "Left"], gutterSizeREM);

  const cssProps = ["margin", "padding"];

  // eslint-disable-next-line complexity
  cssProps.forEach((cssProp) => {
    // cast `props` to a generic object hash, otherwise TypeScript would
    // error out on all the dynamic key accesses bellow
    const propsMap = props as Record<string, unknown>;
    const val = propsMap[cssProp];

    // If padding or margin = false just disable them all.
    if (val === false) {
      setProp(styles, cssProp, ["Top", "Right", "Bottom", "Left"], "0");

      // If is a number then set all the margins or paddings to it
    } else if (isNumber(val)) {
      const computedVal = `${val * gutterSize}rem`;
      setProp(styles, cssProp, ["Top", "Right", "Bottom", "Left"], computedVal);

      // If is an array then set each one to the value or simulate css behaviour
      // where you can specify just two of them and behave in pairs top/bottom left/right
    } else if (isArray(val)) {
      // Logic for simulating css behaviour when setting only two values
      // f.i: padding: 5 0 -> top/bottom to 5 and left/right to 0
      if (val[1] == null) {
        val[1] = val[0];
      }
      if (val[2] == null) {
        val[2] = val[0];
      }
      if (val[3] == null) {
        val[3] = val[1];
      }

      if (val.length === 4) {
        setProp(styles, cssProp, ["Top"], `${val[0] * gutterSize}rem`);
        setProp(styles, cssProp, ["Right"], `${val[1] * gutterSize}rem`);
        setProp(styles, cssProp, ["Bottom"], `${val[2] * gutterSize}rem`);
        setProp(styles, cssProp, ["Left"], `${val[3] * gutterSize}rem`);
      }
    }

    // Indivual override settings
    // Whatever gets specified at a more granular level takes precedence
    // either to disable it or specify a value.
    if (propsMap[`${cssProp}Top`] === false) setProp(styles, cssProp, ["Top"], "0");
    if (propsMap[`${cssProp}Right`] === false) setProp(styles, cssProp, ["Right"], "0");
    if (propsMap[`${cssProp}Bottom`] === false) setProp(styles, cssProp, ["Bottom"], "0");
    if (propsMap[`${cssProp}Left`] === false) setProp(styles, cssProp, ["Left"], "0");

    if (isNumber(propsMap[`${cssProp}Top`])) setProp(styles, cssProp, ["Top"], `${propsMap[`${cssProp}Top`]}rem`);
    if (isNumber(propsMap[`${cssProp}Right`])) setProp(styles, cssProp, ["Right"], `${propsMap[`${cssProp}Right`]}rem`);
    if (isNumber(propsMap[`${cssProp}Bottom`]))
      setProp(styles, cssProp, ["Bottom"], `${propsMap[`${cssProp}Bottom`]}rem`);
    if (isNumber(propsMap[`${cssProp}Left`])) setProp(styles, cssProp, ["Left"], `${propsMap[`${cssProp}Left`]}rem`);
  });

  return styles;
};
