/** @jsxImportSource theme-ui */
import pickBy from "lodash/pickBy";
import * as React from "react";
import PropTypes from "prop-types";
import Tooltip, { tooltipMaxWidth as defaultTooltipMaxWidth } from "./Tooltip";
import calculatePosition from "./CalculatePosition";
import { AbsolutePositioner } from "../positioning";
import WithClickOutside from "../misc/WithClickOutside";

/**
 * Component for wrapping an element that should display our custom tooltip
 * when hovering.
 */

export const TOOLTIP_TRIGGER_PROPTYPES = {
  // Content of the Tooltip to render
  tooltipContent: PropTypes.any.isRequired,

  // Configuration for the tooltip's position
  tooltipPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  tooltipCaret: PropTypes.bool, // defaults to true
  tooltipOffset: PropTypes.string,
  tooltipCaretOffset: PropTypes.string,
  tooltipGutter: PropTypes.number,
  tooltipAlign: PropTypes.oneOf(["left", "right", "center"]), // chosen based on tooltipPosition if not specified

  // Configuration for the tooltip
  tooltipTextColor: PropTypes.string,
  tooltipTextSize: PropTypes.string,
  tooltipBackgroundColor: PropTypes.string,
  tooltipMaxWidth: PropTypes.string,
  tooltipPadding: PropTypes.string,
  tooltipCaretSize: PropTypes.string,

  // Force the tooltip to be open.
  isOpen: PropTypes.bool,

  // allows the child to display as block
  block: PropTypes.bool,

  // allows to display the information if it's focused using the keyboard
  role: PropTypes.string,
  tabIndex: PropTypes.number,
};

type TooltipTriggerProps = {
  hide?: boolean;
  isOpen?: boolean;
  block?: boolean;
  role?: string;
  tabIndex?: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
} & TooltipAtPositionProps &
  Omit<JSX.IntrinsicElements["div"], "style" | "children" | "onMouseEnter" | "onMouseLeave" | "onClick">;

const TooltipTrigger = ({
  tooltipContent,
  tooltipPosition = "top",
  tooltipCaret = true,
  tooltipOffset,
  tooltipCaretOffset,
  tooltipGutter,
  tooltipMaxWidth = defaultTooltipMaxWidth,
  tooltipAlign,
  tooltipCaretSize,
  tooltipTextColor,
  tooltipTextSize,
  tooltipBackgroundColor,
  tooltipPadding,
  isOpen,
  block,
  children,
  style,
  role = "tooltip",
  tabIndex = 0,
  hide,
  ...props
}: TooltipTriggerProps): JSX.Element | null => {
  const [isHovered, setIsHovered] = React.useState(false);

  if (hide && children) return <>{children}</>;
  return (
    <WithClickOutside onClickOutside={() => setIsHovered(false)} wrapper={block ? "div" : "span"}>
      <div
        {...props}
        style={{
          position: "relative",
          display: block ? "block" : "inline-block",
          ...style,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsHovered(true)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        role={role}
        tabIndex={tabIndex}
      >
        {children}
        {(isHovered || isOpen) && (
          <TooltipAtPosition
            tooltipContent={tooltipContent}
            tooltipPosition={tooltipPosition}
            tooltipCaret={tooltipCaret}
            tooltipOffset={tooltipOffset}
            tooltipCaretOffset={tooltipCaretOffset}
            tooltipGutter={tooltipGutter}
            tooltipMaxWidth={tooltipMaxWidth}
            tooltipAlign={tooltipAlign}
            tooltipCaretSize={tooltipCaretSize}
            tooltipTextColor={tooltipTextColor}
            tooltipTextSize={tooltipTextSize}
            tooltipBackgroundColor={tooltipBackgroundColor}
            tooltipPadding={tooltipPadding}
          />
        )}
      </div>
    </WithClickOutside>
  );
};

TooltipTrigger.propTypes = TOOLTIP_TRIGGER_PROPTYPES;

type TooltipAtPositionProps = {
  tooltipContent: React.ReactNode;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  tooltipCaret?: boolean;
  tooltipOffset?: string;
  tooltipCaretOffset?: string;
  tooltipGutter?: number;
  tooltipMaxWidth?: string;
  tooltipAlign?: "left" | "center" | "right";
  tooltipCaretSize?: string;
  tooltipTextColor?: string;
  tooltipTextSize?: string;
  tooltipBackgroundColor?: string;
  tooltipPadding?: string;
};

const TooltipAtPosition = ({
  tooltipContent,
  tooltipPosition,
  tooltipCaret,
  tooltipOffset,
  tooltipCaretOffset,
  tooltipGutter,
  tooltipMaxWidth,
  tooltipAlign,
  tooltipCaretSize,
  tooltipTextColor,
  tooltipTextSize,
  tooltipBackgroundColor,
  tooltipPadding,
}: TooltipAtPositionProps): JSX.Element => {
  const { caret, positionerProps } = calculatePosition({
    position: tooltipPosition,
    caret: tooltipCaret,
    offset: tooltipOffset,
    caretOffset: tooltipCaretOffset,
    gutter: tooltipGutter,
    width: tooltipMaxWidth,
    align: tooltipAlign,
  });

  // need to do this so undefineds don't override default tooltip props.
  const tooltipProps = pickBy(
    {
      caret,
      caretOffset: tooltipCaretOffset,
      caretSize: tooltipCaretSize,
      textColor: tooltipTextColor,
      textSize: tooltipTextSize,
      backgroundColor: tooltipBackgroundColor,
      maxWidth: tooltipMaxWidth,
      padding: tooltipPadding,
    },
    (val) => val != null,
  );

  return (
    <AbsolutePositioner {...positionerProps}>
      <Tooltip {...tooltipProps}>{tooltipContent}</Tooltip>
    </AbsolutePositioner>
  );
};

export default TooltipTrigger;
