/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type CameraVideoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let CameraVideoIcon: NessieFunctionComponent<CameraVideoIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.882 8.035h9.247c.353 0 .636.318.636.671v6.882a.633.633 0 01-.636.636H3.882a.633.633 0 01-.635-.636V8.671c0-.353.282-.636.635-.636zm16.06.353a.566.566 0 01.529.036.617.617 0 01.247.494v6.494c0 .176-.07.353-.247.459a.57.57 0 01-.318.106c-.07 0-.141 0-.212-.036l-4.694-2.082c-.212-.106-.353-.318-.353-.53V11c0-.247.141-.423.353-.53l4.694-2.082z"
          />
          <mask maskUnits="userSpaceOnUse" x={3} y={8} width={18} height={9}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.882 8.035h9.247c.353 0 .636.318.636.671v6.882a.633.633 0 01-.636.636H3.882a.633.633 0 01-.635-.636V8.671c0-.353.282-.636.635-.636zm16.06.353a.566.566 0 01.529.036.617.617 0 01.247.494v6.494c0 .176-.07.353-.247.459a.57.57 0 01-.318.106c-.07 0-.141 0-.212-.036l-4.694-2.082c-.212-.106-.353-.318-.353-.53V11c0-.247.141-.423.353-.53l4.694-2.082z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CameraVideoIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  CameraVideoIcon = nessiePropCheckerWrapper(CameraVideoIcon);
}

export default CameraVideoIcon;
