/** @jsxImportSource theme-ui */
import { textFieldStyles } from "../../nessie/components/TextField";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import translate from "../../utils/translate";

export interface NativeSelectProps {
  options: { value: string; str: string }[];
  selectedValue: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectStyles?: ThemeUIStyleObject;
  titleStyles?: ThemeUIStyleObject;
  titlePosition?: "top" | "left";
  title?: string;
}

const NativeSelect = ({
  options,
  selectedValue,
  onChange,
  selectStyles,
  titleStyles,
  title,
  label,
  titlePosition,
  ...props
}: NativeSelectProps) => {
  return (
    <label
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: titlePosition === "top" ? "column" : "row",
      }}
      {...props}
    >
      {title ? (
        <span sx={{ ...defaultNativeSelectTitleStyles, ...titleStyles }} aria-hidden={true}>
          {title}
        </span>
      ) : null}
      <select
        aria-label={label || title}
        value={selectedValue}
        onChange={onChange}
        sx={{ ...textFieldStyles, ...defaultNativeSelectStyles, ...selectStyles }}
      >
        {options.map((el) => (
          <option key={el.value} value={el.value}>
            {translate(el.str)}
          </option>
        ))}
      </select>
    </label>
  );
};

const caretSize = 5;
const padding = 24;
const defaultNativeSelectTitleStyles: ThemeUIStyleObject = {
  fontSize: "18px",
  fontWeight: "600",
  margin: "dt_m",
};
const defaultNativeSelectStyles: ThemeUIStyleObject = {
  webkitAppearance: "none",
  mozAppearance: "none",
  appearance: "none",
  backgroundImage: `linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%)`,
  backgroundPosition: `calc(100% - ${padding + caretSize}px),
  calc(100% - ${padding}px),
  100% 0`,
  backgroundSize: `${caretSize}px ${caretSize}px,
  ${caretSize}px ${caretSize}px`,
  backgroundRepeat: "no-repeat",
};

export default NativeSelect;
