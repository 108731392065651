/** @jsxImportSource theme-ui */
import PropTypes from "prop-types";

/*
 * ExternalLink is a link to a non-ClassDojo url that should be opened in a new
 * tab. It includes defense against the window.opener vulnerability.
 *
 * Previously we relied on blankshield for this, but we've since switched to a plain
 * <a> with rel="noopener noreferrer" now that browser support is better.
 */

type ExternalLinkProps = JSX.IntrinsicElements["a"];

const ExternalLink = (props: ExternalLinkProps): JSX.Element => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

export default ExternalLink;

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
};
