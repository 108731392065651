/** @jsxImportSource theme-ui */
import { fonts, shadows, palette } from "../commonStyles";

const inputLineHeight = 2.2;
const inputVerticalPadding = 0.6;
const inputBorderSize = 0.1; // ~= 1px

const styles = {
  lineHeightRem: inputLineHeight,
  borderSizeRem: inputBorderSize,

  common: {
    width: "100%",

    fontFamily: fonts.family,
    fontSize: fonts.sizeInputs,
    fontWeight: "normal",
    lineHeight: `${inputLineHeight}rem`,

    padding: `${inputVerticalPadding}rem 1rem`,

    borderTopRightRadius: "0.5rem",
    borderTopLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",

    boxShadow: `${shadows.medium} inset`,
    border: `1px solid ${palette.gray20}`,
    outline: "none",
  },

  inputText: {
    height: `36px`,
  },

  active: {
    outline: 0,
    border: `1px solid ${palette.dojoBlue}`,
  },
  activeDanger: {
    outline: 0,
    border: `1px solid ${palette.dojoRed}`,
    backgroundColor: "rgba(242, 92, 83, 0.1)",
  },
};

export default styles;
