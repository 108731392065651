/** @jsxImportSource theme-ui */
import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import isObject from "lodash/isObject";
import InputText from "./InputText";
import { palette } from "../commonStyles";
import translate from "../../utils/translate";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { translatableValidator } from "../../prop_shapes/translatable";
import { CheckmarkIcon, CloseIcon } from "../../nessie/icons";
import { GlobalCSS } from "../../nessie/stylingLib";

export type ValidatedInputProps = React.ComponentPropsWithoutRef<typeof InputText> & {
  valid?: boolean;
  message?: string | { message?: string };
  withIcon?: boolean;
  forceShowValidations?: boolean;
  "data-test-name"?: string;
  id?: string;
};

/**
 * Input text with validation capabilities, let the user specify error message
 * and icons.
 */
const ValidatedInput = React.forwardRef<HTMLInputElement, ValidatedInputProps>(
  ({ placeholder, valid, message, onChange, onBlur, withIcon, forceShowValidations, id, ...inputProps }, inputRef) => {
    const [firstChangeOcurred, setFirstChangeOcurred] = useState(false);
    const [hasBlurred, setHasBlurred] = useState(false);

    // only run placeholder through translator if it looks like it should be translated
    if (placeholder && (isObject(placeholder) || /^[a-zA-Z]+\.[a-z]+/.test(placeholder))) {
      placeholder = translate(placeholder);
    }

    const shouldShowValidations = forceShowValidations || (firstChangeOcurred && hasBlurred);

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (firstChangeOcurred) {
        setHasBlurred(true);
      }

      if (onBlur) onBlur(event);
    };

    const handleChange = (value: string | number) => {
      // If this is the first time the user changes the input then lets 'save' it
      // for future referene for error handling.
      const valueChanged = value !== "" || value !== inputProps.value;
      if (!firstChangeOcurred && valueChanged) {
        setFirstChangeOcurred(true);
      }

      if (onChange) onChange(value);
    };

    return (
      <div>
        <GlobalCSS
          styles={{
            ".fadeIn-enter": {
              opacity: 0.01,
              transition: "opacity 0.2s ease",
              WebkitTransition: "opacity 0.2s ease",
            },
            ".fadeIn-enter.fadeIn-enter-active": {
              opacity: 1,
              transition: "opacity 0.2s ease-in",
              WebkitTransition: "opacity 0.2s ease-in",
            },
            ".fadeIn-leave": {
              opacity: 1,
              transition: "opacity 0.2s ease-in",
              WebkitTransition: "opacity 0.2s ease-in",
            },
            ".fadeIn-leave.fadeIn-leave-active": {
              opacity: 0.01,
            },
          }}
        />
        <div className="validated-input" style={styles.inputWrap}>
          <InputText
            {...inputProps}
            ref={inputRef}
            autoCapitalize="off"
            autoCorrect="off"
            onChange={handleChange}
            placeholder={{ text: placeholder }}
            onBlur={handleBlur}
            danger={shouldShowValidations && valid === false}
            id={id}
            sx={{
              "::-ms-reveal": {
                display: "none",
                width: 0,
                height: 0,
              },
            }}
          />
          {withIcon && (
            <TransitionGroup>
              {shouldShowValidations && valid === true && (
                <CSSTransition classNames="fadeIn" timeout={200}>
                  <div style={styles.iconWrap} key={0}>
                    <CheckmarkIcon size="s" color="aqua50" />
                  </div>
                </CSSTransition>
              )}
              {shouldShowValidations && valid === false && (
                <CSSTransition classNames="fadeIn" timeout={200}>
                  <div style={styles.iconWrap} key={1}>
                    <CloseIcon size="s" color="watermelon60" />
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          )}
        </div>
        {shouldShowValidations && <StatusIndicator valid={valid} />}
        {shouldShowValidations && <ValidationMessage message={message} valid={valid} />}
      </div>
    );
  },
);

ValidatedInput.propTypes = {
  valid: PropTypes.bool,
  message: PropTypes.string,
  withIcon: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder: translatableValidator as any,
  forceShowValidations: PropTypes.bool,
  "data-test-name": PropTypes.string,
};

export default ValidatedInput;

const ValidationMessage = ({ message, valid }: Pick<ValidatedInputProps, "message" | "valid">) => {
  // If the message comes in a 'error object' then the message is gonna
  // be contained inside.
  if (isObject(message) && message.message) message = message.message;

  // If no message then just return nothing.
  if (!message) return null;

  if (valid === false) {
    return (
      <div style={{ ...styles.message, ...styles.messageFail }} role="alert">
        {message}
      </div>
    );
  }

  // If the user intereacted with the input and props.valid === true or
  // props.valid was not provide but a message was provided then just show it.
  return <div style={styles.message}>{message}</div>;
};

const StatusIndicator = ({ valid }: Pick<ValidatedInputProps, "valid">) => {
  let classStr = "input-status-indicator";
  let statusIndicator = null;

  const validPropSet = valid === false || valid === true;

  if (validPropSet) {
    if (!valid) classStr += " fail";
    statusIndicator = <div className={classStr} />;
  }

  return statusIndicator;
};

const styles = {
  inputWrap: {
    position: "relative" as const,
  },
  iconWrap: {
    position: "absolute" as const,
    top: "50%",
    right: "1.5rem",
    marginTop: "-0.8rem",
    width: "1.4rem",
    height: "1.4rem",
  },
  message: {
    margin: "0.5rem 1rem 1.5rem",
    fontSize: "1.2rem",
    color: palette.gray50,
  },
  messageFail: {
    color: palette.dojoRed,
  },
};
