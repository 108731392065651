/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type SortIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let SortIcon: NessieFunctionComponent<SortIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12.496 18.06L8.28 12.852l1.774-1.774a.763.763 0 00.185-.322c.005-.013.01-.026.012-.04a.738.738 0 00.019-.16l-.002-.012a.74.74 0 00-.62-.716l-7.795-.818a.74.74 0 00-.845.844l.818 7.796a.73.73 0 00.233.423h.001a.757.757 0 00.266.163.74.74 0 00.75-.181l1.774-1.774 5.21 4.215a1.721 1.721 0 102.435-2.434zM11.505 5.94l4.214 5.209-1.773 1.774a.76.76 0 00-.186.321c-.004.014-.01.027-.012.041a.738.738 0 00-.019.16l.002.012a.74.74 0 00.62.716l7.795.818a.74.74 0 00.845-.845l-.818-7.795a.73.73 0 00-.233-.423h-.001a.752.752 0 00-.266-.163.74.74 0 00-.75.181L19.148 7.72l-5.21-4.215a1.721 1.721 0 10-2.434 2.434z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SortIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  SortIcon = nessiePropCheckerWrapper(SortIcon);
}

export default SortIcon;
