/** @jsxImportSource theme-ui */
import * as React from "react";
import translate from "../../../utils/translate";
import { UnstyledButton } from "../../buttons";
import { Button } from "../../../nessie";

export type MultiStageProps = {
  children: React.ReactNode;
  nextText?: string;
  backText?: string;
  doneText?: string;
  onNext?: (stageId: number) => void;
  onPrev?: (stageId: number) => void;
  close: () => void;
};

function MultiStage(props: MultiStageProps) {
  const [stageIdx, setStageIdx] = React.useState(0);

  const items = React.Children.toArray(props.children);

  // Next
  const nextText = props.nextText || "Next";
  const doneText = props.doneText || "Done";
  const nextExists = stageIdx < React.Children.count(props.children) - 1;

  // Back
  const backText = props.backText || "Back";

  const changeStage = (amt: number): void => {
    if (amt > 0) {
      // we're moving forward
      !!props.onNext && props.onNext(stageIdx);
    }

    if (amt < 0) {
      //we're moving backwards
      !!props.onPrev && props.onPrev(stageIdx);
    }

    let newIdx = stageIdx + amt;
    if (newIdx >= React.Children.count(props.children)) newIdx = -1;
    setStageIdx(newIdx);
  };

  return (
    <div>
      {items[stageIdx]}
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "dt_m",
          position: "relative",
          justifyContent: "flex-end",
        }}
      >
        {stageIdx > 0 ? (
          <Button kind="tertiary" size="s" onClick={() => changeStage(-1)}>
            {translate(backText)}
          </Button>
        ) : null}
        <div sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          {items.map((_, idx) => (
            <UnstyledButton
              key={idx}
              sx={dotStyles}
              onClick={() => setStageIdx(idx)}
              aria-pressed={idx === stageIdx}
              data-test-name="dot"
              aria-label={`Go to stage ${idx + 1}`}
            />
          ))}
        </div>
        <Button onClick={nextExists ? () => changeStage(1) : props.close} size="s" sx={{ marginLeft: "auto" }}>
          {translate(nextExists ? nextText : doneText)}
        </Button>
      </div>
    </div>
  );
}

export default MultiStage;

const dotStyles = {
  display: "inline-block",
  marginX: "dt_s",
  width: "0",
  borderWidth: "0.5rem",
  borderRadius: "dt_radius_round",
  borderStyle: "solid",
  borderColor: "dt_taro20",
  '&[aria-pressed="true"]': {
    cursor: "default",
    borderColor: "dt_aqua50",
  },
};
