/** @jsxImportSource theme-ui */
import pickBy from "lodash/pickBy";
import { useCallback, useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Popover, { popoverMaxWidth as defaultPopoverMaxWidth } from "./Popover";
import calculatePosition from "./CalculatePosition";
import { AbsolutePositioner } from "../positioning";
import WithClickOutside from "../misc/WithClickOutside";
import { handleActionKeyDown } from "../../utils/keyboard";
import { UnstyledButton } from "../buttons";

/**
 * Component for wrapping an element that should display our a popover
 * when clicked.
 */

const POPOVER_TRIGGER_PROPTYPES = {
  // Content of the popover to render
  popoverContent: PropTypes.any.isRequired,

  // Configuration for the popover's position
  popoverPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  popoverCaret: PropTypes.bool, // defaults to true
  popoverOffset: PropTypes.string,
  popoverCaretOffset: PropTypes.string,
  popoverGutter: PropTypes.number,
  popoverAlign: PropTypes.oneOf(["left", "right", "center"]), // chosen based on popoverPosition if not specified

  // Configuration for the popover
  popoverTextSize: PropTypes.string,
  popoverTextColor: PropTypes.string,
  popoverBackgroundColor: PropTypes.string,
  popoverMaxWidth: PropTypes.string,
  popoverPadding: PropTypes.string,
  popoverCaretSize: PropTypes.string,

  // Force the popover to be open.
  isOpen: PropTypes.bool,

  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

type PopoverTriggerProps = {
  isOpen?: boolean;
  onOpen?: React.ReactEventHandler;
  onClose?: (event: React.SyntheticEvent | Event) => void;
  ["aria-label"]?: string;
  ["data-test-name"]?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
} & PopoverAtPositionProps &
  Omit<JSX.IntrinsicElements["button"], "style" | "children" | "onMouseEnter" | "onMouseLeave" | "onClick">;

const PopoverTrigger = ({
  isOpen,
  onOpen,
  onClose,
  children,
  style,
  popoverPosition = "top",
  popoverCaret = true,
  popoverOffset,
  popoverCaretOffset,
  popoverGutter,
  popoverAlign,
  popoverCaretSize,
  popoverTextSize,
  popoverTextColor,
  popoverBackgroundColor,
  popoverMaxWidth = defaultPopoverMaxWidth,
  popoverPadding,
  popoverContent,
  ["aria-label"]: ariaLabel,
  ["data-test-name"]: dataTestName,
}: PopoverTriggerProps): JSX.Element => {
  const [isOpenState, setIsOpenState] = useState(false);

  const open = useCallback(
    (e: React.SyntheticEvent) => {
      if (!isOpenState) {
        setIsOpenState(true);
        if (onOpen) {
          onOpen(e);
        }
      }
    },
    [isOpenState, onOpen],
  );

  const close = useCallback(
    (e: React.SyntheticEvent | Event) => {
      if (isOpenState) {
        setIsOpenState(false);
        if (onClose) {
          onClose(e);
        }
      }
    },
    [isOpenState, onClose],
  );

  const togglePopover = useCallback(
    (e: React.SyntheticEvent) => {
      if (isOpenState) close(e);
      else open(e);
    },
    [close, isOpenState, open],
  );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") close(e);
    handleActionKeyDown({ onAction: togglePopover, onCancel: close });
  };

  return (
    <WithClickOutside onClickOutside={close}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div sx={{ position: "relative" }} onKeyDown={onKeyDown}>
        {/* children need to be wrapped in a different div than the popover at position, otherwise it will mess up the focus styles of the container in safari */}
        <UnstyledButton
          style={{ ...styles.wrapper, ...style }}
          onClick={togglePopover}
          onKeyDown={onKeyDown}
          aria-label={ariaLabel}
          aria-haspopup={true}
          aria-expanded={isOpenState || isOpen ? true : false}
          data-test-name={dataTestName}
        >
          {children}
        </UnstyledButton>
        {(isOpenState || isOpen) && (
          <PopoverAtPosition
            popoverPosition={popoverPosition}
            popoverCaret={popoverCaret}
            popoverOffset={popoverOffset}
            popoverCaretOffset={popoverCaretOffset}
            popoverGutter={popoverGutter}
            popoverAlign={popoverAlign}
            popoverCaretSize={popoverCaretSize}
            popoverTextSize={popoverTextSize}
            popoverTextColor={popoverTextColor}
            popoverBackgroundColor={popoverBackgroundColor}
            popoverMaxWidth={popoverMaxWidth}
            popoverPadding={popoverPadding}
            popoverContent={popoverContent}
          />
        )}
      </div>
    </WithClickOutside>
  );
};

PopoverTrigger.propTypes = POPOVER_TRIGGER_PROPTYPES;

export default PopoverTrigger;

type PopoverAtPositionProps = {
  popoverPosition?: "top" | "bottom" | "left" | "right";
  popoverCaret?: boolean;
  popoverOffset?: string;
  popoverCaretOffset?: string;
  popoverGutter?: number;
  popoverAlign?: "left" | "center" | "right";
  popoverCaretSize?: string;
  popoverTextSize?: string;
  popoverTextColor?: string;
  popoverBackgroundColor?: string;
  popoverMaxWidth?: string;
  popoverPadding?: string;
  popoverContent: React.ReactNode;
};

const PopoverAtPosition = ({
  popoverPosition,
  popoverCaret,
  popoverOffset,
  popoverCaretOffset,
  popoverGutter,
  popoverAlign,
  popoverCaretSize,
  popoverTextSize,
  popoverTextColor,
  popoverBackgroundColor,
  popoverMaxWidth,
  popoverPadding,
  popoverContent,
}: PopoverAtPositionProps): JSX.Element => {
  const { caret, positionerProps } = calculatePosition({
    position: popoverPosition,
    caret: popoverCaret,
    offset: popoverOffset,
    caretOffset: popoverCaretOffset,
    gutter: popoverGutter,
    width: popoverMaxWidth,
    align: popoverAlign,
  });

  // need to do this so undefineds don't override default popover props.
  const popoverProps = pickBy(
    {
      caret,
      caretOffset: popoverCaretOffset,
      caretSize: popoverCaretSize,
      textSize: popoverTextSize,
      textColor: popoverTextColor,
      backgroundColor: popoverBackgroundColor,
      maxWidth: popoverMaxWidth,
      padding: popoverPadding,
    },
    (val) => val != null,
  );

  return (
    <AbsolutePositioner {...positionerProps}>
      <Popover {...popoverProps}>
        <div style={styles.popoverContentWrapper}>{popoverContent}</div>
      </Popover>
    </AbsolutePositioner>
  );
};

// @revisit-styles-fix
const styles = {
  wrapper: {
    position: "relative" as const,
    display: "inline-block",
    cursor: "pointer",
    // outline: 0,
  },
  popoverContentWrapper: {
    cursor: "default",
  },
};
