/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type UnlockIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let UnlockIcon: NessieFunctionComponent<UnlockIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.094 10.329h-.953V6.682C17.14 4.113 15.028 2 12.459 2c-2.57 0-4.683 2.113-4.683 4.682v.125c0 .165.125.29.29.29h1.41c.165 0 .29-.125.29-.29v-.125a2.688 2.688 0 012.693-2.693 2.688 2.688 0 012.693 2.693v3.647H6.865A1.857 1.857 0 005 12.193l.829 8.66c0 1.037.828 1.865 1.864 1.865h9.614a1.857 1.857 0 001.864-1.864l.829-8.66c-.041-.995-.87-1.865-1.906-1.865zm-4.641 9.364h-1.947l-.373-3.107a1.38 1.38 0 011.367-1.368 1.38 1.38 0 011.367 1.368l-.414 3.107z"
          />
          <mask maskUnits="userSpaceOnUse" x={5} y={2} width={15} height={21}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.094 10.329h-.953V6.682C17.14 4.113 15.028 2 12.459 2c-2.57 0-4.683 2.113-4.683 4.682v.125c0 .165.125.29.29.29h1.41c.165 0 .29-.125.29-.29v-.125a2.688 2.688 0 012.693-2.693 2.688 2.688 0 012.693 2.693v3.647H6.865A1.857 1.857 0 005 12.193l.829 8.66c0 1.037.828 1.865 1.864 1.865h9.614a1.857 1.857 0 001.864-1.864l.829-8.66c-.041-.995-.87-1.865-1.906-1.865zm-4.641 9.364h-1.947l-.373-3.107a1.38 1.38 0 011.367-1.368 1.38 1.38 0 011.367 1.368l-.414 3.107z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
UnlockIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  UnlockIcon = nessiePropCheckerWrapper(UnlockIcon);
}

export default UnlockIcon;
