/** @jsxImportSource theme-ui */
import { useMemo } from "react";
import * as React from "react";
import PropTypes from "prop-types";

import omit from "lodash/omit";
import translate from "../../utils/translate";
import { TranslatableProps } from "../../prop_shapes/translatable";
import UnstyledElasticTextArea from "./UnstyledElasticTextArea";

type ElasticTextAreaProps = React.ComponentPropsWithoutRef<typeof UnstyledElasticTextArea> & {
  placeholderText?: string | TranslatableProps;
  "aria-label"?: string | TranslatableProps;
};

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholderText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  wrapperStyle: PropTypes.object,
  textAreaStyle: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

const omittableProps = Object.keys(propTypes).concat(["placeholderText"]);

const ElasticTextArea = React.forwardRef<React.ElementRef<typeof UnstyledElasticTextArea>, ElasticTextAreaProps>(
  function ElasticTextArea(props, ref) {
    const rest = omit(props, omittableProps);
    const placeholder = translate(props.placeholderText);
    const textAreaStyle = useMemo(() => ({ ...styles.textarea, ...props.textAreaStyle }), [props.textAreaStyle]);
    const { "aria-label": ariaLabel } = props;
    return (
      <UnstyledElasticTextArea
        ref={ref}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        wrapperStyle={props.wrapperStyle}
        textAreaStyle={textAreaStyle}
        placeholder={placeholder}
        aria-label={ariaLabel}
        {...rest}
      />
    );
  },
);
ElasticTextArea.propTypes = propTypes;

export default ElasticTextArea;

const styles = {
  textarea: {
    maxHeight: "94px",
    border: "1px solid",
    borderColor: "dt_taro60",
    borderRadius: "dt_radius_s",
    background: "#fff",
    "::placeholder": {
      color: "dt_taro60",
    },
  },
};
