/** @jsxImportSource theme-ui */
import { ComponentPropsWithoutRef } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import ScrollableModal from "./Scrollable";
import T from "../misc/T";
import { buttonValidator } from "../../prop_shapes/buttons";
import { RAW_cssValue } from "../../nessie/stylingLib";
import translate from "../../utils/translate";
import { Button } from "../../nessie";
import ModalCloseButton from "./CloseButton";

type AnnouncementModalProps = {
  showCloseButton?: boolean;
  close: React.MouseEventHandler;
  text: ComponentPropsWithoutRef<typeof T>;
  cta: ComponentPropsWithoutRef<typeof Button> & { str?: string };
  header?: ComponentPropsWithoutRef<typeof T>;
  image?: string;
  imageStyle?: React.CSSProperties;
};

const AnnouncementModal = ({
  showCloseButton,
  close,
  text,
  cta,
  header,
  image,
  imageStyle,
}: AnnouncementModalProps): JSX.Element => {
  return (
    <ScrollableModal
      style={styles.modal}
      onRequestHide={close}
      label={translate({ str: "dojo.common:info.announcement", fallback: "announcement" })}
    >
      <div style={styles.modalContents}>
        {showCloseButton && <ModalCloseButton close={close} />}
        {image && (
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              borderTopLeftRadius: RAW_cssValue("3px"),
              borderTopRightRadius: RAW_cssValue("3px"),
              minHeight: "15rem",
              overflow: "hidden",
            }}
          >
            <img alt="" src={image} style={{ ...styles.image, ...imageStyle }} />
          </div>
        )}
        <div style={styles.textSection}>
          <div sx={{ display: "flex", flexDirection: "column" }}>
            {header && (
              <div sx={{ marginBottom: "dt_xl" }}>
                <h3 style={styles.header}>
                  <T {...header} />
                </h3>
              </div>
            )}
            <div sx={{ marginBottom: "dt_xl" }}>
              <T {...text} />
            </div>
            <Button onClick={close} {...cta} size="s" sx={{ margin: "auto" }}>
              {translate(cta)}
            </Button>
          </div>
        </div>
      </div>
    </ScrollableModal>
  );
};

AnnouncementModal.propTypes = {
  close: PropTypes.func.isRequired,
  image: PropTypes.string,
  header: PropTypes.object, // translatable
  text: PropTypes.object.isRequired, // translatable
  cta: buttonValidator,
  learnMore: buttonValidator,
  showCloseButton: PropTypes.bool,
};

export default AnnouncementModal;

const styles = {
  modal: {
    width: "50rem",
  },
  modalContents: {
    overflow: "hidden",
    margin: 0,
  },
  closeButton: {
    color: "white",
    textShadow: "#555 0px 1px 5px",
  },
  textSection: {
    textAlign: "center" as const,
    padding: "3rem 4rem",
    fontSize: "1.8rem",
  },
  image: {
    minHeight: "5rem",
    margin: "-1px",
    maxWidth: "50.2rem",
  },
  header: {
    margin: 0,
    fontSize: "2rem",
  },
};
