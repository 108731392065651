/** @jsxImportSource theme-ui */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import * as React from "react";
import { Portal } from "./Portal";
import Dock from "react-dock";
import { Action, Caption, Heading, Text } from "./Text";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  NessiePropTypeError,
  isNessiePropTypeValidatorSingleProp,
} from "./propTypes";

export const nessiePropCheckerWrapper = <T extends NessieComponentProps & { [key: string]: unknown }>(
  Component: NessieFunctionComponent<T>,
) => {
  return (props: T) => {
    let validationError: NessiePropTypeError | undefined;

    if (!props._dangerouslyDisableNessiePropTypes) {
      Object.keys(props).some((prop) => {
        const validatePropType = Component.nessiePropTypes && Component.nessiePropTypes[prop];
        if (isNessiePropTypeValidatorSingleProp(validatePropType)) {
          validationError = validatePropType(props, prop, Component.displayName);
          return !!validationError;
        }
      });
    }

    const [expanded, setExpanded] = useState(false);

    if (validationError) {
      return (
        <React.Fragment>
          <Portal>
            <div
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              sx={{ backgroundColor: "dt_watermelon50", cursor: "not-allowed", position: "relative" }}
            >
              {!expanded && <SeeMoreButton onClick={() => setExpanded(true)} />}
              {expanded && (
                <Dock position="right" fluid isVisible={true} dimStyle={{ opacity: 0.2 }}>
                  <div sx={{ padding: "dt_m", cursor: "auto", position: "relative" }}>
                    <div
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        setExpanded(false);
                      }}
                      sx={{ cursor: "pointer", display: "inline-block", paddingBottom: "dt_s", position: "relative" }}
                    >
                      <Action>Close</Action>
                    </div>
                    <ErrorMessage title={validationError.title} description={validationError.description} />
                  </div>
                </Dock>
              )}
            </div>
          </Portal>
          <Component {...props} style={{ outline: "3px dotted red" }} />
        </React.Fragment>
      );
    }

    return <Component {...props} />;
  };
};

type SeeMoreButtonProps = {
  onClick: () => void;
};

const SeeMoreButton = ({ onClick }: SeeMoreButtonProps) => (
  <div
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      onClick();
    }}
    sx={{
      backgroundColor: "dt_watermelon50",
      color: "dt_white",
      cursor: "pointer",
      zIndex: "9999",
      position: "fixed",
      top: "10px",
      left: "10px",
      border: "dt_border_card",
      borderRadius: "dt_radius_s",
      paddingLeft: "dt_l",
      paddingRight: "dt_l",
      paddingTop: "dt_xs",
      paddingBottom: "dt_xs",
    }}
  >
    See more
  </div>
);

type ErrorMessageProps = {
  title: string;
  description: string;
};

const ErrorMessage = (props: ErrorMessageProps) => (
  <div>
    <Heading>{props.title}</Heading>
    <Text sx={{ whiteSpace: "initial" }}>
      <span dangerouslySetInnerHTML={{ __html: props.description }} />
    </Text>

    <div sx={{ marginTop: "dt_l", position: "relative" }}>
      <Caption kind="inactive">
        Nessie custom prop types:
        <br />
        If you are having issues with a validation that is not doing what you expect you can disable all nessie custom
        validations for this component by adding the prop <strong>'_dangerouslyDisableNessiePropTypes'</strong>. Use
        this only if you consider there is a bug with nessie custom prop types and report it as a Nessie issue.
      </Caption>
    </div>
  </div>
);
