/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type ShuffleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let ShuffleIcon: NessieFunctionComponent<ShuffleIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7 10.491V9h-2.32c-.762 0-1.237.306-1.65.852-.463.615-.794 1.467-1.195 2.507l-.01.024c-.37.963-.809 2.102-1.5 2.984C9.274 16.323 8.212 17 6.686 17H3a1 1 0 010-2h3.687c.825 0 1.336-.322 1.764-.867.479-.61.82-1.46 1.218-2.492l.044-.116c.36-.935.78-2.027 1.42-2.877C11.854 7.694 12.882 7 14.38 7h2.32V5.509a.5.5 0 01.803-.398l3.274 2.491a.5.5 0 010 .796l-3.274 2.491a.5.5 0 01-.803-.398zm0 4.51v-1.492a.5.5 0 01.803-.398l3.274 2.491a.5.5 0 010 .796l-3.274 2.491a.5.5 0 01-.803-.398v-1.49h-2.32c-1.237 0-2.153-.474-2.84-1.175.422-.635.747-1.324 1.006-1.945.06.094.12.182.184.267.413.546.888.852 1.65.852h2.32zM6.687 7c1.276 0 2.226.472 2.942 1.185-.41.638-.729 1.332-.983 1.954a3.973 3.973 0 00-.195-.273C8.023 9.323 7.512 9 6.687 9H3a1 1 0 110-2h3.687z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ShuffleIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  ShuffleIcon = nessiePropCheckerWrapper(ShuffleIcon);
}

export default ShuffleIcon;
