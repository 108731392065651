// taken from: https://gist.github.com/THEtheChad/1297590
function parseColor(color: string) {
  // special case for white and black, since these are common.
  if (color === "white") return [255, 255, 255, 1];
  if (color === "black") return [0, 0, 0, 1];

  color = color.replace(/\s\s*/g, ""); // Remove all spaces
  let output;
  // Checks for 6 digit hex and converts string to integer
  if ((output = /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.exec(color))) {
    output = [parseInt(output[1], 16), parseInt(output[2], 16), parseInt(output[3], 16)];

    // Checks for 3 digit hex and converts string to integer
  } else if ((output = /^#([\da-fA-F])([\da-fA-F])([\da-fA-F])/.exec(color))) {
    output = [parseInt(output[1], 16) * 17, parseInt(output[2], 16) * 17, parseInt(output[3], 16) * 17];

    // Checks for rgba and converts string to
    // integer/float using unary + operator to save bytes
  } else if ((output = /^rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color))) {
    output = [+output[1], +output[2], +output[3], +output[4]];

    // Checks for rgb and converts string to
    // integer/float using unary + operator to save bytes
  } else if ((output = /^rgb\(([\d]+),([\d]+),([\d]+)\)/.exec(color))) {
    output = [+output[1], +output[2], +output[3]];

    // Otherwise return a null
  } else {
    return null;
  }

  // Performs RGBA conversion by default
  if (isNaN(output[3])) output[3] = 1;

  return output;
}

function unparseColor([R, G, B, O = 1]: [number, number, number, number?]) {
  R = R < 0 ? 0 : R > 255 ? 255 : Math.round(R);
  G = G < 0 ? 0 : G > 255 ? 255 : Math.round(G);
  B = B < 0 ? 0 : B > 255 ? 255 : Math.round(B);
  return `rgba(${R}, ${G}, ${B}, ${O})`;
}

export function lighten(color: string, percent: number): string {
  const parsed = parseColor(color);
  if (parsed == null) return color; // if we can't parse color, give up.
  const [R, G, B, O] = parsed;
  const P = percent / 100;
  return unparseColor([R + P * (255 - R), G + P * (255 - G), B + P * (255 - B), O]);
}

export function darken(color: string, percent: number): string {
  const parsed = parseColor(color);
  if (parsed == null) return color; // if we can't parse color, give up.
  const [R, G, B, O] = parsed;
  const P = percent / 100;
  return unparseColor([R - R * P, G - G * P, B - B * P, O]);
}

export function setOpacity(color: string, opacity: number): string {
  const parsed = parseColor(color);
  if (parsed == null) return color; // if we can't parse color, give up.
  const [R, G, B] = parsed;
  return unparseColor([R, G, B, opacity]);
}
