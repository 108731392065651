/** @jsxImportSource theme-ui */
import * as React from "react";
import Tooltip from "./Tooltip";
import { palette } from "../commonStyles";

export const popoverMaxWidth = "30rem";

type PopoverProps = React.ComponentPropsWithoutRef<typeof Tooltip>;

const Popover = (props: PopoverProps): JSX.Element => {
  return (
    <Tooltip
      caretSize="1.3rem"
      textColor={palette.gray80}
      backgroundColor="#ffffff"
      padding="2rem"
      maxWidth={popoverMaxWidth}
      {...props}
    />
  );
};

export default Popover;
