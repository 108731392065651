/** @jsxImportSource theme-ui */
import { DetailText } from "./Text";
import { textFieldsTextStyles } from "./Text";
import { StylingLibCSSObject } from "../../nessie/stylingLib";
import { uniqueId } from "lodash/fp";
import { useState } from "react";

export interface TextFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  valid?: boolean;
  label?: string;
  validationMessage?: string;
  message?: string;
  onChange?: (value: string) => void;
  /**
   * for passwords use PasswordTextField
   */
  type?: "text" | "number" | "email" | "search" | "tel" | "url";
  "data-test-name"?: string;
}

export function TextField({
  valid = true,
  label,
  maxLength,
  validationMessage,
  message,
  onChange,
  className,
  id,
  ...props
}: TextFieldProps) {
  const [inputId] = useState(uniqueId("textFieldInputId"));
  const [touched, setTouched] = useState(false);
  const descriptionId = `${inputId}-description`;
  const alertId = `${inputId}-alert`;
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    onChange?.(value);
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "xs",
        ":focus-within": {
          [`#${descriptionId}`]: valid ? visibleStyles : {},
        },
      }}
      className={className}
      onBlur={() => {
        if (!touched) setTouched(true);
      }}
    >
      {label && (
        <label htmlFor={id || inputId}>
          <DetailText sx={{ paddingLeft: "xs", fontSize: "18px" }}>{label}</DetailText>
        </label>
      )}
      <input
        maxLength={maxLength || undefined}
        onChange={handleChange}
        sx={{ ...textFieldStyles, ...(!valid && touched ? invalidTextFieldStyle : {}) }}
        id={id || inputId}
        aria-describedby={descriptionId}
        {...props}
      />
      {validationMessage && !valid && touched ? (
        <DetailText
          data-test-name="textFieldValidationMessage"
          sx={{ paddingLeft: "xs" }}
          role="alert"
          color="watermelon60"
          id={alertId}
        >
          {validationMessage}
        </DetailText>
      ) : null}
      {message && (
        <DetailText
          data-test-name="textFieldMessage"
          sx={{ paddingLeft: "xs", ...invisibleStyles }}
          color="dt_taro50"
          id={descriptionId}
        >
          {message}
        </DetailText>
      )}
    </div>
  );
}

export const TEXT_FIELD_HEIGHT = 60;

export const textFieldStyles: StylingLibCSSObject = {
  boxSizing: "border-box",
  outline: "none",
  width: "100%",
  height: TEXT_FIELD_HEIGHT,
  paddingY: "dt_m",
  paddingX: "dt_l",
  ...textFieldsTextStyles,
  borderRadius: "dt_radius_xs",
  border: "2px solid",
  borderColor: "dt_taro60",
  caretColor: "dt_aqua60",
  ":active, :focus": {
    borderColor: "dt_aqua50",
    color: "dt_taro90",
  },
  ":disabled": {
    backgroundColor: "dt_taro10",
    borderColor: "dt_taro30",
    color: "dt_taro50",
    "::placeholder": {
      color: "dt_taro50",
    },
  },
  "::placeholder": {
    ...textFieldsTextStyles,
    color: "dt_taro60",
  },
};

export const invalidTextFieldStyle = {
  borderColor: "dt_watermelon60",
  ":active, :focus": {
    borderColor: "dt_watermelon60",
  },
};

/**
 * it is important that this text is always rendered to help with context for screen readers
 */
const invisibleStyles: StylingLibCSSObject = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
};
const visibleStyles: StylingLibCSSObject = {
  position: "relative",
  left: "0",
  top: "0",
  width: "fit-content",
  height: "fit-content",
  overflow: "hidden",
};
