/** @jsxImportSource theme-ui */
import { cloneDeep, isArray, isObject } from "lodash/fp";
import { isResponsiveValue, transformResponsiveObjectIntoResponsiveArray } from "./responsiveValues";
import { CSSPropName, CSSPropValue, validateCssPropValue } from "./validateCssPropValue";
import { __CssValueClass } from "../RAW_cssValue";
import { isProd } from "../../../utils/env";
import { StylingLibCSSObject, ThemeUIStyleObject } from "..";

type ValidationContext = {
  location: string;
  errors: any[];
};

const isStylingLibCSSObject = (cssPropValue: CSSPropValue): cssPropValue is StylingLibCSSObject =>
  isObject(cssPropValue) && typeof cssPropValue !== "function" && !isArray(cssPropValue);

export const parseProps = <P extends { sx?: ThemeUIStyleObject }>(props: P, validationContext: ValidationContext) => {
  // We are making a copy of the sx property value so we don't mutate the original value
  // being passed into the component when doing validation and transformation of values.
  //
  // As a performance optimization, we might look into which cases this is really needed
  // and only do it when it is necessary.
  const newSx = cloneDeep(props.sx);
  props.sx = newSx;

  if (props.sx && typeof props.sx !== "function") {
    // validate values being used in `sx` property
    for (const prop of Object.keys(props.sx) as Array<CSSPropName>) {
      const value = validateAndProcessResponsiveStyles(prop, props.sx[prop], validationContext);
      props.sx[prop] = value as CSSPropValue;
    }
  }
};

export function validateAndProcessResponsiveStyles(
  cssPropName: CSSPropName,
  cssPropValue: CSSPropValue,
  validationContext: ValidationContext,
) {
  const isResponsivePropValue = isResponsiveValue(cssPropValue);

  // **
  // IMPORTANT: THIS ONE WE WANT FOR PROD AND DEV
  // We want to be able to use object for responsive
  //
  // css={ ":hover": { color: '' }}
  //
  if (isStylingLibCSSObject(cssPropValue) && !(cssPropValue instanceof __CssValueClass)) {
    // process each sub-property of the current prop value
    for (const subPropName of Object.keys(cssPropValue) as Array<keyof typeof cssPropValue>) {
      const value = validateAndProcessResponsiveStyles(
        // if prop value is responsive, validate each of the specified breakpoint values
        //  using the current cssPropName, instead of the breakpoint sub-prop name
        isResponsivePropValue ? cssPropName : subPropName,
        cssPropValue[subPropName],
        validationContext,
      );
      cssPropValue[subPropName] = value as StylingLibCSSObject[keyof StylingLibCSSObject];
    }
  }

  //
  // TODO: should we prevent arrays from being used directly as prop values?
  //

  // **
  // IMPORTANT: THIS ONE WE WANT FOR PROD AND DEV
  // Early return for:
  // Responsive values
  // cssPropValue in this case is something like: {dt_viewport_s: "pitaya", dt_viewport_xl: 'mango' }
  if (isResponsivePropValue && isStylingLibCSSObject(cssPropValue)) {
    // transform the responsive object value into an array,
    // individual values validation will happen further down
    return transformResponsiveObjectIntoResponsiveArray(cssPropValue);
  }

  //
  // *************************************
  // Nothing else to do for production environment after this point,
  // so do early return
  //
  if (isProd) return cssPropValue;

  //
  // *************************************
  // ONLY FOR DEV - CSS Property values validation
  // 👇👇
  //

  // **
  // IMPORTANT: ONLY FOR DEV
  // In production the __CssValueClass will handle it automaticalyl
  // Early return for:
  // Unsafe
  if (cssPropValue instanceof __CssValueClass) {
    return cssPropValue.val;
  }

  // **
  // Early return for:
  // IMPORTANT: ONLY FOR DEV
  // Props from the theme
  // color: 'pitaya'
  return validateCssPropValue(cssPropName, cssPropValue, validationContext);
}
