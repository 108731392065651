/** @jsxImportSource theme-ui */
import commonStyles from "../../commonStyles";

const rem = (unit: number) => `${unit}rem`;

const iconSize = 5.6;
const iconPadding = 0.5;
const iconGutter = 1;

const rowHeight = iconSize + 2 * iconPadding + iconGutter;

const centeredTransform = "translate(-50%, 0)";

export default {
  iconSize,
  iconPadding,
  iconGutter,

  toggleContainerFocused: {
    backgroundColor: commonStyles.palette.gray10,
  },
  toggleImage: {
    height: "100%",
  },
  caret: {
    bottom: 5,
    right: 10,
    position: "absolute" as const,
    width: 0,
    height: 0,
    border: "0.4rem solid transparent",
    borderTop: `0.4rem solid ${commonStyles.palette.gray50}`,
  },

  // Picker
  pickerContainer: {
    backgroundColor: "white",
    border: `1px solid ${commonStyles.palette.gray20}`,
    marginTop: ".5rem",
    borderRadius: ".5rem",
    position: "absolute" as const,
    left: "50%",
    padding: "2rem",
    transform: centeredTransform,
    MsTransform: centeredTransform,
    msTransform: centeredTransform,
    WebkitTransform: centeredTransform,
    boxShadow: commonStyles.shadows.medium,
    zIndex: commonStyles.zIndex.dropdown,
    maxHeight: rem(3.8 * rowHeight),
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
  },
  iconItem: {
    cursor: "pointer",
    display: "inline-block",
    borderRadius: ".5rem",
    padding: rem(iconPadding),
    outline: "none",
  },
  iconItemHovered: {
    backgroundColor: commonStyles.palette.gray10,
  },
  iconImg: {
    width: rem(iconSize),
    height: rem(iconSize),
  },
};
