/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PaintIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PaintIcon: NessieFunctionComponent<PaintIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.313 10.133l4.834-3.065c1.137-1.145 1.137-3.065 0-4.21-1.137-1.144-3.046-1.144-4.224 0l-3.006 4.823s-.65.858-1.178.735l-.731-.735c-.853-.859-1.666-.532-2.031-.245l-.488.49a.427.427 0 000 .654l5.85 5.885a.492.492 0 00.65 0l.527-.531c.285-.327.61-1.145-.243-2.003l-.69-.695c-.106-.352.422-.825.649-1.028l.081-.075zm-7.677-.777a.491.491 0 00-.65-.04l-.568.49c-3.656 3.106-5.037 3.392-5.037 3.392-.163.04-.325.163-.366.327a.54.54 0 00.122.49l.853.859a.437.437 0 00.528.081l1.422-.776-.934 1.144c-.163.204-.122.45.04.613l.69.695a.496.496 0 00.529.082l1.137-.573-.569 1.145a.504.504 0 00.082.531l.365.368c.163.163.406.163.61.04l1.259-.898-.813 1.39c-.121.203-.08.408.082.571l.325.327c.162.164.446.205.609.041l1.625-1.349-1.016 2.126a.504.504 0 00.081.531l.894.9a.457.457 0 00.447.122c.162-.041.284-.205.325-.368 0 0 .203-.9 1.828-3.106l2.071-2.452c.163-.205.163-.45-.04-.654l-5.93-6.049z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.313 10.133l4.834-3.065c1.137-1.145 1.137-3.065 0-4.21-1.137-1.144-3.046-1.144-4.224 0l-3.006 4.823s-.65.858-1.178.735l-.731-.735c-.853-.859-1.666-.532-2.031-.245l-.488.49a.427.427 0 000 .654l5.85 5.885a.492.492 0 00.65 0l.527-.531c.285-.327.61-1.145-.243-2.003l-.69-.695c-.106-.352.422-.825.649-1.028l.081-.075zm-7.677-.777a.491.491 0 00-.65-.04l-.568.49c-3.656 3.106-5.037 3.392-5.037 3.392-.163.04-.325.163-.366.327a.54.54 0 00.122.49l.853.859a.437.437 0 00.528.081l1.422-.776-.934 1.144c-.163.204-.122.45.04.613l.69.695a.496.496 0 00.529.082l1.137-.573-.569 1.145a.504.504 0 00.082.531l.365.368c.163.163.406.163.61.04l1.259-.898-.813 1.39c-.121.203-.08.408.082.571l.325.327c.162.164.446.205.609.041l1.625-1.349-1.016 2.126a.504.504 0 00.081.531l.894.9a.457.457 0 00.447.122c.162-.041.284-.205.325-.368 0 0 .203-.9 1.828-3.106l2.071-2.452c.163-.205.163-.45-.04-.654l-5.93-6.049z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PaintIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PaintIcon = nessiePropCheckerWrapper(PaintIcon);
}

export default PaintIcon;
