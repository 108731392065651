/** @jsxImportSource theme-ui */
import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import CloseButton from "../buttons/Close";
import RichText from "../misc/RichText";
import translate from "../../utils/translate";
import { getLocalizedMoment } from "../../utils/time";
import { backgroundImage } from "../../styles";
import { theme, ConfirmModal } from "../../nessie";

export type ScheduledContentListItemProps = {
  time: moment.MomentInput;
  text: string;
  image?: string | React.ReactNode;
  deleteModalHeaderText: string;
  deleteModalBodyText: string;
  deleteItem: () => void;
};

const ScheduledContentListItem = ({
  time,
  text,
  image,
  deleteModalHeaderText,
  deleteModalBodyText,
  deleteItem,
}: ScheduledContentListItemProps): JSX.Element => {
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const timeString = getLocalizedMoment(time).format("llll");

  let imageContent;
  if (typeof image === "string") {
    const style = { ...IMAGE_CONTAINER_STYLE, ...backgroundImage(image) };
    imageContent = <div style={style} />;
  } else if (image) {
    imageContent = <div style={IMAGE_CONTAINER_STYLE}>{image}</div>;
  }

  const _showDeleteModal = () => setShowingDeleteModal(true);

  const _hideDeleteModal = () => setShowingDeleteModal(false);

  return (
    <div>
      <div
        sx={{
          display: "flex",
          marginLeft: "dt_m",
          marginRight: "dt_m",
          paddingTop: "dt_s",
          paddingBottom: "dt_s",
          borderTop: `1px solid ${theme.colors.taro20}`,
        }}
      >
        <div sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div sx={{ fontWeight: 600, marginBottom: "dt_xs" }}>{timeString}</div>
          <RichText text={text} />
        </div>
        {imageContent}
        <CloseButton onClick={_showDeleteModal} />
      </div>
      {showingDeleteModal && (
        <ConfirmModal
          data-test-name="ScheduledContentListItemDeleteModal"
          title={deleteModalHeaderText}
          bodyText={deleteModalBodyText}
          confirmText={deleteModalHeaderText}
          cancelText={translate("dojo.common:actions.cancel")}
          danger
          close={_hideDeleteModal}
          confirm={deleteItem}
        />
      )}
    </div>
  );
};

ScheduledContentListItem.propTypes = {
  image: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  time: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,

  deleteModalHeaderText: PropTypes.string.isRequired,
  deleteModalBodyText: PropTypes.string.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default ScheduledContentListItem;

const IMAGE_CONTAINER_STYLE = {
  width: 60,
  height: 60,
};
