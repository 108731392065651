/** @jsxImportSource theme-ui */
import { createElement, Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const IGNORE_CLASS = "ignore-react-onclickoutside";

type WithClickOutsideProps = {
  wrapper?: string;
  onClickOutside: (evt: Event) => void;
};

export default class WithClickOutside extends Component<WithClickOutsideProps> {
  static propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  componentDidMount = (): void => {
    if (!this.props.onClickOutside) {
      throw new Error("Component lacks a onClickOutside prop, for processing outside click events.");
    }

    this.localNode = ReactDOM.findDOMNode(this) as Element | null;
    document.addEventListener("mousedown", this.handleClick);
    document.addEventListener("touchstart", this.handleClick);
  };

  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.handleClick);
    document.removeEventListener("touchstart", this.handleClick);
  }

  render(): JSX.Element {
    const { wrapper = "span" } = this.props;
    return createElement(wrapper, {
      children: this.props.children,
    });
  }

  localNode: Element | null;

  handleClick = (evt: Event): void => {
    if (this.props.onClickOutside) {
      let source = evt.target as Element | null;
      let found = false;
      // If source=local then this event came from "somewhere"
      // inside and should be ignored. We could handle this with
      // a layered approach, too, but that requires going back to
      // thinking in terms of Dom nodes nesting, running counter
      // to React's "you shouldn't care about the DOM" philosophy.
      while (source && source.parentNode) {
        found = source === this.localNode || ("classList" in source && source.classList.contains(IGNORE_CLASS));
        if (found) return;
        source = source.parentNode as Element | null;
      }
      this.props.onClickOutside(evt);
    }
  };
}
