/** @jsxImportSource theme-ui */
type ProgressBarProps = {
  value: number;
  max?: number;
  height?: number;
  borderWidth?: number;
  progressBarColor?: string;
  backgroundBarColor?: string;
};

export const ProgressBar = ({
  value,
  max = 100,
  height = 6,
  borderWidth = 0,
  progressBarColor,
  backgroundBarColor,
}: ProgressBarProps) => {
  const hasBorder = borderWidth > 0;
  const borderProps = hasBorder
    ? { borderWidth: `${borderWidth}px`, borderColor: "dt_taro30", borderStyle: "solid" }
    : {};
  return (
    <div
      sx={{
        backgroundColor: backgroundBarColor ? backgroundBarColor : hasBorder ? "dt_white" : "dt_kiwi20",
        borderRadius: "dt_radius_s",
        height,
        overflow: "hidden",
        position: "relative",
        ...borderProps,
      }}
    >
      <div
        sx={{
          backgroundColor: progressBarColor ? progressBarColor : "dt_kiwi50",
          borderRadius: "dt_radius_s",
          height: "100%",
          width: fillBar(max, value),
        }}
      />
    </div>
  );
};

function fillBar(max: number, value: number) {
  max = Number(max);
  value = Number(value);
  const filledBar = max === 100 ? `${value}%` : `${(value / max) * 100}%`;
  return filledBar;
}
