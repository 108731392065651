/** @jsxImportSource theme-ui */
import { GlobalCSS } from "../../nessie/stylingLib";
// eslint-disable-next-line no-restricted-imports
import { css } from "@emotion/react";

export default function DatePickerStyles() {
  return <GlobalCSS styles={datePickerGlobalStyles} />;
}

const datePickerGlobalStyles = css`
  /* custom react-calendar styles */

  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }

  .react-calendar {
    width: 362px;
    max-width: 100%;
    border-radius: 6px;
    font-family: "HafferXH", Arial, Helvetica, sans-serif;
    line-height: 1.125em;

    padding: 12px;
    background-color: white;
    border: 1px solid #aab0d8;
    box-shadow: 0px 3px 6px rgba(44, 42, 80, 0.1);
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar button:disabled:hover {
    cursor: not-allowed;
  }

  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 0;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: #ffffff;
  }

  .react-calendar__navigation button[disabled] svg {
    fill: #e1e2e8;
  }

  .react-calendar__navigation button svg {
    fill: #aab0d8;
  }

  /* hide buttons to skip 2 months ahead or behind */
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    order: 0;
    text-align: left;
    border-top-left-radius: 6px;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: #2c2a50;
  }

  .react-calendar__navigation__prev-button {
    order: 1;
  }

  .react-calendar__navigation__next-button {
    order: 2;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #2c2a50;
  }

  .react-calendar__month-view__days__day {
    color: #2c2a50;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
  }

  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth:enabled:hover,
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth:enabled:focus {
    color: #d3d7ec;
  }

  .react-calendar__tile--active.react-calendar__month-view__days__day--neighboringMonth {
    color: #ffffff;
  }

  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0;
    padding-top: 1px; /* nudge text down for vertical centering */
    background: none;
    position: relative;
    height: 42px;
    line-height: 42px;
    &:focus-visible {
      outline: none;
    }
  }

  /* the circle that appears below each day */
  .react-calendar__tile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 3px;
    width: 42px;
    height: 42px;
    border-radius: 24px;
  }

  .react-calendar__tile abbr {
    position: relative;
    z-index: 2;
  }

  .react-calendar__tile--now::before {
    background: transparent;
    border: solid 2px #d3d7ec;
  }

  .react-calendar__tile:disabled {
    color: #d3d7ec;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    color: white;
  }

  .react-calendar__tile:enabled:hover::before,
  .react-calendar__tile:enabled:focus::before {
    background: #aab0d8;
  }

  .react-calendar__month-view__days__day--neighboringMonth:enabled:hover::before {
    background: #eaecf5;
  }

  .react-calendar__tile--active:enabled {
    color: white !important;
    background-color: #00b2f7;
  }
  .react-calendar__tile--active:enabled::before {
    background: #00b2f7;
    border: none; /* erase the circle that surrounds the current date */
  }

  .react-calendar__tile--active:enabled:hover::before,
  .react-calendar__tile--active:enabled:focus::before {
    background: #0092e5;
  }

  /* styling the selected range */
  .react-calendar__tile--active.react-calendar__tile--rangeStart:enabled {
    background: linear-gradient(90deg, transparent 0%, transparent 50%, #00b2f7 50%, #00b2f7 100%);
  }
  .react-calendar__tile--active.react-calendar__tile--rangeEnd:enabled {
    background: linear-gradient(-90deg, transparent 0%, transparent 50%, #00b2f7 50%, #00b2f7 100%);
  }
  .react-calendar__tile--active.react-calendar__tile--rangeBothEnds:enabled {
    background: none;
  }

  /* styling the range that's in progress of being selected */
  .react-calendar--selectRange .react-calendar__tile--hover:enabled {
    background-color: #70dbff;
    color: white !important;
  }
  .react-calendar__tile--hover:enabled:before {
    border: none; /* erase the circle that surrounds the current date */
  }
  .react-calendar--selectRange .react-calendar__tile--hoverStart:enabled {
    background: linear-gradient(90deg, transparent 0%, transparent 50%, #70dbff 50%, #70dbff 100%);
  }
  .react-calendar--selectRange .react-calendar__tile--hoverEnd:enabled {
    background: linear-gradient(-90deg, transparent 0%, transparent 50%, #70dbff 50%, #70dbff 100%);
  }
  .react-calendar--selectRange .react-calendar__tile--hoverBothEnds:enabled {
    background: none;
  }

  .react-calendar--selectRange .react-calendar__tile--hover:enabled:hover::before,
  .react-calendar--selectRange .react-calendar__tile--hover:enabled:focus::before {
    background-color: #70dbff;
  }
`;
