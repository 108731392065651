/** @jsxImportSource theme-ui */
import { jsx as themeUIJsx, jsxs as themeUIJsxs } from "@theme-ui/core/jsx-runtime";
// eslint-disable-next-line no-restricted-imports
import { createElement as themeUICreateElement, ThemeUIStyleObject } from "@theme-ui/core";
import { parseProps } from "./stylingLibJsx";
import * as React from "react";
import { ValidationCollector } from "./NessieValidationErrorPanel";
import { isDev } from "../../../utils/env";

import type { ThemeUIJSX } from "../jsx-namespace";

// This is necessary for TS to know the `sx` prop exists on JSX elements
export type { ThemeUIJSX as JSX } from "../jsx-namespace";

// https://github.com/reactjs/rfcs/blob/createlement-rfc/text/0000-create-element-changes.md
//
// React now has two different JSX runtime modes:
//   - classic
//   - automatic
//
// They specify different function interfaces with different parameters being passed.
// This needs to match the way that we configure Babel on which JSX transform to use,
// so keep that in mind if doing any changes to configuration or this function.
//
// This implementation handles the "automatic" mode

export { Fragment } from "@theme-ui/core/jsx-runtime";

type PropsWithSx = {
  sx?: ThemeUIStyleObject;
  // eslint-disable-next-line camelcase
  __stylingLibInternal_type: any;
  // eslint-disable-next-line camelcase
  __stylingLibInternal_validationContext: any;
};

// this handles JSX elements where children is a single node
export const jsx = <P extends PropsWithSx>(type: React.ElementType<P>, props: P, key?: string): ThemeUIJSX.Element => {
  if (!props?.sx) {
    return themeUIJsx(type, props, key);
  }

  const validationContext = {
    location: typeof type === "string" ? type : type?.name,
    errors: [],
  };

  parseProps(props, validationContext);

  // Only run the 'nessie validation panel UI' if we are in Dev
  if (isDev) {
    props.__stylingLibInternal_type = type;
    props.__stylingLibInternal_validationContext = validationContext;
    return themeUIJsx(ValidationCollector, props, key);
  }
  return themeUIJsx(type, props, key);
};

// this handles JSX elements where children is an array
export const jsxs = <P extends PropsWithSx>(type: React.ElementType<P>, props: P, key?: string): ThemeUIJSX.Element => {
  if (!props?.sx) {
    return themeUIJsxs(type, props, key);
  }

  const validationContext = {
    location: typeof type === "string" ? type : type?.name,
    errors: [],
  };

  parseProps(props, validationContext);

  // Only run the 'nessie validation panel UI' if we are in Dev
  if (isDev) {
    props.__stylingLibInternal_type = type;
    props.__stylingLibInternal_validationContext = validationContext;
    return themeUIJsxs(ValidationCollector, props, key);
  }
  return themeUIJsxs(type, props, key);
};

// this is a fallback to the "classic" style of JSX transform
export const createElement: typeof React.createElement = (
  type: Parameters<typeof React.createElement>[0],
  props: Parameters<typeof React.createElement>[1] & PropsWithSx,
  ...children: React.ReactNode[]
) => {
  if (!props?.sx) {
    return (themeUICreateElement as any)(type, props, ...children);
  }

  const validationContext = {
    location: typeof type === "string" ? type : type?.name,
    errors: [],
  };

  parseProps(props, validationContext);

  // Only run the 'nessie validation panel UI' if we are in Dev
  if (isDev) {
    props.__stylingLibInternal_type = type;
    props.__stylingLibInternal_validationContext = validationContext;
    return (themeUICreateElement as any)(ValidationCollector, props, ...children);
  }
  return (themeUICreateElement as any)(type, props, ...children);
};
