/** @jsxImportSource theme-ui */
import * as React from "react";
import ReactModal from "react-modal";
import { NessieSpaceSizes, theme } from "./theme";
import { ClassNames, CSSInterpolation } from "../../nessie/stylingLib";

type FullscreenModalProps = {
  isOpen: boolean;
  onRequestClose?: () => void;
  overlayStyleOverrides?: CSSInterpolation;
  "data-test-name"?: string;
};

export const FullscreenModal = ({
  children,
  isOpen,
  onRequestClose,
  overlayStyleOverrides = {},
  ...otherProps
}: React.PropsWithChildren<FullscreenModalProps>) => {
  return (
    <ClassNames>
      {({ css, cx }) => {
        // This overrides styles for the div react-modal uses for the overlay
        const overlayClassName = css({
          backgroundColor: theme.colors.shaleOpacity1,
          // outline: "none",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          position: "fixed",
          zIndex: theme.zIndexes.modalOverlay,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        });

        // This overrides styles for the div react-modal uses to render the content.
        const contentClassName = css({
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          position: "relative",
          width: "100%",
          // outline: "none",
          overflowY: "auto",
        });

        return (
          <ReactModal
            data={
              otherProps["data-test-name"] && {
                "data-test-name": otherProps["data-test-name"],
              }
            }
            ariaHideApp={false}
            onRequestClose={onRequestClose}
            overlayClassName={cx(overlayClassName, css(overlayStyleOverrides))}
            className={contentClassName}
            isOpen={isOpen}
          >
            {children}
          </ReactModal>
        );
      }}
    </ClassNames>
  );
};

// ---------------------------------------------
// Fullscreen Modal

type FullscreenModalContentProps = {
  padding?: NessieSpaceSizes;
};

export const FullscreenModalContent = ({
  children,
  padding = "xl",
}: React.PropsWithChildren<FullscreenModalContentProps>) => (
  <div
    sx={{
      padding,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
      backgroundColor: "dt_white",
      position: "relative",
    }}
  >
    {children}
  </div>
);
