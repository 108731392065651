/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import Input from "./InputText";
import { Button } from "../../nessie";
import T from "../misc/T";
import { RAW_cssValue } from "../../nessie/stylingLib";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

/**
 * Wrapper around our common input in order to add a button on the side.
 */

export type InputWithButtonProps = {
  inputProps: React.ComponentPropsWithoutRef<typeof Input>;
  buttonProps: React.ComponentPropsWithoutRef<typeof Button> & {
    text?: string;
    tmpText?: string;
    str?: string;
    fallback?: string;
    sx?: ThemeUIStyleObject;
  };
};

const InputWithButton = React.forwardRef<HTMLInputElement, InputWithButtonProps>(({ inputProps, buttonProps }, ref) => {
  return (
    <form sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
      <div sx={{ flexGrow: 1 }}>
        <Input ref={ref} {...inputProps} style={{ ...styles.input, ...inputProps.style }} />
      </div>

      <Button type="submit" size="s" {...buttonProps} sx={{ ...styles.button, ...buttonProps.sx }}>
        <T
          text={buttonProps.text}
          str={buttonProps.str}
          tmpText={buttonProps.tmpText}
          fallback={buttonProps.fallback}
        />
      </Button>
    </form>
  );
});

InputWithButton.propTypes = {
  inputProps: PropTypes.object.isRequired,
  buttonProps: PropTypes.object.isRequired,
};

const styles = {
  input: {
    borderTopRightRadius: RAW_cssValue(0),
    borderBottomRightRadius: RAW_cssValue(0),
  },
  button: {
    position: "relative" as const,
    left: "-3px", // this covers the input's right border so it doesn't "expand" the button
    margin: 0,
    borderRadius: RAW_cssValue("5px"),
    borderTopLeftRadius: RAW_cssValue(0),
    borderBottomLeftRadius: RAW_cssValue(0),
    height: "36px",
  },
};

export default InputWithButton;
