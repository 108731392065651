/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import { shadows } from "../commonStyles";
import { theme } from "../../nessie";

export type ColoredCircleProps = {
  large?: boolean;
  xSmall?: boolean;
  small?: boolean;
  xLarge?: boolean;
  messagingUnread?: boolean;
  mergeSuggestion?: boolean;

  // Can specify either "type" or one of the flags
  type?: "success" | "danger" | "warning" | "primary" | "secondary";
  danger?: boolean;
  warning?: boolean;
  primary?: boolean;
  secondary?: boolean;
  ["data-test-name"]?: string;
  ["aria-label"]?: string;
  className?: string;
  children?: React.ReactNode;
};

const ColoredCircle = ({
  xSmall,
  small,
  large,
  xLarge,
  messagingUnread,
  mergeSuggestion,
  children,
  className,
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}: ColoredCircleProps): JSX.Element => {
  let sizeStyle = styles.circleMedium;
  if (xSmall) {
    sizeStyle = styles.circleXSmall;
  }
  if (small) {
    sizeStyle = styles.circleSmall;
  } else if (large) {
    sizeStyle = styles.circleLarge;
  } else if (xLarge) {
    sizeStyle = styles.circleXLarge;
  }
  const dynamicStyles = { backgroundColor: _getColor(props) };

  let messagingStyle = {};
  if (messagingUnread) {
    messagingStyle = styles.circleUnread;
  }
  if (mergeSuggestion) {
    messagingStyle = styles.circleSuggestion;
  }

  return (
    <div
      data-test-name={dataTestName}
      style={{ ...styles.circle, ...sizeStyle, ...messagingStyle, ...dynamicStyles }}
      aria-label={ariaLabel}
      className={className}
    >
      {children}
    </div>
  );
};
ColoredCircle.propTypes = {
  xSmall: PropTypes.any,
  large: PropTypes.any,
  small: PropTypes.any,
  xLarge: PropTypes.any,

  // Can specify either "type" or one of the flags
  type: PropTypes.string,
  danger: PropTypes.any, // red
  warning: PropTypes.any, // orange
  primary: PropTypes.any, // blue
  secondary: PropTypes.any, // gray
};

const _getColor = ({
  danger,
  warning,
  primary,
  secondary,
  type,
}: {
  type?: "success" | "danger" | "warning" | "primary" | "secondary";
  danger?: boolean;
  warning?: boolean;
  primary?: boolean;
  secondary?: boolean;
}) => {
  if (danger || type === "danger") return theme.colors.watermelon60;
  if (warning || type === "warning") return theme.colors.mango60;
  if (primary || type === "primary") return theme.colors.aqua60;
  if (secondary || type === "secondary") return theme.colors.taro40;

  // default to green
  return theme.colors.kiwi60;
};

const styles = {
  circle: {
    display: "inline-block",
    border: "3px solid white",
    borderRadius: "20rem",
    color: "white",
    textAlign: "center" as const,
    padding: "0.5rem",
    boxShadow: shadows.medium,
    fontWeight: 600,
  },
  circleXSmall: {
    border: "2px solid white",
    minWidth: "1.1rem",
    minHeight: "1.1rem",
    borderRadius: "50%",
    fontSize: "0.8rem",
    padding: "0rem",
  },
  circleSmall: {
    border: "2px solid white",
    minWidth: "1.9rem",
    borderRadius: "50%",
    fontSize: "0.8rem",
    padding: "0.2rem",
  },
  circleMedium: {
    minWidth: "3.2rem",
    fontSize: "1.4rem",
  },
  circleLarge: {
    fontWeight: 600,
    minWidth: "4.0rem",
    fontSize: "2rem",
  },
  circleXLarge: {
    border: "6px solid white",
    minWidth: "9.0rem",
    fontSize: "6.0rem",
  },
  circleUnread: {
    minWidth: "1.2rem",
    minHeight: "1.2rem",
    border: "none",
    boxShadow: "none",
  },
  circleSuggestion: {
    minWidth: "1.7rem",
    minHeight: "1.5rem",
    border: "none",
    boxShadow: "none",
    paddingTop: "0.4rem",
    paddingLeft: "0.25rem",
    marginBottom: "0.4rem",
  },
};

export default ColoredCircle;
