export const borderRadius = { borderRadius: 5 };
export const fullWidth = { width: "100%" };
export const unselectable = {
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
  MsUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
} as React.CSSProperties;
export const backgroundImage = (url: string): React.CSSProperties => ({
  backgroundImage: `url("${url}")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
});
