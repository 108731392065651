/** @jsxImportSource theme-ui */
import { CheckboxEmptyIcon, CheckboxSelectedIcon } from "../icons";
import { StylingLibCSSObject } from "../stylingLib";

type CheckboxInputProps = {
  id: string;
  labelId: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  className?: string;
  size?: "s" | "m" | "l" | "xl"; // shouldn't be larger or smaller than this
};

export const CheckboxInput = ({ id, labelId, onChange, disabled, checked, className, size }: CheckboxInputProps) => {
  const handleChange = () => {
    onChange();
  };
  const iconColor = disabled ? "dt_taro30" : "dt_taro50";
  return (
    <div
      // adding classname so devs can style input wrapper with sx
      className={className}
    >
      <div sx={inputRadioContainerStyles}>
        <input
          id={id}
          type="checkbox"
          disabled={disabled}
          onChange={handleChange}
          checked={checked}
          sx={invisibleInputStyles}
          aria-labelledby={labelId}
        />
        <div>
          {checked ? (
            <CheckboxSelectedIcon aria-hidden={true} size={size} color={iconColor} />
          ) : (
            <CheckboxEmptyIcon aria-hidden={true} size={size} color={iconColor} />
          )}
        </div>
      </div>
    </div>
  );
};

const inputRadioContainerStyles: StylingLibCSSObject = {
  position: "relative",
  flex: "none",
  lineHeight: 0,
  "[type='checkbox']:focus + div": {
    outline: "2px solid",
    outlineColor: "dt_blueberry60",
    outlineOffset: "1px",
  },
  "[type='checkbox']:not(:focus-visible) + div": {
    outline: "none", // allowing only keyboard focus
  },
};

const invisibleInputStyles: StylingLibCSSObject = {
  position: "absolute",
  opacity: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
  cursor: "pointer",
  ":disabled": {
    cursor: "not-allowed",
  },
};
