/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type RewardIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let RewardIcon: NessieFunctionComponent<RewardIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 14.835l-1.938 1.074a1 1 0 01-1.418-.514l-.812-2.104-2.184-.45a1 1 0 01-.748-1.293l.715-2.168-1.402-1.79a1 1 0 01.259-1.466l1.913-1.19.056-2.275a1 1 0 011.151-.964l2.19.336L11.246.345a1 1 0 011.51 0l1.463 1.686 2.19-.336a1 1 0 011.152.964l.056 2.274 1.913 1.191a1 1 0 01.26 1.466l-1.403 1.79.715 2.168a1 1 0 01-.748 1.293l-2.184.45-.812 2.104a1 1 0 01-1.418.514l-1.938-1.074zm0-3.51c1.678 0 3.037-1.384 3.037-3.091 0-1.708-1.36-3.092-3.037-3.092-1.677 0-3.037 1.384-3.037 3.092 0 1.707 1.36 3.092 3.038 3.092zm-1.21 5.94a2.508 2.508 0 01-3.433-1.03 2.606 2.606 0 01-.132-.29l-.503-1.305-.786 8.263a1 1 0 001.453.984l4.154-2.137a1 1 0 01.915 0l4.159 2.137a1 1 0 001.452-.984l-.789-8.264-.504 1.306c-.51 1.324-1.98 1.976-3.28 1.456a2.507 2.507 0 01-.285-.135l-1.21-.67-1.21.67z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
RewardIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  RewardIcon = nessiePropCheckerWrapper(RewardIcon);
}

export default RewardIcon;
