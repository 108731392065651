/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import { standardBorder } from "../../styles";

type ListProps = {
  itemBorder?: React.CSSProperties["borderTop"];
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const List = ({ itemBorder = standardBorder.border, style, children, ...passedProps }: ListProps): JSX.Element => {
  const listStyle = { ...wrapperStyle, ...style };
  return (
    <ul {...passedProps} style={listStyle}>
      {React.Children.map(children, (child, i) => {
        if (!child) {
          return null;
        } else if (i === 0) {
          return <li>{child}</li>;
        } else {
          return <li style={{ borderTop: itemBorder }}>{child}</li>;
        }
      })}
    </ul>
  );
};
List.propTypes = {
  itemBorder: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default List;

const wrapperStyle = {
  ...standardBorder,
  borderRadius: 5,
  listStyleType: "none",
  padding: 0,
  margin: 0,
};
