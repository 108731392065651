/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PaintBrushIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PaintBrushIcon: NessieFunctionComponent<PaintBrushIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.961 10.699l7.779-9.243a1.278 1.278 0 011.805-.153c.541.458.61 1.268.152 1.81l-7.777 9.243a2.087 2.087 0 01-.446 1.774l-.691.95c-.521-1.36-1.5-2.225-2.501-2.712a6.39 6.39 0 00-.483-.215l.494-.716a2.072 2.072 0 011.668-.738zM9.402 13.56c.26.085.525.188.784.319.965.495 1.848 1.276 2.209 2.722l-.046.053c-.207 4.629-5.252 6.446-8.086 6.342-2.862-.106-3.524-2.441-3.18-2.47.072-.007.175-.002.299.003.895.039 2.94.127 3.306-3.307.293-2.763 3.821-3.558 4.714-3.662z"
          />
          <mask maskUnits="userSpaceOnUse" x={1} y={1} width={22} height={22}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.961 10.699l7.779-9.243a1.278 1.278 0 011.805-.153c.541.458.61 1.268.152 1.81l-7.777 9.243a2.087 2.087 0 01-.446 1.774l-.691.95c-.521-1.36-1.5-2.225-2.501-2.712a6.39 6.39 0 00-.483-.215l.494-.716a2.072 2.072 0 011.668-.738zM9.402 13.56c.26.085.525.188.784.319.965.495 1.848 1.276 2.209 2.722l-.046.053c-.207 4.629-5.252 6.446-8.086 6.342-2.862-.106-3.524-2.441-3.18-2.47.072-.007.175-.002.299.003.895.039 2.94.127 3.306-3.307.293-2.763 3.821-3.558 4.714-3.662z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PaintBrushIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PaintBrushIcon = nessiePropCheckerWrapper(PaintBrushIcon);
}

export default PaintBrushIcon;
