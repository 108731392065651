/** @jsxImportSource theme-ui */
import PropTypes from "prop-types";
import Styles from "../commonStyles";
import { theme } from "../../nessie";

export type BadgeProps = {
  count: number;
  max?: number;
  reverseColor?: boolean;
  showZero?: boolean;
};

const Badge = ({ count, max = 99, reverseColor = false, showZero = false }: BadgeProps): JSX.Element => {
  if (count == null || (count === 0 && !showZero)) return <noscript />;
  const countToDisplay = count > max ? `${max}+` : count;

  let color;
  if (reverseColor && count === 0) {
    color = styles.reverseColorZero;
  } else if (reverseColor) {
    color = styles.reverseColor;
  } else {
    color = styles.normalColor;
  }

  return (
    <span style={{ ...styles.container, ...color }} data-test-name="badge">
      {countToDisplay}
    </span>
  );
};

export default Badge;

Badge.propTypes = {
  count: PropTypes.number.isRequired,
  max: PropTypes.number,
  reverseColor: PropTypes.bool,
  showZero: PropTypes.bool,
};

const styles = {
  container: {
    display: "inline-block",
    borderRadius: "5px",
    padding: "1px 3px",
    fontSize: "14px",
    minWidth: "1.5rem",
    textAlign: "center" as const,
    fontWeight: 600,
  },
  normalColor: {
    backgroundColor: theme.colors.watermelon60,
    color: "#FFFFFF",
  },
  reverseColor: {
    backgroundColor: "#FFFFFF",
    color: Styles.palette.dojoPurple,
    borderColor: Styles.palette.dojoPurple,
    borderWidth: "1px",
    borderStyle: "solid",
  },
  reverseColorZero: {
    backgroundColor: theme.colors.watermelon60,
    color: "#FFFFFF",
    borderColor: Styles.palette.dojoPurple,
    borderWidth: "1px",
    borderStyle: "solid",
  },
};
