/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type BookIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let BookIcon: NessieFunctionComponent<BookIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.349 6.053c-.162-.04-2.874-.364-6.92 1.133 2.954-2.064 6.272-2.225 6.313-2.225.242 0 .404-.203.404-.446a.45.45 0 00-.445-.445c-.202 0-4.25.203-7.568 2.995 2.145-3.238 6.718-4.168 6.759-4.168a.457.457 0 00.364-.527c-.04-.242-.283-.404-.486-.364-.242.04-5.665 1.133-7.77 5.302-2.104-4.169-7.527-5.261-7.77-5.302-.243-.04-.445.122-.486.364a.457.457 0 00.364.527c.04 0 4.614.93 6.759 4.168C7.589 4.273 3.5 4.07 3.299 4.07a.45.45 0 00-.445.445.48.48 0 00.404.486c.04 0 3.36.162 6.314 2.226-2.712-1.012-4.816-1.174-5.95-1.174-.606 0-.93.04-.97.04-.405.041-.689.405-.648.85l.971 11.251c.04.405.364.688.729.729 4.816.162 7.81 2.347 7.81 2.388.122.12.284.161.486.161.121 0 .243-.04.364-.08.04-.041.081-.041.122-.081.04-.041 2.994-2.186 7.81-2.388a.725.725 0 00.729-.729l.971-11.25c.04-.486-.243-.85-.647-.89zM3.542 7.551c1.335 0 4.087.283 7.608 2.023v9.632a17.725 17.725 0 00-6.758-1.902l-.85-9.753zm9.187 11.655V9.574c3.56-1.78 6.353-2.023 7.648-2.023l-.89 9.753c-3.157.243-5.504 1.214-6.758 1.902z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.349 6.053c-.162-.04-2.874-.364-6.92 1.133 2.954-2.064 6.272-2.225 6.313-2.225.242 0 .404-.203.404-.446a.45.45 0 00-.445-.445c-.202 0-4.25.203-7.568 2.995 2.145-3.238 6.718-4.168 6.759-4.168a.457.457 0 00.364-.527c-.04-.242-.283-.404-.486-.364-.242.04-5.665 1.133-7.77 5.302-2.104-4.169-7.527-5.261-7.77-5.302-.243-.04-.445.122-.486.364a.457.457 0 00.364.527c.04 0 4.614.93 6.759 4.168C7.589 4.273 3.5 4.07 3.299 4.07a.45.45 0 00-.445.445.48.48 0 00.404.486c.04 0 3.36.162 6.314 2.226-2.712-1.012-4.816-1.174-5.95-1.174-.606 0-.93.04-.97.04-.405.041-.689.405-.648.85l.971 11.251c.04.405.364.688.729.729 4.816.162 7.81 2.347 7.81 2.388.122.12.284.161.486.161.121 0 .243-.04.364-.08.04-.041.081-.041.122-.081.04-.041 2.994-2.186 7.81-2.388a.725.725 0 00.729-.729l.971-11.25c.04-.486-.243-.85-.647-.89zM3.542 7.551c1.335 0 4.087.283 7.608 2.023v9.632a17.725 17.725 0 00-6.758-1.902l-.85-9.753zm9.187 11.655V9.574c3.56-1.78 6.353-2.023 7.648-2.023l-.89 9.753c-3.157.243-5.504 1.214-6.758 1.902z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
BookIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  BookIcon = nessiePropCheckerWrapper(BookIcon);
}

export default BookIcon;
