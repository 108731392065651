// TODO: ADD SOME DOCUMENTATION
//
import moment from "moment";

type CreateLocalizedMoment = (date?: moment.MomentInput, locale?: string) => moment.Moment;

let createLocalizedMoment: CreateLocalizedMoment = function () {
  throw new Error(
    "You must initialize the component library with a `createLocalizedMoment` function to use time utils",
  );
};

export function setCreateLocalizedMomentFunction(fn: CreateLocalizedMoment): void {
  createLocalizedMoment = fn;
}

export function getLocalizedMomentFunction(): CreateLocalizedMoment {
  return createLocalizedMoment;
}
