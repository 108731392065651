/** @jsxImportSource theme-ui */
import * as React from "react";
import ReactSlider from "react-slider";
import { palette } from "../../commonStyles";

/**
 * Slider component (wrapper around react-slider)
 */
const Slider = (props: React.ComponentPropsWithoutRef<typeof ReactSlider>) => {
  return (
    <div style={styles.bg}>
      <ReactSlider sx={{ position: "relative", top: "-6px" }} {...props}>
        <div style={styles.handle} />
      </ReactSlider>
    </div>
  );
};

Slider.propTypes = ReactSlider.propTypes;

export default Slider;

const styles = {
  bg: {
    background: palette.gray10,
    height: "0.6rem",
    position: "relative" as const,
    cursor: "pointer",
    border: `1px inset ${palette.gray10}`,
    borderRadius: "3px",
  },
  handle: {
    background: palette.dojoBlue,
    height: "1.9rem",
    width: "1.9rem",
    top: "-0.3rem",
    marginLeft: "-.8rem",
    position: "absolute" as const,
    display: "block",
    cursor: "move",
    borderRadius: "14px",
    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
  },
  legend: {
    border: "0 none",
    marginTop: "4rem",
    display: "block",
    width: "100%",
    marginBottom: "2rem",
    fontSize: "2.1rem",
    color: palette.gray80,
    borderBottom: "1px solid #e5e5e5",
  },
};
