/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import { isServer } from "../../../utils/env";
import type DojoGauge from "./Base";

let Base: typeof DojoGauge | React.FunctionComponent;

// gaugejs looks for global `window`, which will break in server environments
//@ts-expect-error SERVER_CODE_WEBPACKED not defined
if (typeof window === "undefined" || (isServer && global.SERVER_CODE_WEBPACKED)) {
  // eslint-disable-next-line react/display-name
  Base = () => <div />;
} else {
  Base = require("./Base").default;
}

const GAUGE_SIZE_REGULAR = 120;
const GAUGE_SIZE_MEDIUM = 60;
const GAUGE_SIZE_SMALL = 44;
const GAUGE_SIZE_MINI = 20;

const sizes = {
  mini: {
    width: GAUGE_SIZE_MINI,
    height: GAUGE_SIZE_MINI,
    fontSize: undefined,
    top: (top = 0) => top,
    bottom: (bottom = 0) => bottom,
  },
  medium: {
    width: GAUGE_SIZE_MEDIUM,
    height: GAUGE_SIZE_MEDIUM,
    fontSize: "1.6rem",
    top: (top = 21) => top,
    bottom: (bottom = 0) => bottom,
  },
  small: {
    width: GAUGE_SIZE_SMALL,
    height: GAUGE_SIZE_SMALL,
    fontSize: "1.6rem",
    top: (top = 13) => top,
    bottom: (bottom = 0) => bottom,
  },
  regular: {
    width: GAUGE_SIZE_REGULAR,
    height: GAUGE_SIZE_REGULAR,
    fontSize: "2.4rem",
    top: (top = 46) => top,
    bottom: (bottom = 0) => bottom,
  },
};

export type GaugeProps = {
  value?: number;
  size?: "regular" | "medium" | "small" | "mini";
  color?: string;
  content?: React.ReactNode;
  hidePercent?: boolean;
  top?: number;
  bottom?: number;
};

const Gauge = ({
  value = 0,
  size = "regular",
  color,
  hidePercent = false,
  content = undefined,
  top,
  bottom,
}: GaugeProps): JSX.Element => {
  if (isNaN(value)) value = 0;
  if (value > 100) value = 100;
  if (value < 0) value = 0;
  value = Math.round(value);

  const { width, height } = sizes[size];
  const showValue = !hidePercent && size !== "mini";

  return (
    <div style={wrapperStyle(size, bottom)}>
      <div style={gaugeLabelStyle(size, top)}>
        <div style={percentLabelStyle(size, color)}>{showValue && `${value}%`}</div>
        {content}
      </div>

      <div style={gaugeStyle(width)}>
        <Base value={value} width={width} height={height} color={color} />
      </div>
    </div>
  );
};

Gauge.propTypes = {
  value: PropTypes.number,
  size: PropTypes.oneOf(["regular", "medium", "small", "mini"]),
  color: PropTypes.string,
  content: PropTypes.node,
  hidePercent: PropTypes.bool,
  top: PropTypes.number,
  bottom: PropTypes.number,
};

export default Gauge;

const wrapperStyle = (size: keyof typeof sizes, bottom: number | undefined) => ({
  position: "relative" as const,
  bottom: sizes[size].bottom(bottom),
});

const gaugeStyle = (width: number) => ({
  margin: "auto",
  width,
});

const gaugeLabelStyle = (size: keyof typeof sizes, top: number | undefined) => ({
  position: "absolute" as const,
  top: sizes[size].top(top),
  left: 0,
  right: 0,
  textAlign: "center" as const,
  zIndex: 1,
});

const percentLabelStyle = (size: keyof typeof sizes, color = "#93d453") => ({
  fontSize: sizes[size].fontSize,
  fontWeight: 600,
  color,
});
