/** @jsxImportSource theme-ui */
import translate from "../../utils/translate";
import { TranslatableProps, translatablePropTypes } from "../../prop_shapes/translatable";

/**
 * Translation component. Refer to our translation function to check how it can
 * be configured.
 */

export type TProps = Omit<JSX.IntrinsicElements["span"], "children" | "dangerouslySetInnerHTML"> & TranslatableProps;

/**
 * ### [How does translation work?](https://www.notion.so/classdojo/How-Does-Translation-Work-b9ddf058dd6f43ebb5c4c1c4044d3124#9c9e05b364364615acd1074d94e3af42)
 */
const T = ({
  text,
  str,
  htmlStr,
  fallback,
  componentFallback,
  subs,
  lowercase,
  tmpText,
  ...spanProps
}: TProps): JSX.Element => {
  const translation = translate({
    text,
    str,
    htmlStr,
    fallback,
    componentFallback,
    subs,
    lowercase,
    tmpText,
  });

  if (htmlStr) {
    return <span {...spanProps} dangerouslySetInnerHTML={{ __html: translation }} />;
  } else {
    return <span {...spanProps}>{translation}</span>;
  }
};

T.propTypes = translatablePropTypes;

export default T;
