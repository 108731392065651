/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type QuoteIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let QuoteIcon: NessieFunctionComponent<QuoteIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.226 11.611h.356c.967 0 1.837.37 2.61 1.108.771.738 1.158 1.658 1.158 2.761 0 1.087-.387 1.982-1.159 2.686s-1.727 1.056-2.864 1.056c-1.238 0-2.27-.496-3.092-1.489C2.412 16.741 2 15.328 2 13.495c0-1.68.36-3.254 1.082-4.722.721-1.468 1.604-2.63 2.647-3.487C6.773 4.429 7.702 4 8.517 4c.543 0 .967.161 1.272.484.306.322.459.738.459 1.247 0 .747-.331 1.298-.993 1.655-.9.492-1.612 1.069-2.138 1.73-.526.662-.823 1.494-.891 2.495zm11.251 0h.331c.95 0 1.812.37 2.584 1.108.772.738 1.158 1.658 1.158 2.761 0 1.07-.386 1.97-1.158 2.699-.772.73-1.727 1.094-2.864 1.094-1.256 0-2.291-.509-3.106-1.527-.814-1.018-1.222-2.435-1.222-4.251 0-1.307.213-2.537.637-3.691.424-1.154.972-2.16 1.642-3.017.67-.857 1.391-1.535 2.163-2.036.773-.5 1.464-.751 2.075-.751.56 0 .989.157 1.286.47.297.315.445.735.445 1.261 0 .764-.33 1.315-.993 1.655-.933.526-1.637 1.107-2.112 1.743-.476.637-.764 1.464-.866 2.482z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={4} width={20} height={16}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.226 11.611h.356c.967 0 1.837.37 2.61 1.108.771.738 1.158 1.658 1.158 2.761 0 1.087-.387 1.982-1.159 2.686s-1.727 1.056-2.864 1.056c-1.238 0-2.27-.496-3.092-1.489C2.412 16.741 2 15.328 2 13.495c0-1.68.36-3.254 1.082-4.722.721-1.468 1.604-2.63 2.647-3.487C6.773 4.429 7.702 4 8.517 4c.543 0 .967.161 1.272.484.306.322.459.738.459 1.247 0 .747-.331 1.298-.993 1.655-.9.492-1.612 1.069-2.138 1.73-.526.662-.823 1.494-.891 2.495zm11.251 0h.331c.95 0 1.812.37 2.584 1.108.772.738 1.158 1.658 1.158 2.761 0 1.07-.386 1.97-1.158 2.699-.772.73-1.727 1.094-2.864 1.094-1.256 0-2.291-.509-3.106-1.527-.814-1.018-1.222-2.435-1.222-4.251 0-1.307.213-2.537.637-3.691.424-1.154.972-2.16 1.642-3.017.67-.857 1.391-1.535 2.163-2.036.773-.5 1.464-.751 2.075-.751.56 0 .989.157 1.286.47.297.315.445.735.445 1.261 0 .764-.33 1.315-.993 1.655-.933.526-1.637 1.107-2.112 1.743-.476.637-.764 1.464-.866 2.482z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
QuoteIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  QuoteIcon = nessiePropCheckerWrapper(QuoteIcon);
}

export default QuoteIcon;
