/** @jsxImportSource theme-ui */
import * as React from "react";
import { Action } from "./Text";
import { RAW_cssValue } from "../../nessie/stylingLib";

type ButtonProps = {
  onClick?: React.MouseEventHandler;
  onBlur?: React.FocusEventHandler;
  disabled?: boolean;
  width?: number | string;
  kind?: "primary" | "secondary" | "tertiary" | "destructive" | "like" | "plus";
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  size?: "s" | "m" | "l";
  "data-test-name"?: string;
  "aria-label"?: string;
  "aria-describedby"?: string;
  type?: "submit" | "button" | "reset";
  href?: string;
  target?: string;
  className?: string;
  id?: string;
  tabIndex?: number;
  "aria-controls"?: string;
  "aria-expanded"?: boolean;
};

// we want buttons to have the same height whether they have icons or no
const getPadding = (size: string, hasIcon: boolean) => {
  if (size === "l") return hasIcon ? RAW_cssValue("18px 24px") : RAW_cssValue("19px 24px");
  if (size === "m") return hasIcon ? RAW_cssValue("12px 18px") : RAW_cssValue("13px 18px");
  return RAW_cssValue("12px 18px");
};

export const Button = ({
  onClick,
  onBlur,
  disabled,
  width,
  kind = "primary",
  style,
  icon,
  children,
  size = "l",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ["aria-describedby"]: ariaDescribedby,
  ["aria-controls"]: ariaControls,
  ["aria-expanded"]: ariaExpanded,
  id,
  type = "button",
  href,
  className,
  target,
  tabIndex,
}: React.PropsWithChildren<ButtonProps>): JSX.Element => {
  const Element = href ? "a" : "button";
  return (
    <Element
      sx={{
        width,
        border: "none",
        position: "relative",
        backgroundColor: "transparent",
        ...buttonStyle,
        ...getButtonColorStyle(kind, disabled),
        padding: getPadding(size, !!icon),
      }}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      onBlur={onBlur}
      className={className}
      data-test-name={dataTestName}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      type={href ? undefined : type}
      target={target}
      href={href}
      tabIndex={tabIndex}
      style={style}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      id={id}
    >
      {icon && React.isValidElement(icon) ? (
        <div sx={{ marginRight: children ? "dt_xs" : "0", position: "relative" }}>
          {/* default icon size="s" if button size="s" */}
          {/* @ts-expect-error invalid property size */}
          {size === "s" && !icon.props.size ? React.cloneElement(icon, { size: "s" }) : icon}
        </div>
      ) : null}
      <Action compact={size === "s"}>{children}</Action>
    </Element>
  );
};

const getButtonColorStyle = (kind: NonNullable<ButtonProps["kind"]>, disabled: ButtonProps["disabled"]) => {
  return {
    ...((kind === "primary" && primaryStyle) || {}),
    ...((kind === "secondary" && secondaryStyle) || {}),
    ...((kind === "tertiary" && tertiaryStyle) || {}),
    ...((kind === "destructive" && destructiveStyle) || {}),
    ...((kind === "plus" && plusStyle) || {}),
    ...((kind === "like" && likeActionStyle) || {}),
    ...((disabled && disabledStyle) || {}),
    ...((disabled && kind === "tertiary" && disabledTertiaryStyle) || {}),
  };
};

const buttonStyle = {
  borderRadius: RAW_cssValue("40px"),
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  span: {
    color: "dt_white",
    whiteSpace: "nowrap" as const,
  },
  svg: {
    verticalAlign: "middle",
  },
};

const primaryStyle = {
  backgroundColor: "contentAccent",
  ":hover, :focus": { backgroundColor: "contentAccentHover" },
  ":active": { backgroundColor: "contentAccentActive" },
};

const secondaryStyle = {
  backgroundColor: "dt_taro20",
  span: { ...buttonStyle.span, color: "dt_taro90" },
  "svg path": { fill: "dt_taro90" },
  ":hover, :focus": {
    backgroundColor: "dt_taro30",
    span: { ...buttonStyle.span, color: "dt_taro90" },
    "svg path": { fill: "dt_taro90" },
  },
  ":active": {
    backgroundColor: "dt_taro20",
    span: { ...buttonStyle.span, color: "dt_taro50" },
    "svg path": { fill: "dt_taro50" },
  },
};

const tertiaryStyle = {
  backgroundColor: "dt_white",
  span: { ...buttonStyle.span, color: "dt_aqua50" },
  "svg path": { fill: "dt_aqua50" },
  ":hover, :focus": {
    backgroundColor: "dt_aqua10",
    span: { ...buttonStyle.span, color: "dt_aqua60" },
    "svg path": { fill: "dt_aqua60" },
  },
  ":active": {
    backgroundColor: "dt_aqua10",
    span: { ...buttonStyle.span, color: "dt_aqua40" },
    "svg path": { fill: "dt_aqua40" },
  },
};

const plusStyle = {
  backgroundColor: "dt_grape50",
  ":hover, :focus": { backgroundColor: "dt_grape50" },
  ":active": { backgroundColor: "dt_grape40" },
};

const destructiveStyle = {
  backgroundColor: "dt_watermelon50",
  span: { ...buttonStyle.span, color: "dt_white" },
  ":hover, :focus": { backgroundColor: "dt_watermelon60" },
  ":active": { backgroundColor: "dt_watermelon40" },
};

const likeActionStyle = {
  backgroundColor: "dt_watermelon20",
  span: { ...buttonStyle.span, color: "dt_watermelon50" },
  "svg path": { fill: "dt_watermelon50" },
};

const disabledStyle = {
  cursor: "not-allowed",
  backgroundColor: "dt_taro10",
  span: { ...buttonStyle.span, color: "dt_taro30" },
  "svg path": { fill: "dt_taro30" },
  ":hover, :focus": {
    backgroundColor: "dt_taro10",
    span: { ...buttonStyle.span, color: "dt_taro30" },
    "svg path": { fill: "dt_taro30" },
  },
  ":active": {
    backgroundColor: "dt_taro10",
    span: { ...buttonStyle.span, color: "dt_taro30" },
    "svg path": { fill: "dt_taro30" },
  },
};

const disabledTertiaryStyle = {
  backgroundColor: "dt_white",
  span: { ...buttonStyle.span, color: "dt_taro30" },
  "svg path": { fill: "dt_taro30" },
  ":hover": {
    backgroundColor: "dt_white",
    span: { ...buttonStyle.span, color: "dt_taro30" },
    "svg path": { fill: "dt_taro30" },
  },
  ":active, :focus": {
    backgroundColor: "dt_white",
    span: { ...buttonStyle.span, color: "dt_taro30" },
    "svg path": { fill: "dt_taro30" },
  },
};
