function getDigit(d: number | string): number {
  if (typeof d === "number") {
    return d;
  }
  const captured = /(.*)rem/.exec(d);
  if (captured) {
    return Number(captured[1]);
  } else {
    throw new Error(`${JSON.stringify(d)} has no rems!`);
  }
}

export function addRem(a: number | string, b: number | string, ...rest: Array<number | string>): string {
  const sum = `${getDigit(a) + getDigit(b)}rem`;
  if (rest.length > 0) {
    const [_b, ..._rest] = rest;
    return addRem(sum, _b, ..._rest);
  }
  return sum;
}

export function subtractRem(a: number | string, b: number | string, ...rest: Array<number | string>): string {
  const diff = `${getDigit(a) - getDigit(b)}rem`;
  if (rest.length > 0) {
    const [_b, ..._rest] = rest;
    return subtractRem(diff, _b, ..._rest);
  }
  return diff;
}

export function multiplyRem(a: number | string, b: number | string, ...rest: Array<number | string>): string {
  const product = `${getDigit(a) * getDigit(b)}rem`;
  if (rest.length > 0) {
    const [_b, ..._rest] = rest;
    return multiplyRem(product, _b, ..._rest);
  }
  return product;
}

export function divideRem(a: number | string, b: number | string, ...rest: Array<number | string>): string {
  const ratio = `${getDigit(a) / getDigit(b)}rem`;
  if (rest.length > 0) {
    const [_b, ..._rest] = rest;
    return divideRem(ratio, _b, ..._rest);
  }
  return ratio;
}
