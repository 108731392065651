/** @jsxImportSource theme-ui */
import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import translate from "../../utils/translate";
import { Button } from "../../nessie";

export type ScheduledContentListProps = {
  headerText: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const ScheduledContentList = ({ headerText, children, style }: ScheduledContentListProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const toggleText = translate(expanded ? "dojo.common:actions.hide" : "dojo.common:actions.expand");

  const _toggle = () => setExpanded((value) => !value);

  return (
    <div sx={{ backgroundColor: "dt_taro10" }} style={style}>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "dt_m",
          marginRight: "dt_m",
          paddingTop: "dt_s",
          paddingBottom: "dt_s",
        }}
      >
        <span>{headerText}</span>
        <Button size="s" kind="tertiary" onClick={_toggle}>
          {toggleText}
        </Button>
      </div>
      {expanded && children}
    </div>
  );
};

ScheduledContentList.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default ScheduledContentList;
