/** @jsxImportSource theme-ui */
import { BaseDonut } from "./gauge.min";

export type DojoGaugeOptions = {
  angle?: number; // The span of the gauge arc
  lineWidth?: number; // The line thickness
  radiusScale?: number; // Relative radius
  pointer?: {
    length?: number; // // Relative to gauge radius
    strokeWidth?: number; // The thickness
    color?: string; // Fill color
  };
  limitMax?: boolean; // If false, max value increases automatically if value > maxValue
  limitMin?: boolean; // If true, the min value of the gauge will be fixed
  colorStart?: string; // Colors
  colorStop?: string; // just experiment with them
  strokeColor?: string; // to see which ones work best for you
  generateGradient?: boolean;
  highDpiSupport?: boolean; // High resolution support
  percentColors?: [number, string][];
  staticLabels?: {
    font: string; // Specifies font
    labels: number[]; // Print labels at these values
    color: string; // Optional: Label text color
    fractionDigits?: number; // Numerical precision. 0=round off.
  };
}; //source: https://bernii.github.io/gauge.js/
export type GaugeInterface = {
  set: (a: number) => void;
  setOptions: (options: DojoGaugeOptions) => void;
  animationSpeed: number;
  new (canvas: HTMLCanvasElement): GaugeInterface;
};

// building declaration files was getting an error because it needed to
// export BaseDonut type, but it is private inside gauge.min.js.
// we are just explicitly setting it to unknown here to fix it, but it
// probably resolves itself when we convert gauge to typescript
const donut = BaseDonut as unknown as GaugeInterface;

export default donut;
