/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type SchoolwideBadgeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let SchoolwideBadgeIcon: NessieFunctionComponent<SchoolwideBadgeIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 18 18"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM8.633 4.876c-.395-.61-1.34-.356-1.378.37l-.089 1.702a.75.75 0 01-.555.685l-1.647.441c-.701.188-.752 1.165-.074 1.425l1.592.61a.75.75 0 01.48.74l-.09 1.703c-.037.726.875 1.076 1.332.511l1.073-1.325a.75.75 0 01.852-.228l1.592.611c.678.26 1.293-.5.898-1.109l-.93-1.43a.75.75 0 01.047-.88l1.073-1.325c.457-.565-.075-1.385-.777-1.197l-1.647.442a.75.75 0 01-.823-.316l-.929-1.43z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SchoolwideBadgeIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  SchoolwideBadgeIcon = nessiePropCheckerWrapper(SchoolwideBadgeIcon);
}

export default SchoolwideBadgeIcon;
