/** @jsxImportSource theme-ui */
import * as React from "react";
import { Text } from "./Text";
import { NessieThemeColors } from "./theme";
import { RAW_cssValue } from "../stylingLib";

type PillProps = {
  size?: "s" | "m" | "l";
  color?: NessieThemeColors;
  backgroundColor?: NessieThemeColors;
};

export const Pill = ({
  size,
  children,
  color = "dt_white",
  backgroundColor = "dt_kiwi50",
}: React.PropsWithChildren<PillProps>) => {
  // Default size
  let width = "26px";
  let fontSize = "14px";
  let padding = "2px 7px";
  let lineHeight = "22px";

  if (size === "m") {
    width = "36px";
    fontSize = "18px";
    padding = "4px 10px";
    lineHeight = "26px";
  }

  if (size === "l") {
    width = "42px";
    fontSize = "22px";
    padding = "4px 10px";
    lineHeight = "32px";
  }

  return (
    <div
      sx={{
        display: "inline-block",
        minWidth: width,
        height: width,
        padding: RAW_cssValue(padding),
        borderRadius: RAW_cssValue(width),
        color,
        backgroundColor,
      }}
    >
      <Text nowrap textAlign="center" sx={{ fontSize, fontWeight: "bold", lineHeight }}>
        {children}
      </Text>
    </div>
  );
};
