import { TranslatableProps } from "../prop_shapes/translatable";

export type TranslateFunction = {
  (str: TranslatableProps["str"] | TranslatableProps): string;
  (str: TranslatableProps["str"], subs: TranslatableProps["subs"]): string;
  (str: TranslatableProps["str"], fallback: TranslatableProps["fallback"]): string;
};

export { TranslatableProps };

let translate: TranslateFunction = function () {
  throw new Error("You must initialize the component library with a `translate` function to translate text!");
};

/**
 *
 * Takes either multiple arguments or a translatable obj.
 * Translatable object accepts the following keys:
 *   {str, fallback, subs, tmpText, text},
 *
 */
const defaultTranslate = function (...args: Parameters<TranslateFunction>): string {
  return translate(...args);
} as TranslateFunction;

export default defaultTranslate;

export function setTranslateFunction(fn: TranslateFunction): void {
  translate = fn;
}
