/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";

import T, { TProps } from "./T";

import { palette } from "../../styles";

const Alert = ({ style, type, "data-test-name": dataTestName, children, ...rest }: AlertProps) => {
  let content;
  if (children) {
    content = children;
  } else {
    content = <T {...rest} />;
  }

  return (
    <div
      style={{ ...styles.common, ...styles[type], ...style }}
      role={type === "danger" ? "alert" : "generic"}
      data-test-name={dataTestName}
    >
      {content}
    </div>
  );
};

type AlertPropsBase = {
  type: "danger" | "warning" | "success" | "info";
  style?: React.CSSProperties;
  "data-test-name"?: string;
};

type AlertPropsWithTranslate = AlertPropsBase & {
  children?: undefined;
} & TProps;

type AlertPropsWithoutTranslate = AlertPropsBase & {
  children: React.ReactNode;
};

export type AlertProps = AlertPropsWithTranslate | AlertPropsWithoutTranslate;

Alert.propTypes = {
  type: PropTypes.oneOf(["danger", "warning", "success", "info"]),
};

export default Alert;

const styles = {
  common: {
    padding: "2rem",
    borderRadius: ".5rem",
    textShadow: "rgba(255, 255, 255, .5) 0 1px",
    fontWeight: "bold" as const,
    borderWidth: ".1rem",
    borderStyle: "solid",
  },
  // hex values below from http://tdekoning.github.io/rgba-converter/
  danger: {
    backgroundColor: "#fde6e5", // dojoRed + 0.15 transparency
    borderColor: "#fac5c3", // dojoRed + 0.35 transparency
    color: palette.dojoRed,
  },
  warning: {
    backgroundColor: "#fef5e0", // goldMentor + 0.15 transparency
    borderColor: "#fee9b6", // goldMentor + 0.35 transparency
    color: palette.goldMentor,
  },
  info: {
    backgroundColor: "#d8f2fc", // dojoBlue + 0.15 transparency
    borderColor: "#a5e2f9", // dojoBlue + 0.35 transparency
    color: palette.dojoBlue,
  },
  success: {
    backgroundColor: "#eef8e5", // greenPositive + 0.15 transparency
    borderColor: "#d9f0c2", // greenPositive + 0.35 transparency
    color: palette.greenPositive,
  },
};
