/** @jsxImportSource theme-ui */
import { useEffect, useState } from "react";
import * as React from "react";
import { debounce } from "lodash/fp";
import Modal from "./Base";
import { scrollableModalPropTypes } from "./ModalPropTypes";

const SCREEN_SIZE_THRESHOLD = 1000; // modal stops having extra top padding here.
const SMALL_SCREEN_MARGIN = 20;
const LARGE_SCREEN_MARGIN = 70;

type ScrollableModalProps = React.ComponentPropsWithoutRef<typeof Modal> & {
  fixedTop?: boolean;
  header?: React.ReactNode;
  headerHeight?: number;
  footer?: React.ReactNode;
  footerHeight?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
  showModalOverflow?: boolean;
  showModalContentOverflow?: boolean;
};

const ScrollableModal = ({
  fixedTop,
  header,
  headerHeight,
  footer,
  footerHeight,
  children,
  style,
  showModalOverflow,
  showModalContentOverflow,
  ...props
}: ScrollableModalProps): JSX.Element => {
  const [windowHeight, setWindowHeight] = useState(typeof window === "undefined" ? 600 : window.innerHeight);

  const onResize = React.useMemo(() => {
    return debounce(100, () => setWindowHeight(window.innerHeight));
  }, []);

  useEffect(() => {
    // To recalculate the component's height any time the window is resized.
    window && window.addEventListener("resize", onResize);

    return () => {
      window && window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  let margin;
  if (fixedTop && windowHeight > SCREEN_SIZE_THRESHOLD) {
    margin = LARGE_SCREEN_MARGIN;
  } else if (fixedTop) {
    margin = Math.max(SMALL_SCREEN_MARGIN, LARGE_SCREEN_MARGIN - (SCREEN_SIZE_THRESHOLD - windowHeight) / 4);
  } else {
    margin = SMALL_SCREEN_MARGIN;
  }

  const allowedHeight = windowHeight - ((header && headerHeight) || 0) - ((footer && footerHeight) || 0) - 2 * margin;

  const modalStyle = fixedTop
    ? { top: margin, transform: "none", WebkitTransform: "none", msTransform: "none", ...style }
    : { ...style };

  const contentStyle: React.CSSProperties = { maxHeight: allowedHeight };

  if (showModalOverflow) {
    modalStyle.overflow = "visible";
    contentStyle.overflowY = "auto";
  } else if (modalStyle.overflow !== "visible") {
    contentStyle.overflowY = "auto";
  }

  if (showModalContentOverflow) {
    contentStyle.overflowY = "visible";
  }

  return (
    <Modal {...props} style={modalStyle}>
      {header}
      <div style={contentStyle}>{children}</div>
      {footer}
    </Modal>
  );
};

ScrollableModal.propTypes = scrollableModalPropTypes;

export default ScrollableModal;
