/** @jsxImportSource theme-ui */
import extend from "lodash/extend";
import PropTypes from "prop-types";
import { buttonValidator } from "../../prop_shapes/buttons";
import { translatableValidator } from "../../prop_shapes/translatable";

export const modalPropTypes = {
  isOpen: PropTypes.any,
  onRequestHide: PropTypes.func,
  requestHideOnOverlayClick: PropTypes.any,
  style: PropTypes.object,
  overlayStyle: PropTypes.object,
};

export const scrollableModalPropTypes = extend(
  {
    fixedTop: PropTypes.any,
    header: PropTypes.node,
    headerHeight: PropTypes.number,
    headerRightSideElement: PropTypes.node,
    footer: PropTypes.node,
    footerHeight: PropTypes.number,
  },
  modalPropTypes,
);

export const standardModalPropTypes = extend(
  {
    title: PropTypes.any,
    width: PropTypes.string,
    small: PropTypes.bool, // fixes width to 51.2rem
    loading: PropTypes.any, // shows loading mojo instead of contents.
    primaryButton: buttonValidator,
    primaryButtonStyle: PropTypes.object,
    secondaryButton: buttonValidator,
    secondaryButtonStyle: PropTypes.object,
    tertiaryButton: buttonValidator,
    tertiaryButtonStyle: PropTypes.object,
    tooltip: translatableValidator,
    headerStyle: PropTypes.object,
    contentStyle: PropTypes.object,
  },
  scrollableModalPropTypes,
);
