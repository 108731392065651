/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type VideoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let VideoIcon: NessieFunctionComponent<VideoIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.767 4H2.405C2.162 4 2 4.203 2 4.486v15.028c0 .284.203.486.405.486h19.322c.243 0 .405-.203.405-.486V4.486c.04-.283-.162-.486-.365-.486zM19.58 5.296h1.498V6.43H19.58V5.296zm-3.2 0h1.498V6.43H16.38V5.296zm-3.281 0h1.498V6.43H13.1V5.296zm-3.403 0h1.499V6.43H9.696V5.296zm-3.362 0h1.458V6.43H6.294V5.296h.04zm-3.2 0h1.499V6.43H3.134V5.296zm1.499 13.53H3.134V17.69h1.499v1.134zm3.16 0h-1.5V17.69h1.5v1.134zm3.402 0H9.696V17.69h1.499v1.134zm.04-3.12h-8.1v-7.25h8.1v7.25zm3.322 3.12h-1.499V17.69h1.499v1.134zm3.321 0H16.38V17.69h1.498v1.134zm3.16 0h-1.499V17.69h1.499v1.134zm.04-3.12h-8.1v-7.25h8.1v7.25z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={4} width={21} height={16}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.767 4H2.405C2.162 4 2 4.203 2 4.486v15.028c0 .284.203.486.405.486h19.322c.243 0 .405-.203.405-.486V4.486c.04-.283-.162-.486-.365-.486zM19.58 5.296h1.498V6.43H19.58V5.296zm-3.2 0h1.498V6.43H16.38V5.296zm-3.281 0h1.498V6.43H13.1V5.296zm-3.403 0h1.499V6.43H9.696V5.296zm-3.362 0h1.458V6.43H6.294V5.296h.04zm-3.2 0h1.499V6.43H3.134V5.296zm1.499 13.53H3.134V17.69h1.499v1.134zm3.16 0h-1.5V17.69h1.5v1.134zm3.402 0H9.696V17.69h1.499v1.134zm.04-3.12h-8.1v-7.25h8.1v7.25zm3.322 3.12h-1.499V17.69h1.499v1.134zm3.321 0H16.38V17.69h1.498v1.134zm3.16 0h-1.499V17.69h1.499v1.134zm.04-3.12h-8.1v-7.25h8.1v7.25z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
VideoIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  VideoIcon = nessiePropCheckerWrapper(VideoIcon);
}

export default VideoIcon;
