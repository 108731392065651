/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type CheckmarkIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let CheckmarkIcon: NessieFunctionComponent<CheckmarkIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M8.421 19.122a1.996 1.996 0 001.565-.58L20.434 8.093a2 2 0 10-2.828-2.828L8.421 14.45 5.443 11.47A2 2 0 102.614 14.3l4.243 4.242a1.996 1.996 0 001.564.58z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckmarkIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  CheckmarkIcon = nessiePropCheckerWrapper(CheckmarkIcon);
}

export default CheckmarkIcon;
