/** @jsxImportSource theme-ui */
import * as React from "react";
import { UnstyledButton } from "../buttons";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { CloseIcon } from "../../nessie/icons";
import { HEADER_HEIGHT, HEADER_LATERAL_PADDING } from "./Styles";

/**
 * Simple component for rendering the close button in our modals, internally used
 * by our modals.
 */
type ModalCloseButtonProps = {
  close: React.MouseEventHandler;
  className?: string;
  "data-test-name"?: string;
  "aria-label"?: string;
};

const ModalCloseButton = ({
  close,
  className,
  "data-test-name": dataTestName = "closeModalButton",
  "aria-label": ariaLabel = "Close modal",
}: ModalCloseButtonProps): JSX.Element => {
  return (
    <UnstyledButton
      data-test-name={dataTestName}
      className={className}
      sx={modalHeaderClose}
      onClick={close}
      aria-label={ariaLabel}
    >
      <CloseIcon size="m" />
    </UnstyledButton>
  );
};

export default ModalCloseButton;

const modalHeaderClose: ThemeUIStyleObject = {
  color: "dt_taro90",
  position: "absolute",
  right: "0",
  top: "0",
  paddingX: RAW_cssValue(HEADER_LATERAL_PADDING),
  height: HEADER_HEIGHT,
  display: "flex",
  alignItems: "center",
  ":hover svg": {
    color: "dt_taro50",
  },
};
