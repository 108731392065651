/** @jsxImportSource theme-ui */
import { shadows } from "../commonStyles";

export const HEADER_HEIGHT = "6.8rem";
export const HEADER_LATERAL_PADDING = "2rem";

const styles = {
  modal: {
    width: "72rem",
    maxWidth: "90%",
    position: "relative" as const,

    // centering
    top: "50%",
    left: 0,
    right: 0,
    bottom: "auto",
    transform: "translate(0, -50%)",
    margin: "0 auto",
    padding: 0,

    border: "1px solid #ccc",
    background: "#fff",
    WebkitOverflowScrolling: "touch" as const,
    borderRadius: "4px",
    // outline: "none",
    boxShadow: shadows.strong,
  },

  modalHeader: {
    position: "relative" as const,
    height: HEADER_HEIGHT,
    borderBottom: "1px solid #e5e5e5",
    padding: `0 ${HEADER_LATERAL_PADDING}`,
  },

  modalHeaderTitle: {
    margin: 0,
    padding: 0,
    fontSize: "1.8rem",
    fontWeight: 600,
    lineHeight: HEADER_HEIGHT,
    width: "100%",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  headerRightSideElement: {
    margin: 0,
    padding: 0,
    lineHeight: HEADER_HEIGHT,
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  content: {
    padding: HEADER_LATERAL_PADDING,
  },

  loadingContainer: {
    position: "relative" as const,
    height: "20rem",
  },

  tooltipSpacer: {
    height: "3rem",
  },

  modalBackdrop: {
    position: "fixed" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(66, 62, 93, 0.9)",
  },
};

export default styles;
