/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type CalendarIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let CalendarIcon: NessieFunctionComponent<CalendarIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 25 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.741 2H4.26c-1.171 0-2.176.922-2.176 2.084v15.832c0 1.122.963 2.084 2.176 2.084H20.74c1.172 0 2.176-.922 2.176-2.084V4.084C22.917 2.922 21.913 2 20.74 2zm-3.723 1.483c.46 0 .837.36.837.802 0 .44-.377.801-.837.801-.46 0-.836-.36-.836-.801 0-.441.376-.802.836-.802zm-8.952 0c.46 0 .836.36.836.802 0 .44-.376.801-.836.801-.46 0-.837-.36-.837-.801 0-.441.377-.802.837-.802zm13.428 16.393c0 .4-.334.721-.753.721H4.26c-.418 0-.753-.32-.753-.721V6.289h17.988v13.587zm-14.017-2.75c.419.28 1.255.561 2.26.561 2.216 0 3.22-1.363 3.22-2.605 0-.882-.502-1.603-1.255-2.004.628-.441.963-1.042.963-1.764 0-1.082-.795-2.164-2.636-2.164a4.56 4.56 0 00-2.3.641c-.126.08-.168.24-.126.401l.293.762c.041.08.125.16.209.2.083.04.209 0 .293-.04.292-.2.836-.441 1.38-.441.67 0 1.046.28 1.046.802 0 .681-.837.962-1.422.962h-.586c-.21 0-.335.16-.335.32v.762c0 .2.168.32.335.32h.67c.292 0 1.673.04 1.714 1.203 0 .28-.125.56-.292.761-.251.24-.628.361-1.13.361-.625 0-1.213-.244-1.527-.375l-.063-.026c-.083-.04-.209-.08-.292-.04a.495.495 0 00-.21.2l-.292.802c-.084.16-.042.32.083.401zm6.795-5.697l-.017-.034-.21-.762c-.041-.12.042-.28.168-.36l1.715-.882c.042-.04.126-.04.167-.04h.92c.168 0 .335.12.335.32v7.615c0 .16-.125.321-.334.321H15.97c-.167 0-.335-.12-.335-.32v-6.093l-.878.441a.371.371 0 01-.293 0c-.108-.035-.154-.129-.192-.205z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CalendarIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  CalendarIcon = nessiePropCheckerWrapper(CalendarIcon);
}

export default CalendarIcon;
