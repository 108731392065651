/** @jsxImportSource theme-ui */
import logEvent from "../../utils/log_event";
import { HelpIcon } from "../../nessie/icons";
import translate from "../../utils/translate";
import { DropdownMenu, MenuOptions } from "../../nessie";

export type HelpCenterProps = {
  type: "teacher" | "parent";
  locale: string;
  hideTicketLink?: boolean;
  purple?: boolean;
};

const HelpCenter = ({ type, locale, hideTicketLink, purple }: HelpCenterProps): JSX.Element => {
  const forTeacher = type === "teacher";

  const _openHelpdesk = () => {
    logEvent("help_desk_menu.click_helpdesk");
    const zendeskLocale = getZendeskLocale(locale);
    const article = type === "parent" ? 200185365 : 200185275;
    const link = `https://classdojo.zendesk.com/hc/${zendeskLocale}/categories/${article}`;
    window.open(link, "_blank");
  };

  const _openTicketLink = () => {
    logEvent("help_desk_menu.click_submit_ticket");
    const zendeskLocale = getZendeskLocale(locale);
    const article = type === "parent" ? 28909 : 27695;
    const link = `https://classdojo.zendesk.com/hc/${zendeskLocale}/requests/new?ticket_form_id=${article}`;
    window.open(link, "_blank");
  };

  const options: MenuOptions[] = [
    {
      label: translate({
        str: forTeacher ? T_TEACHER_HELPDESK : T_PARENT_HELPDESK,
        fallback: forTeacher ? "Teacher Helpdesk" : "Parent Helpdesk",
      }),
      onClick: _openHelpdesk,
      opensAModal: false,
    },
  ];
  // Can remove this once helpdesk is rolled out to everyone.
  if (!hideTicketLink) {
    options.push({
      onClick: _openTicketLink,
      label: translate({ str: T_SUBMIT_TICKET, fallback: "Need more help?" }),
      opensAModal: false,
    });
  }

  return (
    <DropdownMenu
      label={translate({ fallback: "Open help center menu", str: "dojo.teacher_web:header.open_help" })}
      trigger={<MenuTrigger purple={purple} />}
      options={options}
      align="end"
    />
  );
};

export default HelpCenter;

function getZendeskLocale(locale?: string) {
  if (!locale) return "en-us";
  if (startsWithLowerCase(locale, "es")) return "es";
  if (startsWithLowerCase(locale, "zh")) return "zh-cn";
  if (startsWithLowerCase(locale, "pt")) return "pt";
  if (startsWithLowerCase(locale, "tr")) return "tr";
  return "en-us";
}

function startsWithLowerCase(str: string, prefix: string) {
  return str.slice(0, prefix.length).toLowerCase() === prefix;
}

function MenuTrigger({ purple }: { purple?: boolean }) {
  return <HelpIcon color={purple ? "taro50" : "white"} sx={{ width: "28px", height: "28px" }} />;
}

const T_TEACHER_HELPDESK = "dojo.common:support.teacher_helpdesk";
const T_PARENT_HELPDESK = "dojo.common:support.parent_helpdesk";
const T_SUBMIT_TICKET = "dojo.common:support.submit_ticket";
