/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type ThoughtCloudIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let ThoughtCloudIcon: NessieFunctionComponent<ThoughtCloudIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.203 7.126c.971.486 1.66 1.336 1.781 2.348.202 1.822-1.538 3.522-3.926 3.805-.527.081-1.093.04-1.58-.04-.485.93-1.578 1.66-2.954 1.821-1.133.122-2.186-.162-2.914-.769-.324.202-.77.364-1.215.405-.89.121-1.7-.162-2.186-.648-2.671-.121-4.898-1.943-5.181-4.412-.283-2.55 1.62-4.857 4.29-5.505.122-1.012 1.053-1.903 2.227-2.024.89-.081 1.74.243 2.226.85.567-.486 1.296-.85 2.105-.931 1.417-.162 2.712.445 3.4 1.457C18.26 3.403 20 4.738 20.203 6.56v.566zM6.48 19.23c1.207 0 2.185-.762 2.185-1.7 0-.94-.978-1.7-2.185-1.7-1.208 0-2.186.76-2.186 1.7 0 .938.978 1.7 2.186 1.7zm-2.793 2.226c.558 0 1.012-.345 1.012-.77 0-.424-.454-.768-1.012-.768-.56 0-1.012.344-1.012.769 0 .424.453.769 1.012.769z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.203 7.126c.971.486 1.66 1.336 1.781 2.348.202 1.822-1.538 3.522-3.926 3.805-.527.081-1.093.04-1.58-.04-.485.93-1.578 1.66-2.954 1.821-1.133.122-2.186-.162-2.914-.769-.324.202-.77.364-1.215.405-.89.121-1.7-.162-2.186-.648-2.671-.121-4.898-1.943-5.181-4.412-.283-2.55 1.62-4.857 4.29-5.505.122-1.012 1.053-1.903 2.227-2.024.89-.081 1.74.243 2.226.85.567-.486 1.296-.85 2.105-.931 1.417-.162 2.712.445 3.4 1.457C18.26 3.403 20 4.738 20.203 6.56v.566zM6.48 19.23c1.207 0 2.185-.762 2.185-1.7 0-.94-.978-1.7-2.185-1.7-1.208 0-2.186.76-2.186 1.7 0 .938.978 1.7 2.186 1.7zm-2.793 2.226c.558 0 1.012-.345 1.012-.77 0-.424-.454-.768-1.012-.768-.56 0-1.012.344-1.012.769 0 .424.453.769 1.012.769z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ThoughtCloudIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  ThoughtCloudIcon = nessiePropCheckerWrapper(ThoughtCloudIcon);
}

export default ThoughtCloudIcon;
