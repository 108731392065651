/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type LinkIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let LinkIcon: NessieFunctionComponent<LinkIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg width="1em" height="1em" viewBox="0 0 1024 1024" fill="currentColor" {...props}>
          <path d="M329.746 394.25c-57.262 0-103.683 46.45-103.683 103.75s46.421 103.75 103.683 103.75H433.43c22.902 0 41.472 18.582 41.472 41.5s-18.57 41.5-41.472 41.5H329.746c-103.074 0-186.629-83.613-186.629-186.75s83.555-186.75 186.63-186.75H433.43c22.902 0 41.472 18.582 41.472 41.5s-18.57 41.5-41.472 41.5zm228.102-41.5c0-22.918 18.566-41.5 41.472-41.5h103.684c103.07 0 186.629 83.613 186.629 186.75s-83.559 186.75-186.63 186.75H599.32c-22.906 0-41.472-18.582-41.472-41.5s18.566-41.5 41.472-41.5h103.684c57.258 0 103.68-46.45 103.68-103.75s-46.422-103.75-103.68-103.75H599.32c-22.906 0-41.472-18.582-41.472-41.5zM350.484 498c0-22.918 18.563-41.5 41.473-41.5h248.836c22.906 0 41.473 18.582 41.473 41.5s-18.567 41.5-41.473 41.5H391.957c-22.91 0-41.473-18.582-41.473-41.5zm0 0" />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
LinkIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  LinkIcon = nessiePropCheckerWrapper(LinkIcon);
}

export default LinkIcon;
