/** @jsxImportSource theme-ui */
import * as React from "react";
import { Text } from "./Text";
import { NessieThemeColors } from "./theme";
import { RAW_cssValue } from "../../nessie/stylingLib";

type OutlinedPillProps = {
  size?: "m" | "l";
  color?: NessieThemeColors;
};

export const OutlinedPill = ({ size, children, color = "capri" }: React.PropsWithChildren<OutlinedPillProps>) => {
  // Default size
  let width = "26px";
  let fontSize = "14px";
  let padding = "2px 7px";
  let lineHeight = "18px";

  if (size === "m") {
    width = "36px";
    fontSize = "18px";
    padding = "4px 10px";
    lineHeight = "24px";
  }

  if (size === "l") {
    width = "42px";
    fontSize = "22px";
    padding = "4px 10px";
    lineHeight = "30px";
  }

  return (
    <div
      sx={{
        color,
        padding: RAW_cssValue(padding),
        display: "inline-block",
        minWidth: width,
        height: width,
        borderRadius: RAW_cssValue(width),
        border: "2px solid",
        borderColor: color,
        position: "relative",
      }}
    >
      <Text nowrap textAlign="center" sx={{ fontSize, fontWeight: "bold", lineHeight }}>
        {children}
      </Text>
    </div>
  );
};
