import { palette } from "./palette";

export const textSmall = { fontSize: "14px" };
export const textMedium = { fontSize: "16px" };
export const textBold = { fontWeight: 600 };
export const textNormalWeight = { fontWeight: 400 };

export const textWhite = { color: "white" };
export const textLight = { color: palette.gray50 };
export const textBlue = { color: palette.dojoBlue };
export const textPurple = { color: palette.dojoPurple };

export const textLeft = { textAlign: "left" };
export const textCenter = { textAlign: "center" as const };
export const textRight = { textAlign: "right" };

export const ellipsis = { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" };
export const standardFont = { fontFamily: "'HafferXH', 'Helvetica Neue', Helvetica, Arial, sans-serif" };
