/** @jsxImportSource theme-ui */
import * as React from "react";
import { Subheading, BodyText } from "./Text";
import { Space } from "./Space";
import UnstyledButton from "../../components/buttons/UnstyledButton";

type ListItemProps = {
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  cursor?: React.CSSProperties["cursor"];
  onClick?: () => void;
  width?: React.CSSProperties["width"];
};

export const ListItem = React.forwardRef<HTMLButtonElement, ListItemProps>(
  ({ leftAccessory, rightAccessory, title, description, cursor, onClick, width }, ref) => {
    const ButtonOrSpan = onClick ? UnstyledButton : "span";
    return (
      <ButtonOrSpan
        ref={ref}
        onClick={onClick}
        sx={{ display: "flex", alignItems: "center", position: "relative", width, cursor }}
      >
        {leftAccessory && <ListItemLeftAccessory>{leftAccessory}</ListItemLeftAccessory>}
        {(title || description) && <TextSection title={title} description={description} />}
        {rightAccessory && <ListItemRightAccessory>{rightAccessory}</ListItemRightAccessory>}
      </ButtonOrSpan>
    );
  },
);

export const ListItemTitle = ({ children, nowrap }: { children: React.ReactNode; nowrap?: boolean }) => {
  if (typeof children === "string") {
    return <Subheading nowrap={nowrap || undefined}>{children}</Subheading>;
  } else {
    return (children || null) as React.ReactElement;
  }
};
ListItemTitle.displayName = "ListItemTitle";

export const ListItemDescription = ({ children }: { children: React.ReactNode }) => {
  if (typeof children === "string") {
    return <BodyText nowrap>{children}</BodyText>;
  } else {
    return (children || null) as React.ReactElement;
  }
};
ListItemDescription.displayName = "ListItemDescription";

const TextSection = ({ title, description }: { title?: React.ReactNode; description?: React.ReactNode }) => {
  if (title && !description) {
    return (
      <div sx={{ position: "relative" }}>
        <ListItemTitle>{title}</ListItemTitle>
      </div>
    );
  }

  return (
    <div sx={{ minWidth: "0", position: "relative" }}>
      <ListItemTitle>{title}</ListItemTitle>
      <ListItemDescription>{description}</ListItemDescription>
    </div>
  );
};

const ListItemLeftAccessory = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div sx={{ display: "flex", alignItems: "center", position: "relative" }}>{children}</div>
      <Space kind="inline" size="xs" />
    </>
  );
};
ListItemLeftAccessory.displayName = "ListItemLeftAccessory";

const ListItemRightAccessory = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div sx={{ display: "flex", flexGrow: 1, position: "relative" }} />
      <Space kind="inline" size="s" />
      {children}
    </>
  );
};
ListItemRightAccessory.displayName = "ListItemRightAccessory";
