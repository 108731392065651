/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PersonIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PersonIcon: NessieFunctionComponent<PersonIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.836 16.533c.18.08.345.154.511.21.2.08.32.24.32.44v1.844a.53.53 0 01-.52.521H2.52a.53.53 0 01-.521-.52v-1.844c0-.2.12-.401.32-.481 2.365-.882 4.97-2.325 5.17-3.006v-.44c-.48-.482-.841-1.203-1.082-2.005-.28-.2-.48-.56-.48-.962 0-.28.12-.56.28-.76V7.885c0-1.683 1.002-2.845 3.126-2.845 2.004 0 2.925.961 3.086 2.444V9.53c.16.2.28.481.28.761 0 .401-.16.762-.48.962-.04.1-.07.19-.1.28-.03.091-.06.181-.1.281a1.38 1.38 0 01-.14.324l-.061.117c0 .02-.01.03-.02.04-.01.01-.02.02-.02.04-.16.361-.361.642-.601.882-.04 0-.04.04-.04.04v.441c.12.36 1.001 1.002 2.123 1.603.2.12.441.24.682.36 0 .04.04.04.04.04.24.081.48.201.721.321.02 0 .03.01.04.02.01.01.02.02.04.02.12.06.24.11.361.16.12.05.24.101.36.161.02 0 .03.01.04.02.01.01.02.02.04.02.088.037.17.075.251.11zm4.297-.227c.567.2 1.165.412 1.745.637.16.08.28.28.24.441v1.683c0 .28-.2.481-.48.481H17.55c.04-.16.08-.32.08-.52v-1.844c0-.642-.401-1.203-1.002-1.443a21.87 21.87 0 01-2.886-1.323v-.721c-.56 0-1.002-.04-1.362-.12-.04 0-.08-.04-.12-.08.36-.481.64-1.042.841-1.643.4-.401.641-.962.641-1.563 0-.361-.12-.722-.28-1.042V7.926c0-.681-.12-1.242-.32-1.763a.225.225 0 00.032-.044c.013-.021.023-.036.047-.036.24-.24.561-.441.962-.562.401-.16.802-.24 1.243-.24.4 0 .801.08 1.162.2.48.12.841.361 1.122.682a3.684 3.684 0 011.202 2.725s.32 3.567 1.203 4.088c0 0-.842.721-3.006.721v1.122c.146.47 1.474.939 3.024 1.487z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={5} width={21} height={15}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.836 16.533c.18.08.345.154.511.21.2.08.32.24.32.44v1.844a.53.53 0 01-.52.521H2.52a.53.53 0 01-.521-.52v-1.844c0-.2.12-.401.32-.481 2.365-.882 4.97-2.325 5.17-3.006v-.44c-.48-.482-.841-1.203-1.082-2.005-.28-.2-.48-.56-.48-.962 0-.28.12-.56.28-.76V7.885c0-1.683 1.002-2.845 3.126-2.845 2.004 0 2.925.961 3.086 2.444V9.53c.16.2.28.481.28.761 0 .401-.16.762-.48.962-.04.1-.07.19-.1.28-.03.091-.06.181-.1.281a1.38 1.38 0 01-.14.324l-.061.117c0 .02-.01.03-.02.04-.01.01-.02.02-.02.04-.16.361-.361.642-.601.882-.04 0-.04.04-.04.04v.441c.12.36 1.001 1.002 2.123 1.603.2.12.441.24.682.36 0 .04.04.04.04.04.24.081.48.201.721.321.02 0 .03.01.04.02.01.01.02.02.04.02.12.06.24.11.361.16.12.05.24.101.36.161.02 0 .03.01.04.02.01.01.02.02.04.02.088.037.17.075.251.11zm4.297-.227c.567.2 1.165.412 1.745.637.16.08.28.28.24.441v1.683c0 .28-.2.481-.48.481H17.55c.04-.16.08-.32.08-.52v-1.844c0-.642-.401-1.203-1.002-1.443a21.87 21.87 0 01-2.886-1.323v-.721c-.56 0-1.002-.04-1.362-.12-.04 0-.08-.04-.12-.08.36-.481.64-1.042.841-1.643.4-.401.641-.962.641-1.563 0-.361-.12-.722-.28-1.042V7.926c0-.681-.12-1.242-.32-1.763a.225.225 0 00.032-.044c.013-.021.023-.036.047-.036.24-.24.561-.441.962-.562.401-.16.802-.24 1.243-.24.4 0 .801.08 1.162.2.48.12.841.361 1.122.682a3.684 3.684 0 011.202 2.725s.32 3.567 1.203 4.088c0 0-.842.721-3.006.721v1.122c.146.47 1.474.939 3.024 1.487z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PersonIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PersonIcon = nessiePropCheckerWrapper(PersonIcon);
}

export default PersonIcon;
