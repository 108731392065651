import { useState, useCallback, useLayoutEffect } from "react";

export default function useActive(): [onMouseDown: () => void, isActive: boolean] {
  const [isActive, setIsActive] = useState(false);

  const onMouseUp = useCallback(() => {
    setIsActive(false);
    document.removeEventListener("mouseup", onMouseUp, false);
    document.removeEventListener("dragend", onMouseUp, false);
  }, [setIsActive]);

  const onMouseDown = useCallback(() => {
    setIsActive(true);
    document.addEventListener("mouseup", onMouseUp, false);
    document.addEventListener("dragend", onMouseUp, false);
  }, [onMouseUp]);

  useLayoutEffect(() => {
    return () => {
      document.removeEventListener("mouseup", onMouseUp, false);
      document.removeEventListener("dragend", onMouseUp, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [onMouseDown, isActive];
}
