/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type LockIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let LockIcon: NessieFunctionComponent<LockIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.347 9.829h-.896V6.401C16.451 3.986 14.465 2 12.05 2S7.649 3.986 7.649 6.401V9.83h-.896c-.974 0-1.753.779-1.753 1.753l.779 8.14c0 .974.779 1.753 1.753 1.753h9.036c.974 0 1.753-.779 1.753-1.753l.779-8.14c0-.935-.779-1.753-1.753-1.753zm-4.362 8.803h-1.83l-.351-2.922c0-.7.584-1.285 1.285-1.285s1.285.584 1.285 1.285l-.39 2.922zm1.597-8.803H9.518V6.401A2.527 2.527 0 0112.05 3.87 2.527 2.527 0 0114.582 6.4V9.83z"
          />
          <mask maskUnits="userSpaceOnUse" x={5} y={2} width={15} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.347 9.829h-.896V6.401C16.451 3.986 14.465 2 12.05 2S7.649 3.986 7.649 6.401V9.83h-.896c-.974 0-1.753.779-1.753 1.753l.779 8.14c0 .974.779 1.753 1.753 1.753h9.036c.974 0 1.753-.779 1.753-1.753l.779-8.14c0-.935-.779-1.753-1.753-1.753zm-4.362 8.803h-1.83l-.351-2.922c0-.7.584-1.285 1.285-1.285s1.285.584 1.285 1.285l-.39 2.922zm1.597-8.803H9.518V6.401A2.527 2.527 0 0112.05 3.87 2.527 2.527 0 0114.582 6.4V9.83z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
LockIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  LockIcon = nessiePropCheckerWrapper(LockIcon);
}

export default LockIcon;
