/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PhotoLibraryIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PhotoLibraryIcon: NessieFunctionComponent<PhotoLibraryIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.85 6.341a.846.846 0 00-.492-.32l-2.009-.32-.357-2.056a.83.83 0 00-.893-.64l-7.412 1.417-5.759-.869c-.402-.045-.804.229-.848.64l-.224 1.463-3.214.594a.847.847 0 00-.491.32.706.706 0 00-.134.594L3.294 19.96a.872.872 0 00.312.503.742.742 0 00.447.137h.134l10.224-1.92 6.027.915h.09a.773.773 0 00.759-.686l.223-1.6.134-.045c.402-.092.67-.503.625-.914l-.357-1.874 1.071-7.678c.045-.046 0-.274-.134-.457zm-1.787 3.473l-.67 4.845-.223 1.462-.223 1.646-.044.274-.849-.137-4.732-.686-9.42-1.37 1.205-8.684.223-1.645.044-.183.536.091 4.733.686 7.367 1.096 1.607.229.759.091-.313 2.285zm-2.455-5.072l.134.777-2.367-.366 2.233-.411zM2.624 7.758l1.965-.366-.893 6.307-1.072-5.941zM4.634 19l-.313-1.736 5.313.777-5 .96zm5.134-8.226c.813 0 1.473-.675 1.473-1.508s-.66-1.508-1.473-1.508c-.814 0-1.474.675-1.474 1.508s.66 1.508 1.474 1.508zm6.965 2.468l1.384-1.462.848 4.478.09.549-1.653-.229-11.295-1.645c1.205-3.108 5.58-1.325 5.58-1.325l3.304-3.885 1.742 3.52z"
          />
          <mask maskUnits="userSpaceOnUse" x={1} y={3} width={22} height={18}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.85 6.341a.846.846 0 00-.492-.32l-2.009-.32-.357-2.056a.83.83 0 00-.893-.64l-7.412 1.417-5.759-.869c-.402-.045-.804.229-.848.64l-.224 1.463-3.214.594a.847.847 0 00-.491.32.706.706 0 00-.134.594L3.294 19.96a.872.872 0 00.312.503.742.742 0 00.447.137h.134l10.224-1.92 6.027.915h.09a.773.773 0 00.759-.686l.223-1.6.134-.045c.402-.092.67-.503.625-.914l-.357-1.874 1.071-7.678c.045-.046 0-.274-.134-.457zm-1.787 3.473l-.67 4.845-.223 1.462-.223 1.646-.044.274-.849-.137-4.732-.686-9.42-1.37 1.205-8.684.223-1.645.044-.183.536.091 4.733.686 7.367 1.096 1.607.229.759.091-.313 2.285zm-2.455-5.072l.134.777-2.367-.366 2.233-.411zM2.624 7.758l1.965-.366-.893 6.307-1.072-5.941zM4.634 19l-.313-1.736 5.313.777-5 .96zm5.134-8.226c.813 0 1.473-.675 1.473-1.508s-.66-1.508-1.473-1.508c-.814 0-1.474.675-1.474 1.508s.66 1.508 1.474 1.508zm6.965 2.468l1.384-1.462.848 4.478.09.549-1.653-.229-11.295-1.645c1.205-3.108 5.58-1.325 5.58-1.325l3.304-3.885 1.742 3.52z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PhotoLibraryIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PhotoLibraryIcon = nessiePropCheckerWrapper(PhotoLibraryIcon);
}

export default PhotoLibraryIcon;
