/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type NewsIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let NewsIcon: NessieFunctionComponent<NewsIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.306 17.6c0 .423-.33.8-.8.8H3.859l.037-.104a.68.68 0 00.057-.225V5.27H21.46c.424 0 .8.329.8.8V17.6h.047zm-10.259-6.165H6.588a.371.371 0 00-.376.377.36.36 0 00.094.235.36.36 0 00.235.094h5.46a.371.371 0 00.376-.376c.047-.142-.095-.33-.33-.33zM6.588 13.6h5.46c.187 0 .376.141.329.33a.371.371 0 01-.377.376H6.541a.36.36 0 01-.235-.094.36.36 0 01-.094-.236c0-.235.188-.376.376-.376zm5.46 2.165h-5.46a.371.371 0 00-.376.376.36.36 0 00.094.236.36.36 0 00.235.094h5.46a.371.371 0 00.376-.377c.047-.188-.142-.33-.33-.33zm2.21-4.33h5.46c.188 0 .376.188.33.33a.371.371 0 01-.377.376h-5.46a.36.36 0 01-.234-.094.36.36 0 01-.095-.235c0-.236.189-.377.377-.377zm5.46 2.165h-5.46a.371.371 0 00-.375.377.36.36 0 00.094.235.36.36 0 00.235.094h5.459a.371.371 0 00.376-.377c.047-.188-.141-.329-.33-.329zm-5.46 2.165h5.46c.188 0 .376.14.33.33a.371.371 0 01-.377.376h-5.46a.36.36 0 01-.234-.095.36.36 0 01-.095-.235c0-.235.189-.376.377-.376zM21.507 4H3.388a.623.623 0 00-.611.612v.847H.894c-.188 0-.33.047-.423.188a.604.604 0 00-.189.47v11.53c0 .612.236 2.024 2.495 2.024h18.776c1.13 0 2.024-.895 2.024-2.024V6.071c0-1.13-.942-2.071-2.071-2.071zM2.776 17.694c0 .094 0 .141-.046.235-.095.283-.33.471-.565.471-.33 0-.612-.33-.612-.706V6.777h1.224v10.917zM6.4 7.294h13.553c.094.047.188.094.188.188v2.165a.202.202 0 01-.188.188H6.4c-.047 0-.094 0-.141-.047-.047-.047-.047-.094-.047-.14V7.481c0-.094.094-.188.188-.188z"
          />
          <mask maskUnits="userSpaceOnUse" x={0} y={4} width={24} height={16}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.306 17.6c0 .423-.33.8-.8.8H3.859l.037-.104a.68.68 0 00.057-.225V5.27H21.46c.424 0 .8.329.8.8V17.6h.047zm-10.259-6.165H6.588a.371.371 0 00-.376.377.36.36 0 00.094.235.36.36 0 00.235.094h5.46a.371.371 0 00.376-.376c.047-.142-.095-.33-.33-.33zM6.588 13.6h5.46c.187 0 .376.141.329.33a.371.371 0 01-.377.376H6.541a.36.36 0 01-.235-.094.36.36 0 01-.094-.236c0-.235.188-.376.376-.376zm5.46 2.165h-5.46a.371.371 0 00-.376.376.36.36 0 00.094.236.36.36 0 00.235.094h5.46a.371.371 0 00.376-.377c.047-.188-.142-.33-.33-.33zm2.21-4.33h5.46c.188 0 .376.188.33.33a.371.371 0 01-.377.376h-5.46a.36.36 0 01-.234-.094.36.36 0 01-.095-.235c0-.236.189-.377.377-.377zm5.46 2.165h-5.46a.371.371 0 00-.375.377.36.36 0 00.094.235.36.36 0 00.235.094h5.459a.371.371 0 00.376-.377c.047-.188-.141-.329-.33-.329zm-5.46 2.165h5.46c.188 0 .376.14.33.33a.371.371 0 01-.377.376h-5.46a.36.36 0 01-.234-.095.36.36 0 01-.095-.235c0-.235.189-.376.377-.376zM21.507 4H3.388a.623.623 0 00-.611.612v.847H.894c-.188 0-.33.047-.423.188a.604.604 0 00-.189.47v11.53c0 .612.236 2.024 2.495 2.024h18.776c1.13 0 2.024-.895 2.024-2.024V6.071c0-1.13-.942-2.071-2.071-2.071zM2.776 17.694c0 .094 0 .141-.046.235-.095.283-.33.471-.565.471-.33 0-.612-.33-.612-.706V6.777h1.224v10.917zM6.4 7.294h13.553c.094.047.188.094.188.188v2.165a.202.202 0 01-.188.188H6.4c-.047 0-.094 0-.141-.047-.047-.047-.047-.094-.047-.14V7.481c0-.094.094-.188.188-.188z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
NewsIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  NewsIcon = nessiePropCheckerWrapper(NewsIcon);
}

export default NewsIcon;
