import { isFunction } from "lodash/fp";

export function handleActionKeyDown(handlers: Record<string, React.ReactEventHandler | undefined>) {
  return function (event: React.KeyboardEvent): void {
    const keyCode = _getKeyCode(event);
    if (event.target === event.currentTarget) {
      if (_isActionKey(keyCode) && isFunction(handlers.onAction)) {
        event.preventDefault();
        handlers.onAction(event);
      } else if (_isCancelKey(keyCode) && isFunction(handlers.onCancel)) {
        event.preventDefault();
        handlers.onCancel(event);
      }
    }
  };
}

function _getKeyCode(event: React.KeyboardEvent) {
  return event.key || event.keyCode || event.which;
}

function _isActionKey(keyCode: string | number) {
  const actionKeyCodes = ["Enter", 13, " ", 32];
  return actionKeyCodes.includes(keyCode);
}

function _isCancelKey(keyCode: string | number) {
  const actionKeyCodes = ["Escape", 27];
  return actionKeyCodes.includes(keyCode);
}
