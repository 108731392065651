/** @jsxImportSource theme-ui */
/* eslint-disable no-restricted-imports */
export { RAW_cssValue } from "./RAW_cssValue";

export { useThemeBreakpoint } from "./useThemeBreakpoint";

export { keyframes, ClassNames, Global as GlobalCSS } from "@emotion/react";

export { ThemeProvider } from "@theme-ui/core";

// re-export TypeScript types
export type {
  ThemeUICSSObject as StylingLibCSSObject,
  ThemeUICSSProperties as StylingLibCSSProperties,
  ThemeUIStyleObject,
} from "@theme-ui/core";

// for usage with ClassName's `css` function
import type { css } from "@emotion/react";
type CSSInterpolation = Parameters<typeof css>[1];
export type { CSSInterpolation };

// this is related to the JSX transform from ./jsx-runtime.
// In certain cases, React looks for the classic style `createElement` as a fallback
import { createElement } from "./jsx-runtime";
export { createElement };
