import get from "lodash/get";
import moment from "moment";
import translate from "./translate";
import { getLocalizedMomentFunction } from "./localtime";

export function getLocalizedMoment(time?: moment.MomentInput): moment.Moment {
  return getLocalizedMomentFunction()(time);
}

// l -- 09/04/1986
export function makeShortDate(time: moment.MomentInput, locale?: string): string {
  return getLocalizedMomentFunction()(time, locale).format("l");
}

/**
 * This function returns how many hours / days have passes from the
 * time passed to today.
 *
 * @param  {Moment/Date/String} time Time to calculate from.
 * @return {String}      A String representing the time from the param to today, expressed in hour (up to 23h) or days.
 */

/*
  We want to roughly match the table below
  so that times are consistent when we're using moment.fromNow

  Range Key Sample Output
  0 to 45 seconds              --- a few seconds ago (ignoring)
  45 to 90 seconds             --- a minute ago
  90 seconds to 45 minutes     --- 2 minutes ago - 45 minutes ago
  45 to 90 minutes             --- an hour ago
  90 minutes to 22 hours       --- 2 hours ago - 22 hours ago
  22 to 36 hours               --- a day ago
  36 hours to 25 days          --- 2 days ago - 25 days ago
  25 to 45 days                --- a month ago
  45 to 345 days               --- 2 months ago - 11 months ago
  345 to 545 days (1.5 years)  --- a year ago
  546 days+                    --- 2 years ago - 20 years ago
*/

export function fromToday(time: moment.MomentInput, locale?: string): string {
  if (!time) throw new Error("Must pass in a time!");

  const now = moment();
  const then = getLocalizedMomentFunction()(time, locale);

  const diff = now.diff(then);

  if (diff < moment.duration(90, "seconds").asMilliseconds()) return "1m";
  if (diff < moment.duration(3, "minutes").asMilliseconds()) return "2m";
  if (diff < moment.duration(45, "minutes").asMilliseconds()) return `${now.diff(then, "minutes")}m`;
  if (diff < moment.duration(90, "minutes").asMilliseconds()) return "1h";
  if (diff < moment.duration(120, "minutes").asMilliseconds()) return "2h";
  if (diff < moment.duration(22, "hours").asMilliseconds()) return `${now.diff(then, "hours")}h`;
  if (diff < moment.duration(36, "hours").asMilliseconds()) return "1d";
  if (diff < moment.duration(3, "days").asMilliseconds()) return "2d";
  if (diff < moment.duration(345, "days").asMilliseconds()) return `${now.diff(then, "days")}d`;
  if (diff < moment.duration(545, "days").asMilliseconds()) return "1y";
  if (diff < moment.duration(3, "years").asMilliseconds()) return "2y";
  else return `${now.diff(then, "year")}y`;
}

export function makeTimestampString(time: moment.MomentInput, locale?: string): string {
  if (!time) throw new Error("Must pass in a time!");

  const now = moment();
  const then = getLocalizedMomentFunction()(time, locale);

  if (process.env.NODE_ENV === "test") {
    then.utcOffset("07:00");
    now.utcOffset("07:00");
  }

  const sameYear = now.year() === then.year();

  const today = sameYear && now.dayOfYear() === then.dayOfYear();
  if (today) {
    return then.format("LT");
  }

  const yesterday = sameYear && now.dayOfYear() === then.dayOfYear() + 1;
  if (yesterday) {
    const key = "dojo.teacher_web:date_picker.yesterday";
    return translate(key, "Yesterday");
  }

  if (sameYear) {
    // Not exactly the same as "MMM Do" (includes year)
    // return then.format("MMM Do");
    return then.format("ll");
  }

  return then.format("l");
}

const getLocalizedNow = (locale?: string): moment.Moment => getLocalizedMomentFunction()(Date.now(), locale);

const getFromLocalizedNow = (key: string, locale?: string): string[] => get(getLocalizedNow(locale), key);

export const getMonths = (locale?: string): string[] => getFromLocalizedNow("_locale._months", locale);

export const getMonthsShort = (locale?: string): string[] => getFromLocalizedNow("_locale._monthsShort", locale);
export const getWeekdays = (locale?: string): string[] => getFromLocalizedNow("_locale._weekdays", locale);
export const getWeekdaysShort = (locale?: string): string[] => getFromLocalizedNow("_locale._weekdaysShort", locale);

export const getFormattedLocalizedMoment = (moment: moment.MomentInput): string => {
  const postTime = getLocalizedMoment(moment);
  const withinToday = getLocalizedMoment().startOf("day") < postTime;
  const withinYear = getLocalizedMoment().startOf("year") < postTime;
  let localizedFormattedMoment = "";
  if (withinToday) {
    localizedFormattedMoment = postTime.startOf("minute").fromNow();
  } else if (withinYear) {
    localizedFormattedMoment = postTime.format("MMM D");
  } else {
    localizedFormattedMoment = postTime.format("ll");
  }
  return localizedFormattedMoment;
};
