/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";

type StageProps = {
  children: React.ReactNode;
};

const Stage = ({ children }: StageProps): JSX.Element => {
  return children as JSX.Element;
};

Stage.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Stage;
