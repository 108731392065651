/** @jsxImportSource theme-ui */
import * as React from "react";
import Badge from "../misc/Badge";
import { palette } from "../commonStyles";
import { TranslatableProps } from "../../prop_shapes/translatable";
import translate from "../../utils/translate";
import UnstyledButton from "./UnstyledButton";
import { DetailAction } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

type NavbarButtonProps = {
  style?: React.CSSProperties;
  disabled?: boolean;
  href?: string;
  target?: string;
  id?: string;
  className?: string;
  onClick?: React.ReactEventHandler;
  active?: boolean;
  light?: boolean;
  opensModal?: boolean;
  icon?: React.ReactElement;
  badge?: number;
  badgeMax?: number;
  "data-test-name"?: string;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "li" | "label";
  // necessary for a11y
  notToggle?: boolean;
} & TranslatableProps;

/**
 * Button for our navigation bars
 */
const NavbarButton = (props: NavbarButtonProps): JSX.Element => {
  const {
    style,
    disabled,
    href,
    target,
    id,
    className,
    onClick,
    active,
    light,
    icon,
    badge = 0,
    badgeMax,
    notToggle,
    opensModal,
    as,
    ["data-test-name"]: dataTestName,
  } = props;
  const translatedText = translate(props);

  const Component = href ? "a" : UnstyledButton;

  return (
    <Component
      aria-current={href ? (active ? true : false) : undefined}
      aria-pressed={href || notToggle || opensModal ? undefined : active ? true : false}
      aria-haspopup={(!href || !notToggle) && opensModal ? true : undefined}
      sx={{ ...componentStyles, fontWeight: light ? 400 : 600 }}
      disabled={disabled && !active}
      style={style}
      target={target}
      onClick={onClick}
      href={href}
      className={className}
      id={id}
      aria-label={badge ? `${translatedText} (${badge})` : translatedText}
      data-test-name={dataTestName}
    >
      {icon}

      <DetailAction as={as} sx={{ flex: "0 0 auto" }} color={"inherit"}>
        {translatedText}
      </DetailAction>

      {badge ? <Badge count={badge} max={badgeMax} /> : null}
    </Component>
  );
};

export default NavbarButton;

const componentStyles: ThemeUIStyleObject = {
  fontSize: "1.6rem",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  gap: "dt_xs",
  flexWrap: "nowrap",
  justifyContent: "center",
  paddingX: "dt_l",
  color: "dt_taro90",
  paddingY: "dt_s",
  "&:disabled, &[disabled]": {
    color: "dt_taro50",
    pointerEvents: "none",
  },
  "&:hover": {
    backgroundColor: "dt_taro20",
  },
  '&[aria-pressed="true"], &[aria-current="true"]': {
    color: "dt_aqua50",
    boxShadow: `0 -2px 0 0 ${palette.dojoBlue} inset`,
    paddingX: "0",
    marginX: "dt_l",

    "&:hover": { backgroundColor: "transparent" },
  },
};
