/** @jsxImportSource theme-ui */
import { useState } from "react";
import * as React from "react";
import mailcheck from "mailcheck";
import ValidatedInput from "./ValidatedInput";
import { Button } from "../../nessie";

export type ValidatedEmailInputProps = Omit<
  React.ComponentPropsWithoutRef<typeof ValidatedInput>,
  "type" | "onBlur" | "onChange"
> & {
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
};

/**
 * Similar to Validated Input but with the addition of automatically checking
 * email format and adding email suggestions.
 */
const ValidatedEmailInput = React.forwardRef<HTMLInputElement, ValidatedEmailInputProps>(
  ({ value, onChange, onBlur, ...inputProps }, inputRef) => {
    const [suggestedEmail, setSuggestedEmail] = useState("");

    const updateParentEmail = () => {
      if (onChange) {
        onChange(suggestedEmail);
      }

      setSuggestedEmail("");
    };

    const checkEmail = () => {
      const email = (value || "").toLowerCase();

      mailcheck.run({
        email,
        suggested: (suggestion: { full?: string }) => {
          const emailSuggestion = (suggestion.full || "").toLowerCase();
          if (emailSuggestion !== email) {
            setSuggestedEmail(emailSuggestion);
          } else {
            setSuggestedEmail("");
          }
        },
      });

      if (onBlur) {
        onBlur();
      }
    };

    return (
      <span>
        <ValidatedInput
          ref={inputRef}
          {...inputProps}
          value={value}
          onChange={onChange}
          onBlur={checkEmail}
          type="email"
        />
        {suggestedEmail ? (
          <Button onClick={updateParentEmail} size="s" kind="tertiary" sx={{ marginTop: "xs" }}>
            <span>Did you mean: {suggestedEmail}?</span>
          </Button>
        ) : null}
      </span>
    );
  },
);

export default ValidatedEmailInput;
