/** @jsxImportSource theme-ui */
const makeSpace = (spaces: [number, number, number, number, number, number, number, number]) => {
  return Object.assign(spaces, {
    auto: "auto",
    unset: "unset",
    "0": spaces[0],
    xxs: spaces[1],
    xs: spaces[2], // baseline
    s: spaces[3],
    m: spaces[4],
    l: spaces[5],
    xl: spaces[6],
    xxl: spaces[7],
    // "dt_" aliases. We should eventually migrate all usages to these:
    dt_xxs: spaces[1],
    dt_xs: spaces[2], // baseline
    dt_s: spaces[3],
    dt_m: spaces[4],
    dt_l: spaces[5],
    dt_xl: spaces[6],
    dt_xxl: spaces[7],
  });
};

//                        0 xxs xs s   m   l   xl  xxl
const spaces = makeSpace([0, 3, 6, 12, 18, 24, 30, 54]);

const contentAccent = "rgb(125, 64, 255)";
const contentAccentHover = "rgb(97, 43, 212)";
const contentAccentActive = "rgb(65, 36, 143)";

const contentPrimary = "#1A192D";
const contentSecondary = "#5D5D8F";

const contentDanger100 = "#D92218"; // Left in HEX for easy search
const contentDanger5 = "rgba(217, 34, 24, 0.05)";
const contentSuccess = "#13803A";

const primaryBorderColor = "rgba(0, 9, 91, 45%);";

const midnight = "#16134A"; // Left in HEX for easy search
const midnight5 = "rgba(22, 19, 74, 0.05)";

const dojoIslandsYellow = "#FFD633";
const dojoIslandsYellowMidnight20 = "#D0AE38"; // yellow with the midnight overlay of 20%
const dojoIslandsYellowMidnight40 = "#A2883D"; // yellow with the midnight overlay of 40%

const whiteWithMidnight20 = "#D0D0DB"; // white with the midnight overlay of 20%
const whiteWithMidnight40 = "#A2A1B7"; // white with the midnight overlay of 40%

const taro10 = "#F7F8FF";
const taro20 = "#EAECF5";
const taro30 = "#D3D7EC";
const taro40 = "#AAB0D8";
const taro50 = "#7174A0";
const taro60 = "#545382";
const taro90 = "#2C2A50";

const grape20 = "#F0E7FF";
const grape30 = "#D5BFFF";
const grape40 = "#AD85FF";
const grape50 = "#8047FF";
const grape60 = "#4E10EB";

const blueberry20 = "#E4F3FF";
const blueberry30 = "#ADDAFF";
const blueberry40 = "#61B5FF";
const blueberry50 = "#3A74FF";
const blueberry60 = "#194BE0";

const aqua10 = "#F2FAFF";
const aqua20 = "#E1F8FF";
const aqua30 = "#B2EEFF";
const aqua40 = "#70DBFF";
const aqua50 = "#00B2F7";
const aqua60 = "#0092E5";

const kiwi20 = "#F2FBE7";
const kiwi30 = "#DFFFBA";
const kiwi40 = "#B4F575";
const kiwi50 = "#83DC1F";
const kiwi60 = "#58BC00";

const mango20 = "#FFFEE5";
const mango30 = "#FFF8AB";
const mango40 = "#FDF267";
const mango50 = "#FFDC00";
const mango60 = "#F7B100";

const tangerine20 = "#FFF3E1";
const tangerine30 = "#FFDFB3";
const tangerine40 = "#FFBC5F";
const tangerine50 = "#FF9517";
const tangerine60 = "#E95E13";

const watermelon20 = "#FFECEE";
const watermelon30 = "#FFC9D0";
const watermelon40 = "#FFA2A3";
const watermelon50 = "#FF5854";
const watermelon60 = "#D72A2B";

const makeBreakpoints = (breakpoints: [string, string, string, string]) => {
  return Object.assign(breakpoints, {
    // Extra small screen / phone (mobile first)
    xs: 0,
    // Small screen / phone
    s: breakpoints[0],
    // Medium screen / tablet
    m: breakpoints[1],
    // Large screen / desktop
    l: breakpoints[2],
    // Extra large screen / wide desktop
    xl: breakpoints[3],

    // "dt_" aliases. We should eventually migrate all usages to these:
    dt_viewport_xs: 0,
    // Small screen / phone
    dt_viewport_s: breakpoints[0],
    // Medium screen / tablet
    dt_viewport_m: breakpoints[1],
    // Large screen / desktop
    dt_viewport_l: breakpoints[2],
    // Extra large screen / wide desktop
    dt_viewport_xl: breakpoints[3],
  });
};

const breakpoints = makeBreakpoints(["576px", "768px", "992px", "1200px"]);

//
// Shadows
// theme-ui expects an array but we want 'keyed' tokens.
const makeShadows = (shadows: string[]) => {
  return Object.assign(shadows, {
    dt_shadow_shadezies: shadows[0],
    dt_shadow_shadezies_inverted: shadows[1],
    dt_shadow_shadezies_small: shadows[2],
  });
};
const shadows = makeShadows([
  "0px 6px 0px rgba(45, 64, 150, 0.06)", // 0 dt_shadow_shadezies
  "0px -3px 0px rgba(45, 64, 150, 0.06)", // 1 dt_shadow_inverted
  "0px 2px 0px rgba(45, 64, 150, 0.06)", // 2 dt_shadow_shadezies_small
]);

// --------------------------------------------
// Z INDEXES
// @classdojo/web has the values below. We should find a better way to read from web, for now copy / paste works.
// We want our modals to show on top of the @classdojo/web modals, so we can embed new modals on top of older modals but
// not the other way around.
const webPackageZIndexes = {
  overlay: 110,
  dropdown: 120,
  modalOverlay: 130,
  globalErrorBanner: 140, // error banner should appear above everything else
};

export type NessieSpaceSizes =
  | "auto"
  | "0"
  | "xxs"
  | "dt_xxs"
  | "xs"
  | "dt_xs"
  | "s"
  | "dt_s"
  | "m"
  | "dt_m"
  | "l"
  | "dt_l"
  | "xl"
  | "dt_xl"
  | "xxl"
  | "dt_xxl";

export type NessieThemeColors = keyof (typeof theme)["colors"];

export type NessieTheme = typeof theme;

//
// Primary theme object required by our ThemeProvider
//
export const theme = {
  //
  // Spacing tokens
  //
  space: spaces,

  //
  // Border radius tokens
  //
  radii: {
    dt_radius_xs: "18px",
    dt_radius_s: "24px",
    dt_radius_m: "30px",
    dt_radius_l: "54px",
    dt_radius_round: "50%",
    dt_radius_pill: "9999px",
  },

  //
  // Color tokens
  //
  colors: {
    white: "#fff",
    dt_white: "#fff",
    transparent: "transparent",
    inherit: "inherit",

    //
    // Latest colors. hue x saturation
    //
    contentAccent,
    contentAccentHover,
    contentAccentActive,
    contentPrimary,
    contentSecondary,
    contentDanger: contentDanger100,
    contentDanger5: contentDanger5,
    contentSuccess,
    primaryBorderColor,

    midnight,
    midnight5,

    dojoIslandsYellow,
    dojoIslandsYellowMidnight20,
    dojoIslandsYellowMidnight40,

    whiteWithMidnight20,
    whiteWithMidnight40,

    taro10,
    dt_taro10: taro10,
    taro20,
    dt_taro20: taro20,
    taro30,
    dt_taro30: taro30,
    taro40,
    dt_taro40: taro40,
    taro50,
    dt_taro50: taro50,
    taro60,
    dt_taro60: taro60,
    taro90,
    dt_taro90: taro90,

    // WARNING: Wrong just to make it compile. what color should be use for taro80 ?
    dt_taro80: taro90,
    taro80: taro90,

    grape20,
    dt_grape20: grape20,
    grape30,
    dt_grape30: grape30,
    grape40,
    dt_grape40: grape40,
    grape50,
    dt_grape50: grape50,
    grape60,
    dt_grape60: grape60,

    blueberry20,
    dt_blueberry20: blueberry20,
    blueberry30,
    dt_blueberry30: blueberry30,
    blueberry40,
    dt_blueberry40: blueberry40,
    blueberry50,
    dt_blueberry50: blueberry50,
    blueberry60,
    dt_blueberry60: blueberry60,

    aqua10,
    dt_aqua10: aqua10,
    aqua20,
    dt_aqua20: aqua20,
    aqua30,
    dt_aqua30: aqua30,
    aqua40,
    dt_aqua40: aqua40,
    aqua50,
    dt_aqua50: aqua50,
    aqua60,
    dt_aqua60: aqua60,

    kiwi20,
    dt_kiwi20: kiwi20,
    kiwi30,
    dt_kiwi30: kiwi30,
    kiwi40,
    dt_kiwi40: kiwi40,
    kiwi50,
    dt_kiwi50: kiwi50,
    kiwi60,
    dt_kiwi60: kiwi60,

    // WARNING: Wrong just to make it compile. what color should be use for mango10 ?
    mango10: mango20,
    dt_mango10: mango20,

    mango20,
    dt_mango20: mango20,
    mango30,
    dt_mango30: mango30,
    mango40,
    dt_mango40: mango40,
    mango50,
    dt_mango50: mango50,
    mango60,
    dt_mango60: mango60,

    tangerine20,
    dt_tangerine20: tangerine20,
    tangerine30,
    dt_tangerine30: tangerine30,
    tangerine40,
    dt_tangerine40: tangerine40,
    tangerine50,
    dt_tangerine50: tangerine50,
    tangerine60,
    dt_tangerine60: tangerine60,

    watermelon20,
    dt_watermelon20: watermelon20,
    watermelon30,
    dt_watermelon30: watermelon30,
    watermelon40,
    dt_watermelon40: watermelon40,
    watermelon50,
    dt_watermelon50: watermelon50,
    watermelon60,
    dt_watermelon60: watermelon60,

    //
    // Deprecated color names
    //

    quartz: taro10,
    granite: taro30,
    basalt: taro40,
    shale: taro90,
    // Often ok to replace with: dt_taro90 => #2C2A50
    shaleOpacity1: "rgba(66, 62, 93, .9)",

    glacier: aqua10,
    maya: aqua40,
    capri: aqua50,

    keyLime: kiwi20,
    spearmint: kiwi40,
    kiwi: kiwi50,

    buttermilk: mango20,
    limoncello: mango40,
    mango: mango50,

    ambrosia: watermelon20,
    nectar: watermelon40,
    pitaya: watermelon50,

    cantaloupe: tangerine20,
    butternut: tangerine40,
    tangerine: tangerine50,

    bubbles: blueberry20,
    daylight: blueberry40,
    cobalt: blueberry50,

    lavenderMist: grape20,
    wisteria: grape30,
    ultraviolet: grape60,
  },

  //
  // Shadow tokens
  //
  shadows,

  fontFamily: "'HafferXH', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  breakpoints,

  //
  // Border tokens
  //
  borders: {
    none: "none",
    unset: "unset",
    normal: `1px solid ${taro30}`,
    bold: `2px solid ${taro30}`,
    "0": 0,
    dt_border_normal: `1px solid ${taro30}`,
    dt_border_card: `2px solid ${taro30}`,
  },

  zIndexes: {
    // We want ALL our modals to have the same z-index. The way Portals work is by adding a new portal at the end of the
    // body, this means that if we render one modal inside other the portals are going to be added ascending order and
    // elements with same z-index will stack based on the dom order.
    // This enables us to combine modals from current @classdojo/web package and this package as well as full / non fullscreen ones.
    modalOverlay: webPackageZIndexes.modalOverlay,
    globalErrorBanner: webPackageZIndexes.globalErrorBanner,
  },
  stackingOrder: {
    /**
     * Used for the Overlay and everything that's inside.
     */
    OVERLAY: webPackageZIndexes.modalOverlay,
    /**
     * Used as the default for the StackingContext.
     */
    STACKING_CONTEXT: 5,
  },
};
