/** @jsxImportSource theme-ui */
import { Text } from "./Text";

type InputGroupProps = {
  groupLabel?: string;
  groupLabelId?: string;
  groupLabelAs?: "h2" | "h3" | "h4" | "h5" | "h6";
  groupLabelStyles?: React.CSSProperties;
  groupType: "checkbox" | "radio";
  className?: string;
  children: React.ReactNode;
  ["aria-label"]?: string; // use only if no label was provided by designers or there is no obvious label around to pass with groupLabelId
};

export const InputGroup = ({
  groupLabel,
  groupLabelId,
  groupLabelStyles,
  groupLabelAs,
  groupType,
  className,
  children,
  ["aria-label"]: ariaLabel,
}: InputGroupProps) => {
  return (
    <div
      role={groupType === "radio" ? "radiogroup" : "group"}
      aria-labelledby={groupLabelId}
      className={className}
      aria-label={ariaLabel}
    >
      {groupLabel && (
        <Text as={groupLabelAs || "h3"} id={groupLabelId} sx={{ ...groupLabelStyles }}>
          {groupLabel}
        </Text>
      )}
      {children}
    </div>
  );
};
