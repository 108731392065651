// IMPORTANT: This is a legacy/deprecated set of colors. The updated palette lives in
// nessie/components/theme.ts

import { darken } from "./colorManipulation";
import { theme } from "../nessie";

export const palette = {
  dojoBlue: theme.colors.aqua50,
  dojoPurple: theme.colors.taro60,
  dojoRed: theme.colors.watermelon50,
  dojoOrange: theme.colors.tangerine50,
  badgeRed: theme.colors.watermelon60,
  gray5: theme.colors.taro10,
  gray10: theme.colors.taro10,
  gray20: theme.colors.taro20,
  gray30: theme.colors.taro30,
  gray40: theme.colors.taro40,
  gray50: theme.colors.taro50,
  gray80: theme.colors.taro90,
  grayWash: theme.colors.taro10,
  blueSelected: theme.colors.aqua10,
  greenPositive: theme.colors.kiwi50,
  greenProgress: theme.colors.kiwi50,
  greenWash: theme.colors.kiwi30,
  purpleBackground: theme.colors.taro90,
  purpleHighlight: theme.colors.taro60,
  goldMentor: theme.colors.mango60,
  orangeZero: theme.colors.mango60,
  blueWash: theme.colors.blueberry20,
};

// For use ONLY in highContrast mode
// colors are darkened 20%
// This palette has NOT been vetted by design.
// THIS IS FOR DEMONSTRATION ONLY -- DO NOT FLIP
// THE FEATURE FLAG OR USE THIS IN PRODUCTION
// -- will
export const highContrastPalette: typeof palette = Object.keys(palette).reduce((p, colorName: keyof typeof palette) => {
  p[colorName] = darken(palette[colorName], 20);
  return p;
}, {} as typeof palette);
