/** @jsxImportSource theme-ui */
import { useState } from "react";
import { TextField, TextFieldProps, TEXT_FIELD_HEIGHT } from "./TextField";
import { EyeIcon } from "../../nessie/icons";
import { UnstyledButton } from "../..";

type PasswordTextFieldProps = Omit<TextFieldProps, "type">;

export function PasswordTextField(props: PasswordTextFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div sx={{ position: "relative" }}>
      {/* @ts-ignore */}
      <TextField type={showPassword ? "text" : "password"} {...props} />
      <UnstyledButton
        onClick={() => {
          setShowPassword(!showPassword);
        }}
        aria-label={showPassword ? "hide password" : "show password"}
        sx={{
          height: TEXT_FIELD_HEIGHT,
          maxHeight: TEXT_FIELD_HEIGHT,
          position: "absolute",
          top: 0,
          right: 0,
          paddingX: "l",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        type="button"
      >
        <EyeIcon size="s" />
      </UnstyledButton>
    </div>
  );
}
