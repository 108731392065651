/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type GlobeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let GlobeIcon: NessieFunctionComponent<GlobeIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.494 2 2 6.494 2 12s4.494 10 10 10 10-4.494 10-10c-.04-5.506-4.494-10-10-10zm0 18.583c-2.51 0-4.777-1.093-6.356-2.793.08-.203.486-1.418.769-1.984.324-.608.405-.689 1.093-1.296.688-.607-1.376-.89-1.862-1.376-.486-.486-.729-.486-1.255-.932-.405-.323-.648 1.134-.73 1.863a8.398 8.398 0 01-.242-2.024c0-1.822.567-3.523 1.538-4.9-.283.85-.526 2.065.527 1.701 1.174-.405 1.255-.89 1.255-.89l.688-1.215s.122 1.66.243 1.903c.121.243.688.93.89-.04.365-1.782.446-2.268 1.094-2.268.648 0 1.984-.526 2.146 1.053.121 1.579-.122.647-1.013 1.052-.85.405-1.052 1.863-.283 2.024.77.162 2.591.891 2.591.891l.89 1.012.284 1.053s.162 1.215.162 1.296v1.093s.04 2.145.81 1.66c.77-.527 2.955-3.887 2.51-5.142-.445-1.255-.486-1.417.324-1.863.81-.445 1.417-1.012 1.376-1.619-.04-.445 0-.688.04-.972.649 1.215 1.053 2.632 1.053 4.09 0 4.777-3.846 8.623-8.542 8.623z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.494 2 2 6.494 2 12s4.494 10 10 10 10-4.494 10-10c-.04-5.506-4.494-10-10-10zm0 18.583c-2.51 0-4.777-1.093-6.356-2.793.08-.203.486-1.418.769-1.984.324-.608.405-.689 1.093-1.296.688-.607-1.376-.89-1.862-1.376-.486-.486-.729-.486-1.255-.932-.405-.323-.648 1.134-.73 1.863a8.398 8.398 0 01-.242-2.024c0-1.822.567-3.523 1.538-4.9-.283.85-.526 2.065.527 1.701 1.174-.405 1.255-.89 1.255-.89l.688-1.215s.122 1.66.243 1.903c.121.243.688.93.89-.04.365-1.782.446-2.268 1.094-2.268.648 0 1.984-.526 2.146 1.053.121 1.579-.122.647-1.013 1.052-.85.405-1.052 1.863-.283 2.024.77.162 2.591.891 2.591.891l.89 1.012.284 1.053s.162 1.215.162 1.296v1.093s.04 2.145.81 1.66c.77-.527 2.955-3.887 2.51-5.142-.445-1.255-.486-1.417.324-1.863.81-.445 1.417-1.012 1.376-1.619-.04-.445 0-.688.04-.972.649 1.215 1.053 2.632 1.053 4.09 0 4.777-3.846 8.623-8.542 8.623z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
GlobeIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  GlobeIcon = nessiePropCheckerWrapper(GlobeIcon);
}

export default GlobeIcon;
