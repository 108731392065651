/** @jsxImportSource theme-ui */
import {
  NessieComponentProps,
  oneOfNessieResponsivePropType,
  spacingNessieResponsivePropType,
  NessieFunctionComponent,
} from "./propTypes";
import { nessiePropCheckerWrapper } from "./nessiePropCheckerWrapper";

type SpaceProps = NessieComponentProps & {
  kind?: "inline" | "block";
  size: number | string;
};

export let Space: NessieFunctionComponent<SpaceProps> = ({ children, kind = "block", size }) => {
  const props: { marginRight?: number | string; marginBottom?: number | string } = {};
  if (kind === "inline") {
    props.marginRight = size;
  }
  if (kind === "block") {
    props.marginBottom = size;
  }
  return (
    <div sx={kind === "inline" ? { marginRight: size } : kind === "block" ? { marginBottom: size } : undefined}>
      {children}
    </div>
  );
};

Space.displayName = "Space";

Space.nessiePropTypes = {
  kind: oneOfNessieResponsivePropType(["inline", "block"]),
  size: spacingNessieResponsivePropType,
};

// ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking
if (process.env.NODE_ENV === "development") {
  Space = nessiePropCheckerWrapper(Space);
}
