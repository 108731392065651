/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type IdeasIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let IdeasIcon: NessieFunctionComponent<IdeasIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.206 21.406c1.698-.31 3.453-.82 4.26-2.94 0-.028-.027-.085-.083-.085-.724.424-1.671.48-1.977.48-.084 0-.112-.028-.112-.113-.027-.31-.112-1.272.223-2.035.028-.028-.027-.085-.084-.085-1.976 1.074-2.283 2.882-2.394 4.636.027.086.11.142.167.142zm-2.173-8.48c-.084.114 0 .283.167.198 2.2-.734 4.371-.396 5.124-.227.166.029.223.198.194.311l-.5 1.924c-.028.114.027.283.167.31a4.78 4.78 0 011.392.82c.53.51.863 1.018.975 1.328.084.114.194.17.305.114l1.81-.735c.168-.027.279 0 .363.17.224.706.863 2.912.363 5.2-.028.114.111.227.223.114 1.059-1.216 3.148-3.93 2.98-7.914 0-.085.027-.113.083-.17C21.11 9.566 21.917 3.998 22 1c-2.896.424-8.299 1.893-12.364 7.914-.084.028-.112.086-.196.086-3.899.31-6.32 2.74-7.407 3.927zm14.23-5.425c.807.734.89 2.034.166 2.882a2.023 2.023 0 01-2.84.17c-.808-.735-.89-2.035-.167-2.883.752-.848 2.034-.932 2.84-.17z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={1} width={20} height={22}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.206 21.406c1.698-.31 3.453-.82 4.26-2.94 0-.028-.027-.085-.083-.085-.724.424-1.671.48-1.977.48-.084 0-.112-.028-.112-.113-.027-.31-.112-1.272.223-2.035.028-.028-.027-.085-.084-.085-1.976 1.074-2.283 2.882-2.394 4.636.027.086.11.142.167.142zm-2.173-8.48c-.084.114 0 .283.167.198 2.2-.734 4.371-.396 5.124-.227.166.029.223.198.194.311l-.5 1.924c-.028.114.027.283.167.31a4.78 4.78 0 011.392.82c.53.51.863 1.018.975 1.328.084.114.194.17.305.114l1.81-.735c.168-.027.279 0 .363.17.224.706.863 2.912.363 5.2-.028.114.111.227.223.114 1.059-1.216 3.148-3.93 2.98-7.914 0-.085.027-.113.083-.17C21.11 9.566 21.917 3.998 22 1c-2.896.424-8.299 1.893-12.364 7.914-.084.028-.112.086-.196.086-3.899.31-6.32 2.74-7.407 3.927zm14.23-5.425c.807.734.89 2.034.166 2.882a2.023 2.023 0 01-2.84.17c-.808-.735-.89-2.035-.167-2.883.752-.848 2.034-.932 2.84-.17z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
IdeasIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  IdeasIcon = nessiePropCheckerWrapper(IdeasIcon);
}

export default IdeasIcon;
