/** @jsxImportSource theme-ui */
import { forwardRef, CSSProperties } from "react";
import PropTypes from "prop-types";

type UnstyledElasticTextAreaProps = Omit<JSX.IntrinsicElements["textarea"], "style"> & {
  wrapperStyle?: CSSProperties;
  textAreaStyle?: CSSProperties;
  children?: never;
};

/**
 * React implementation of the approach described in
 * http://alistapart.com/article/expanding-text-areas-made-elegant
 */
const UnstyledElasticTextArea = forwardRef<HTMLTextAreaElement, UnstyledElasticTextAreaProps>(
  function UnstyledElasticTextArea({ wrapperStyle, textAreaStyle, ...rest }, ref) {
    return (
      <div sx={{ ...styles.wrapper, ...wrapperStyle }}>
        <pre sx={{ ...styles.pre, ...styles.common, ...textAreaStyle }}>
          <span>{rest.value}</span>
          <br />
        </pre>
        <textarea ref={ref} sx={{ ...styles.area, ...styles.common, ...textAreaStyle }} {...rest} tabIndex={0} />
      </div>
    );
  },
);

UnstyledElasticTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  wrapperStyle: PropTypes.object,
  textAreaStyle: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default UnstyledElasticTextArea;

const styles = {
  common: {
    margin: 0,
    border: 0,
    padding: "dt_s",
    background: "transparent",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    fontSize: "inherit",
    fontFamily: "inherit", // IMPORTANT
    wordBreak: "break-word",
  } as CSSProperties,
  area: {
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    resize: "none",
  } as CSSProperties,
  pre: {
    display: "block",
    visibility: "hidden",
  } as CSSProperties,
  wrapper: {
    position: "relative",
  } as CSSProperties,
};
