/** @jsxImportSource theme-ui */
// this component was developed following the w3c a11y patterns:
// https://www.w3.org/WAI/ARIA/apg/example-index/combobox/combobox-select-only.html
import { CheckmarkIcon, ChevronDownIcon } from "../../nessie/icons";
import { RAW_cssValue } from "../../nessie/stylingLib";
import React, { useEffect, useState, useRef } from "react";
import WithClickOutside from "../../components/misc/WithClickOutside";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { uniqueId } from "lodash/fp";

export type ComboBoxOptionType = { label: string; value: string };
export interface ComboBoxProps {
  options: ComboBoxOptionType[];
  labelText: string;
  labelPosition?: "top" | "left" | "hidden";
  selectedValue: ComboBoxOptionType["value"];
  onSelectOption: (option: ComboBoxOptionType) => void;
  className?: string;
}

export function ComboBox({
  options,
  labelText,
  labelPosition = "top",
  selectedValue,
  onSelectOption,
  className,
}: ComboBoxProps) {
  const [baseId] = useState(() => uniqueId("ComboBoxOption"));
  const [showOptions, setShowOptions] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<number | undefined>();
  const [comboboxInfo, setComboboxInfo] = useState({ left: 0, width: 0, height: 0, bottom: 0 });
  const [positionIndicatorInfo, setPositionIndicatorInfo] = useState({ left: 0, bottom: 0 });
  const listBoxRef = useRef<HTMLDivElement>(null);
  const comboBoxRef = useRef<HTMLDivElement>(null);
  const positionIndicator = useRef<HTMLDivElement>(null);

  // map a key press to an action
  // eslint-disable-next-line complexity
  function handleComboKeyDown(event: React.KeyboardEvent) {
    const { key, altKey, ctrlKey, metaKey } = event;

    if (key !== "Tab") event.preventDefault();

    const openKeys = ["ArrowDown", "ArrowUp", "Enter", " "]; // all keys that will do the default open action

    // handle opening when closed
    if (!showOptions && openKeys.includes(key)) setShowOptions(true);

    // home and end move the selected option when open or closed
    if (key === "Home" || key === "End") {
      setShowOptions(true);

      return setActiveIndex(key === "Home" ? 0 : options.length - 1);
    }

    // handle typing characters when open or closed
    if (
      key === "Backspace" ||
      key === "Clear" ||
      (key.length === 1 && key !== " " && !altKey && !ctrlKey && !metaKey)
    ) {
      setSearchString(searchString + key);
    }

    // handle keys when open
    if (showOptions) {
      if ((key === "ArrowUp" && altKey) || key === "Enter" || key === " ") {
        onSelectOption(options[activeIndex]);
        setShowOptions(false);
      } else if (key === "ArrowDown" && !altKey) {
        setActiveIndex(Math.min(options.length - 1, activeIndex + 1));
      } else if (key === "ArrowUp") {
        setActiveIndex(Math.max(0, activeIndex - 1));
      } else if (key === "Escape") {
        setShowOptions(false);
      }
    }
  }

  function handleBlur() {
    if (showOptions) {
      onSelectOption(options[activeIndex]);
      setShowOptions(false);
    }
  }

  useEffect(() => {
    if (showOptions) {
      if (selectedValue) {
        setActiveIndex(options.findIndex((el) => el.value === selectedValue));
      }
      handleListPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const handleListPosition = () => {
    if (comboBoxRef.current) setComboboxInfo(comboBoxRef.current.getBoundingClientRect());
  };

  useEffect(() => {
    window.addEventListener("scroll", handleListPosition);
    if (positionIndicator.current) setPositionIndicatorInfo(positionIndicator.current.getBoundingClientRect());
    return () => {
      window.removeEventListener("scroll", handleListPosition);
    };
  }, []);

  // makes sure active index in visible inside scrollable parent
  useEffect(() => {
    document.getElementById(`${baseId}-${activeIndex}`)?.scrollIntoView({ block: "center" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    if (!searchString) return;

    // open the listbox if it is closed
    if (!showOptions) setShowOptions(true);
    // reset typing timeout and start new timeout
    // this allows us to make multiple-letter matches, like a native select
    else if (searchTimeout) window.clearTimeout(searchTimeout);

    const newTimeout = window.setTimeout(() => {
      setSearchString("");
      setSearchTimeout(undefined);
    }, 500);

    setSearchTimeout(newTimeout);

    // find the index of the first matching option
    const searchIndex = getIndexOfNextMatch(options, searchString, activeIndex);

    // if a match was found, go to it
    if (searchIndex >= 0) setActiveIndex(searchIndex);

    return () => {
      window.clearTimeout(newTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const containerStyle: ThemeUIStyleObject =
    labelPosition === "left"
      ? { gridTemplateColumns: "135px auto", alignItems: "center", justifyItems: "end" }
      : { gridTemplateRows: "auto auto" };

  const selectedOptionLabel = options.filter((option) => selectedValue === option.value)[0]?.label;

  return (
    <WithClickOutside onClickOutside={() => setShowOptions(false)}>
      <div
        sx={labelPosition === "hidden" ? {} : { width: "100%", display: "grid", gap: "dt_xs", ...containerStyle }}
        className={className}
      >
        {labelPosition === "hidden" ? null : <label sx={comboLabelStyles}>{labelText}</label>}
        <div
          //combobox
          aria-controls={`${baseId}-listbox`}
          aria-expanded={showOptions}
          aria-haspopup="listbox"
          aria-label={labelText}
          id={baseId}
          sx={comboInputStyles}
          role="combobox"
          tabIndex={0}
          onClick={() => setShowOptions(!showOptions)}
          onBlur={handleBlur}
          onKeyDown={handleComboKeyDown}
          aria-activedescendant={`${baseId}-${activeIndex}`}
          ref={comboBoxRef}
        >
          {selectedOptionLabel ? selectedOptionLabel : <span>&nbsp;</span>}
          <ChevronDownIcon size="s" color="dt_taro50" />
          <div ref={positionIndicator} sx={{ position: "fixed", top: 0, left: 0 }} />
          {showOptions ? (
            <div
              sx={{
                ...listBoxStyles,
                top: comboboxInfo?.bottom - positionIndicatorInfo.bottom,
                left: comboboxInfo?.left - positionIndicatorInfo.left,
                width: comboboxInfo?.width,
              }}
              role="listbox"
              id={`${baseId}-listbox`}
              aria-label={labelText}
              ref={listBoxRef}
            >
              {options.map((option, index) => (
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                  key={`${baseId}-${index}`}
                  role="option"
                  id={`${baseId}-${index}`}
                  sx={{ ...comboOptionStyles, borderColor: index === activeIndex ? "dt_taro50" : "transparent" }}
                  aria-selected={selectedValue === option.value}
                  onClick={() => onSelectOption(option)}
                >
                  {option.label}
                  {selectedValue === option.value ? <CheckmarkIcon size="s" color="dt_taro90" /> : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </WithClickOutside>
  );
}

function checkIfAllTheLettersInAStringAreTheSame(string: string) {
  const array = string.split("");
  if (array.every((letter) => letter === array[0])) return array[0];
  return string;
}

// return the index of an option from an array of options, based on a search string
// if the filter is multiple iterations of the same letter (e.g "aaa"), then cycle through first-letter matches
function getIndexOfNextMatch(options: ComboBoxOptionType[], inputFilter: string, activeIndex: number) {
  const filter = checkIfAllTheLettersInAStringAreTheSame(inputFilter);
  const lowerFilter = filter.toLowerCase();

  const matchesIndexes = options.reduce(
    (acc, option, index) => {
      const lowerString = option.label.toLowerCase();

      if (lowerString.includes(lowerFilter)) {
        if (acc.firstMatch === -1) acc.firstMatch = index;
        else if (index > activeIndex && acc.nextMatch === -1 && acc.firstMatch <= activeIndex) acc.nextMatch = index;
      }
      return acc;
    },
    { firstMatch: -1, nextMatch: -1 },
  );
  return Math.max(matchesIndexes.firstMatch, matchesIndexes.nextMatch);
}

const comboInputStyles: ThemeUIStyleObject = {
  borderRadius: RAW_cssValue("5px"),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "dt_s",
  textAlign: "left",
  width: "100%",
  position: "relative",
  fontSize: "16px",
  fontWeight: "normal",
  lineHeight: `22px`,
  boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, .15) inset`,
  border: `1px solid`,
  borderColor: "dt_taro20",
  color: "dt_taro60",
  height: "36px",
  ":focus": {
    borderColor: "dt_aqua50",
    outline: `none`,
  },
};

const listBoxStyles: ThemeUIStyleObject = {
  backgroundColor: "dt_white",
  border: `1px solid`,
  borderColor: "dt_taro20",
  borderBottomRightRadius: RAW_cssValue("5px"),
  borderBottomLeftRadius: RAW_cssValue("5px"),
  maxHeight: "200px",
  overflowY: "scroll",
  position: "fixed",
  width: "100%",
  zIndex: "100",
};

const comboOptionStyles: ThemeUIStyleObject = {
  padding: "dt_s",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "2px solid",
  borderColor: "transparent",
  fontSize: "14px",
  lineHeight: "18px",
  ":hover": {
    backgroundColor: "dt_blueberry20",
  },
  "&[aria-selected=true]": {
    backgroundColor: "dt_blueberry20",
    color: "dt_taro90",
  },
};

const comboLabelStyles: ThemeUIStyleObject = {
  display: "block",
  fontSize: "14px",
  color: "dt_taro90",
  fontWeight: "100",
  whiteSpace: "nowrap",
};
