/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import { Tile } from "./Tile";
import { CloseButton } from "../modals";

type WelcomeTileProps = {
  title?: React.ReactNode;
  image?: string;
  imageWidth?: string | number;
  close?: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
};

export const WelcomeTile = ({ title, image, imageWidth, close, style, children, className }: WelcomeTileProps) => {
  return (
    <Tile style={{ ...styles.container, ...style }} className={className}>
      {close && <CloseButton close={close} />}
      <div sx={{ display: "flex" }}>
        {image && (
          <div>
            <img alt="" src={image} sx={{ width: imageWidth, marginRight: "dt_xl" }} />
          </div>
        )}
        <div sx={{ flex: 1 }}>
          {title && <div style={styles.title}>{title}</div>}
          <div style={styles.content}>{children}</div>
        </div>
      </div>
    </Tile>
  );
};
WelcomeTile.propTypes = {
  close: PropTypes.func,
  image: PropTypes.node,
  imageWidth: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const styles = {
  container: {
    position: "relative",
    padding: "2.6rem",
  } as React.CSSProperties,
  title: {
    fontSize: "1.8rem",
    fontWeight: 600,
    marginBottom: "1rem",
  } as React.CSSProperties,
  content: {
    fontSize: "1.4rem",
    fontWeight: 200,
  } as React.CSSProperties,
};
