/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import { colorNessieResponsivePropType } from "./propTypes";
import { NessieTheme, NessieThemeColors, theme } from "./theme";
import { RAW_cssValue, StylingLibCSSObject } from "../stylingLib";

const WEIGHT_EXTRABOLD = 900;
const WEIGHT_BOLD = 700;
const WEIGHT_SEMIBOLD = 600;
// const WEIGHT_NORMAL = "400";
const DEFAULT_LINE_HEIGHT = 1.3;
const fontFamily = "'HafferXH', 'Helvetica Neue', Helvetica, Arial, sans-serif";

//----------------------------------------------
//----------------------------------------------
// Base Text Component

const textStyle: StylingLibCSSObject = {
  borderWidth: 0,
  boxSizing: "border-box",
  color: "inherit",
  fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  fontSize: "14px",
  fontStyle: "inherit",
  fontVariant: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  margin: 0,
  padding: 0,
  textDecorationLine: "none",

  // block level by default, but inline when nested
  display: "block",
  ">*": {
    display: "inline !important",
  },

  // default wrapping
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
};

const inlineStyle = { display: "inline" };
const noWrapStyle: StylingLibCSSObject = {
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ">.text": {
    whiteSpace: "inherit",
  },
};

// Pre compute the class name on evaluation time rather than render time, to make it more performant.
const textAlignStyles = {
  left: { textAlign: "left" } as StylingLibCSSObject,
  center: { textAlign: "center" } as StylingLibCSSObject,
  right: { textAlign: "right" } as StylingLibCSSObject,
};

type TextProps = {
  children: React.ReactNode;
  href?: string;
  nowrap?: boolean;
  inline?: boolean;
  className?: string;
  textAlign?: keyof typeof textAlignStyles;
  onClick?: React.MouseEventHandler;
  color?: NessieThemeColors;
  "data-test-name"?: string;
  "aria-label"?: string;
  overrides?: {
    root?: { style: (theme: NessieTheme) => React.CSSProperties };
  };
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "li" | "label";
  htmlFor?: string;
  id?: string;
  target?: string;
  role?: "alert" | "status";
};

//
// Primary Text component. All other components build on top of this base text element
//
export const Text = ({
  as,
  children,
  href,
  nowrap,
  inline,
  className,
  textAlign,
  onClick,
  overrides = {},
  color,
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  htmlFor,
  id,
  target,
  role,
}: TextProps) => {
  const Tag = typeof href === "string" ? "a" : as || "span";

  let style: React.CSSProperties = {};
  if (overrides.root && overrides.root.style) {
    style = overrides.root.style(theme);
  }
  if (color && theme.colors[color]) {
    style.color = theme.colors[color];
  }

  return (
    <Tag
      data-test-name={dataTestName}
      href={href}
      target={target}
      onClick={onClick}
      className={`nessie-text ${className}`}
      aria-label={ariaLabel}
      htmlFor={htmlFor}
      sx={{
        ...textStyle,
        ...((textAlign && textAlignStyles[textAlign]) || {}),
        ...((inline && inlineStyle) || {}),
        ...((nowrap && noWrapStyle) || {}),
      }}
      id={id}
      style={style}
      role={role}
    >
      {children}
    </Tag>
  );
};

Text.propTypes = {
  overrides: PropTypes.shape({
    root: PropTypes.shape({
      style: PropTypes.func,
    }),
  }),
  color: colorNessieResponsivePropType,
};

//
// ---------------------------------------
// Text variants
//

// ---------------------------------------
// ---------------------------------------
// Caption

type CaptionProps = TextProps & {
  kind?: keyof typeof captionKindStyles;
};

export const Caption = ({ kind = "normal", color, ...rest }: CaptionProps) => {
  return <Text {...rest} sx={{ ...captionStyle, ...captionKindStyles[kind], ...(color && { color }) }} />;
};

// Caption styles
const captionStyle = {
  fontFamily,
  fontSize: 12,
  lineHeight: DEFAULT_LINE_HEIGHT,
  // Font vertical aligment correction
  paddingTop: RAW_cssValue("1.4px"),
  letterSpacing: ".25px",
  fontWeight: WEIGHT_BOLD,
  margin: 0,
};
const captionKindStyles = {
  normal: { color: "dt_taro90" },
  active: { color: "dt_aqua50" },
  inactive: { color: "dt_taro40" },
};

// ------------------------------
// ------------------------------
// Action

type ActionProps = TextProps & {
  compact?: boolean;
  kind?: keyof typeof actionKindStyles;
} & ActionConditionalProps;

/**
 * Action should only have on click if it has an href as a complement to the redirection action like a log.
 * The action is based on the text and generates a span unless it has an href.
 * Non interactive elements should not have onClick.
 */
type ActionConditionalProps =
  | {
      href: string;
      onClick?: () => void;
    }
  | {
      onClick?: never;
      href?: never;
    };

export const Action = ({ compact = false, color, kind = "available", ...rest }: ActionProps) => {
  return (
    <Text
      {...rest}
      sx={{
        ...actionStyle,
        ...((compact && actionCompactStyle) || {}),
        ...actionKindStyles[kind],
        ...(color && { color }),
      }}
    />
  );
};

const actionStyle = {
  fontFamily,
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: WEIGHT_BOLD,
  margin: 0,
};
// There's no Action Compact in designer's Nessie. Match this to "Detail action"
const actionCompactStyle = {
  fontSize: "15px",
  lineHeight: "18px",
};
const actionKindStyles = {
  available: { "&, &:visited": { color: "dt_aqua50" }, "&[href]:hover": { color: "dt_aqua60" } },
  unavailable: { "&, &:visited": { color: "dt_taro30" } },
  destructive: { "&, &:visited": { color: "dt_watermelon50" }, "&[href]:hover": { color: "dt_watermelon60" } },
  inactive: { "&, &:visited": { color: "dt_taro40" }, "&[href]:hover": { color: "dt_taro50" } },
};

// -----------------------------
// -----------------------------
// Title

type TitleProps = TextProps & {
  size?: keyof typeof titleSizeStyles;
};

export const Title = ({ size = 1, color, as = "h1", ...rest }: TitleProps) => {
  if (Number(size) < 1 || Number(size) > 4) {
    throw new Error("invalid size attribute passed to Title");
  }

  return (
    <Text
      {...rest}
      as={as}
      sx={{
        ...titleStyle,
        ...titleSizeStyles[size],
        ...(color && { color }),
      }}
    />
  );
};

const titleStyle = {
  fontFamily,
  lineHeight: "1",
  fontWeight: WEIGHT_EXTRABOLD,
  margin: 0,
  color: "dt_taro90",
};

const titleSizeStyles = {
  1: {
    letterSpacing: "-.35px",
    fontSize: "30px",
    lineHeight: "36px",
  },
  2: {
    letterSpacing: "-.5px",
    fontSize: "50px",
  },
  3: {
    letterSpacing: "-.72px",
    fontSize: "72px",
  },
  4: {
    letterSpacing: "-1px",
    fontSize: "102px",
  },
};

// -------------------------------
// -------------------------------
// Heading

type HeadingProps = TextProps & {
  kind?: keyof typeof headingKindStyles;
};

export const Heading = ({ kind = "normal", as, color, ...rest }: HeadingProps) => {
  return <Text {...rest} as={as} sx={{ ...headingStyle, ...headingKindStyles[kind], ...(color && { color }) }} />;
};

const headingStyle = {
  fontFamily,
  fontSize: "24px",
  lineHeight: "30px",
  // Font vertical aligment correction
  letterSpacing: "-.25px",
  fontWeight: WEIGHT_EXTRABOLD,
  margin: 0,
  color: "dt_taro90",
};
const headingKindStyles = {
  normal: { color: "dt_taro90" },
  active: { color: "dt_aqua50" },
  inactive: { color: "dt_taro40" },
};

//--------------------------------------
//--------------------------------------
// Subheading
export const Subheading = ({ color, as = "h2", ...rest }: TextProps) => {
  return <Text {...rest} as={as} sx={{ ...subheadingStyle, ...(color && { color }) }} />;
};

const subheadingStyle = {
  fontFamily,
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: WEIGHT_EXTRABOLD,
  margin: 0,
  color: "dt_taro90",
};

//--------------------------------------
//--------------------------------------
// BodyText
export const BodyText = ({ color, ...rest }: TextProps) => {
  return <Text {...rest} sx={{ ...bodyStyle, ...(color && { color }) }} />;
};
const bodyStyle = {
  fontFamily,
  fontSize: 18,
  lineHeight: "22px",
  letterSpacing: "-.25px",
  fontWeight: WEIGHT_SEMIBOLD,
  margin: 0,
  color: "dt_taro90",
};

//--------------------------------------
//--------------------------------------
// DeprecatedDetailText
export const DeprecatedDetailText = (props: TextProps) => {
  return <Text {...props} sx={detailStyle} />;
};
const detailStyle = {
  fontFamily,
  fontSize: 18,
  lineHeight: DEFAULT_LINE_HEIGHT,
  letterSpacing: "-.25px",
  fontWeight: WEIGHT_SEMIBOLD,
  margin: 0,
  color: "dt_taro40",
};

//--------------------------------------
//--------------------------------------
// DetailText
export const DetailText = ({ color, ...rest }: TextProps) => {
  return <Text {...rest} sx={{ ...karkDetailStyle, ...(color && { color }) }} />;
};
const karkDetailStyle = {
  fontFamily,
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: WEIGHT_SEMIBOLD,
  margin: 0,
  color: "dt_taro90",
};

//--------------------------------------
//--------------------------------------
// DetailAction
export const DetailAction = ({ color, ...rest }: TextProps) => {
  return <Text {...rest} sx={{ ...detailActionStyle, ...(color && { color }) }} />;
};
const detailActionStyle = {
  fontFamily,
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: WEIGHT_BOLD,
  margin: 0,
  color: "dt_taro90",
};

//--------------------------------------
//--------------------------------------
// DetailHeading
export const DetailHeading = ({ color, ...rest }: TextProps) => {
  return <Text {...rest} sx={{ ...detailHeadingStyle, ...(color && { color }) }} />;
};
const detailHeadingStyle = {
  fontFamily,
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: WEIGHT_BOLD,
  letterSpacing: ".5px",
  textTransform: "uppercase" as const,
  margin: 0,
  color: "dt_taro90",
};

// ------------------------------------
// ------------------------------------
// Other text related styles

// ---
// Text field styles
export const textFieldsTextStyles = {
  fontFamily,
  fontSize: 18,
  lineHeight: "22px",
  letterSpacing: "-.25px",
  fontWeight: WEIGHT_SEMIBOLD,
  color: "dt_taro90",
};
