/** @jsxImportSource theme-ui */
import { intersection, isObject } from "lodash/fp";

// TODO: Get these from the theme
const BREAKPOINTS_KEYS = [
  "xs",
  "s",
  "m",
  "l",
  "xl",
  "dt_viewport_xs",
  "dt_viewport_s",
  "dt_viewport_m",
  "dt_viewport_l",
  "dt_viewport_xl",
];

export const isResponsiveValue = (cssPropValue: unknown): boolean =>
  isObject(cssPropValue) && intersection(BREAKPOINTS_KEYS, Object.keys(cssPropValue)).length > 0;

export const transformResponsiveObjectIntoResponsiveArray = <T>(
  responsiveObject: Record<string, T>,
): readonly [T | null, T | null, T | null, T | null, T | null] => {
  const responsiveArray = [
    responsiveObject.xs || responsiveObject.dt_viewport_xs || null,
    responsiveObject.s || responsiveObject.dt_viewport_s || null,
    responsiveObject.m || responsiveObject.dt_viewport_m || null,
    responsiveObject.l || responsiveObject.dt_viewport_l || null,
    responsiveObject.xl || responsiveObject.dt_viewport_xl || null,
  ] as const;

  return responsiveArray;
};
