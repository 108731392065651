/** @jsxImportSource theme-ui */
import { useState } from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import Icon from "./Icon";
import styles from "./Styles";
import { iconValidator } from "./PropTypes";
import WithClickOutside from "../WithClickOutside";
import { Grid } from "../../positioning";
import { handleActionKeyDown } from "../../../utils/keyboard";
import translate from "../../../utils/translate";
import commonStyles from "../../commonStyles";

type IconOption = {
  value: number | string;
  iconUrl: string;
};

const toggleContainerWidth = 90;

export type IconPickerProps = {
  value: number | string;
  onChange: (value: number | string) => void;
  options: IconOption[];
  tabIndex?: number;
};

/**
 * Icon picker
 */
const IconPicker = ({ value, onChange, options, tabIndex = 0 }: IconPickerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const item = find(options, (opt) => String(opt.value) === String(value));
  const src = item && item.iconUrl;

  const closePicker = () => setIsOpen(false);
  const togglePicker = () => setIsOpen(!isOpen);

  const onSelect = (selected: IconOption) => {
    onChange(selected.value);
    togglePicker();
  };

  const picker = (
    <div style={styles.pickerContainer} data-test-name="iconPickerContent">
      <Grid itemsPerRow={5} itemWidth={styles.iconSize + 2 * styles.iconPadding}>
        {options.map((icon, i) => (
          <Icon key={i} icon={icon} onSelect={() => onSelect(icon)} />
        ))}
      </Grid>
    </div>
  );

  const onKeyDown = handleActionKeyDown({ onAction: togglePicker, onCancel: closePicker });

  return (
    <div sx={{ position: "relative" as const, width: toggleContainerWidth }}>
      <WithClickOutside onClickOutside={closePicker}>
        <div
          data-test-name="iconPickerToggle"
          sx={toggleContainer}
          onClick={togglePicker}
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}
          role="button"
          aria-expanded={isOpen}
          aria-label={translate({ str: "dojo.common:actions.select_icon", fallback: "Select an icon" })}
        >
          <img alt="" data-test-name="iconPickerCurrentIcon" style={styles.toggleImage} src={src} />
          <span style={styles.caret} />
        </div>

        {isOpen && picker}
      </WithClickOutside>
    </div>
  );
};

IconPicker.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(iconValidator),
};

export default IconPicker;

const toggleContainer = {
  backgroundColor: "dt_taro10",
  boxShadow: `inset 0 0 0 2px ${commonStyles.palette.gray20}`,
  borderRadius: "dt_radius_s",
  textAlign: "center" as const,
  padding: "s",
  width: toggleContainerWidth,
  height: toggleContainerWidth,
  cursor: "pointer",
};
