import { ProductEvent } from "@classdojo/log-client/lib/client/client";

type LogEventFunction = {
  (event: string): void;
  (event: ProductEvent): void;
};

let logEvent: LogEventFunction = function () {
  throw new Error("You must initialize the component library with a `logEvent` function to log events!");
};

const defaultLogEvent: LogEventFunction = function (...args: Parameters<LogEventFunction>): void {
  return logEvent(...args);
} as LogEventFunction;

export default defaultLogEvent;

export function setLogEventFunction(fn: LogEventFunction): void {
  logEvent = fn;
}
