/** @jsxImportSource theme-ui */
import { useState } from "react";
import styles from "./Styles";
import { iconValidator } from "./PropTypes";
import { handleActionKeyDown } from "../../../utils/keyboard";

/**
 * Private.
 * Icon used internally by the Icon Picker
 */

type IconProps = {
  icon: { value: unknown; iconUrl: string };
  tabIndex?: number;
  onSelect: () => void;
  "aria-label"?: string;
};
const Icon = ({ icon, tabIndex = 0, onSelect, "aria-label": ariaLabel }: IconProps): JSX.Element => {
  const [hovered, setHovered] = useState(false);

  const iconItemStyles = {
    ...styles.iconItem,
    ...(hovered && styles.iconItemHovered),
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onKeyDown = handleActionKeyDown({ onAction: onSelect });

  return (
    <div
      style={iconItemStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
      tabIndex={tabIndex}
      role="button"
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
    >
      <img alt="" src={icon.iconUrl} style={styles.iconImg} />
    </div>
  );
};

Icon.propTypes = { icon: iconValidator };

export default Icon;
