/** @jsxImportSource theme-ui */
import * as React from "react";

type CardProps = {
  className?: string;
};

const Card = ({ className, children }: React.PropsWithChildren<CardProps>) => {
  return (
    <div
      className={className}
      sx={{
        background: "dt_white",
        boxShadow: "dt_shadow_shadezies",
        border: "dt_border_card",
        borderRadius: "dt_radius_m",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};

export { Card };
