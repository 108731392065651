/** @jsxImportSource theme-ui */
import { ButtonHTMLAttributes, forwardRef } from "react";

type UnstyledButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & { inverted?: string };
const UnstyledButton = forwardRef<HTMLButtonElement, UnstyledButtonProps>(({ ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    sx={{ backgroundColor: "transparent", border: 0, padding: 0, textAlign: "inherit" }}
    // Do something with the extra props
  />
));

export default UnstyledButton;
