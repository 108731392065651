/** @jsxImportSource theme-ui */
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import React from "react";
import { CloseIcon, MinusIcon } from "../icons";
import { UnstyledButton } from "../..";
import { Button } from "./Button";

type ToastBannerProps = {
  text: string;
  error?: boolean;
  leftIcon?: React.ReactElement;
  rightButton?: { text: string; onClick: (arg?: any) => void };
  closeToast?: () => void;
  transparent?: boolean;
};

function ToastBanner({ text, error, leftIcon, rightButton, closeToast, transparent }: ToastBannerProps) {
  const errorStyles = error ? { backgroundColor: "dt_watermelon50" } : {};
  return (
    <div sx={{ ...toastContainerStyles, ...errorStyles, opacity: transparent ? 0 : 1 }}>
      {error ? <MinusIcon color="white" /> : leftIcon}
      <span sx={{ flexGrow: 1 }}>{text}</span>
      {rightButton && !transparent ? (
        <Button onClick={rightButton.onClick} size="s">
          {rightButton.text}
        </Button>
      ) : null}
      <UnstyledButton sx={closeButtonStyles} onClick={closeToast} data-test-name="toastCloseButton">
        <CloseIcon color="white" />
      </UnstyledButton>
    </div>
  );
}

export default ToastBanner;

const toastContainerStyles: ThemeUIStyleObject = {
  paddingY: "dt_s",
  paddingX: "dt_m",
  minHeight: 60,
  minWidth: 350,
  backgroundColor: "dt_taro90",
  color: "dt_white",
  borderRadius: "dt_radius_s",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  gap: "dt_m",
  fontSize: 15,
};

const closeButtonStyles: ThemeUIStyleObject = {
  marginLeft: "auto",
  width: 40,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
