/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type ListIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let ListIcon: NessieFunctionComponent<ListIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg width="1em" height="1em" viewBox="0 0 1024 1024" fill="currentColor" {...props}>
          <path d="M859.831 727.27h-451.7c-17.105 0-30.995 13.909-30.995 31.014s13.89 30.976 30.995 30.976h451.7c17.105 0 30.993-13.871 30.993-30.976s-13.888-31.014-30.993-31.014zm0-240.495h-451.7c-17.105 0-30.995 13.871-30.995 30.997 0 17.139 13.89 30.955 30.995 30.955h451.7c17.105 0 30.993-13.833 30.993-30.955-.017-17.143-13.888-30.997-30.993-30.997zm-451.7-178.546h451.7c17.105 0 30.993-13.872 30.993-30.977 0-17.123-13.888-31.013-30.993-31.013h-451.7c-17.105 0-30.995 13.89-30.995 31.013 0 17.105 13.872 30.977 30.995 30.977zM262.823 207.823h-76.837c-17.123 0-31.013 13.872-31.013 30.977v76.873c0 17.105 13.89 31.013 31.013 31.013h76.837c17.123 0 31.013-13.908 31.013-31.013V238.8c0-17.105-13.89-30.977-31.013-30.977zm0 240.501h-76.837c-17.123 0-31.013 13.905-31.013 31.031v76.851c0 17.105 13.89 30.98 31.013 30.98h76.837c17.123 0 31.013-13.875 31.013-30.98v-76.851c0-17.143-13.89-31.031-31.013-31.031zm0 240.529h-76.837c-17.123 0-31.013 13.871-31.013 30.976v76.873c0 17.105 13.89 30.98 31.013 30.98h76.837c17.123 0 31.013-13.875 31.013-30.98v-76.873c0-17.122-13.89-30.976-31.013-30.976z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ListIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  ListIcon = nessiePropCheckerWrapper(ListIcon);
}

export default ListIcon;
