/** @jsxImportSource theme-ui */
import ColoredCircle, { ColoredCircleProps } from "./ColoredCircle";
import translate from "../../utils/translate";
import { StylingLibCSSObject } from "../../nessie/stylingLib";

export type BehaviorCircleProps = ColoredCircleProps & {
  behavior: {
    points: number | null;
    positive: boolean;
  };
  absent?: boolean;
  bubbleStyle?: StylingLibCSSObject;
  hideIfZero?: boolean;
};

const BehaviorCircle = ({ behavior, absent, bubbleStyle, type, hideIfZero, ...rest }: BehaviorCircleProps) => {
  const { points, positive } = behavior;
  if (points === null || (hideIfZero && !points)) return null;
  let circleType: "success" | "danger" | "warning" | "primary" | "secondary";

  if (type) {
    circleType = type;
  } else if (absent) {
    circleType = "secondary";
  } else if (behavior.points === 0) {
    circleType = "warning";
  } else if (positive) {
    circleType = "success";
  } else {
    circleType = "danger";
  }

  const content = `${!positive && points > 0 ? "-" : ""}${points}`;
  const circleLabel = `${translate({
    str: `dojo.teacher_web:common.point${points === 1 ? "" : "s"}`,
    fallback: `__count__ point${points === 1 ? "" : "s"}`,
    subs: {
      count: content,
    },
  })}`;

  return (
    <ColoredCircle type={circleType} aria-label={circleLabel} sx={bubbleStyle} {...rest}>
      {content}
    </ColoredCircle>
  );
};

export default BehaviorCircle;

export function getPointsFromObject(
  object: { currentPoints?: number; positivePoints?: number; negativePoints?: number },
  bubbleType?: "combinedTotal" | "separateTotals" | "dontShowPoints",
) {
  if (bubbleType === "separateTotals") return object.positivePoints || 0;
  else if (bubbleType === "combinedTotal") return (object.positivePoints || 0) - Math.abs(object.negativePoints || 0);
  else if (bubbleType === "dontShowPoints") return null;
  else return object.negativePoints || 0;
}
