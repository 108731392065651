/** @jsxImportSource theme-ui */
import { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

let portalContainer: HTMLDivElement;

// Simple wrapper around React Portal API to simply render children in a portal.
// https://reactjs.org/docs/portals.html
// Inspired (if not cc'ed) from
// https://github.com/segmentio/evergreen/blob/a50d8ec18a5030d47b69e000e37c6a1625a20bc1/src/portal/src/Portal.js

type PortalProps = {
  children: React.ReactNode;
};

export class Portal extends Component<PortalProps> {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  el: HTMLDivElement;

  constructor(props: PortalProps) {
    super(props);

    // This fixes SSR
    if (!canUseDOM) return;

    if (!portalContainer) {
      portalContainer = document.createElement("div");
      portalContainer.setAttribute("nessie-portal-container", "");
      document.body.appendChild(portalContainer);
    }

    this.el = document.createElement("div");
  }

  componentDidMount() {
    portalContainer.appendChild(this.el);
  }

  componentWillUnmount() {
    portalContainer.removeChild(this.el);
  }

  render() {
    // This fixes SSR
    if (!canUseDOM) return null;
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
