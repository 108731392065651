/** @jsxImportSource theme-ui */
import isArray from "lodash/isArray";
import * as React from "react";
import commonStyles from "../commonStyles";
import PropTypes from "prop-types";

/**
 * Grid renders a set of children as an equally spaced grid.
 *
 * Children will wrap if the container is not wide enough to fit all children in a
 * single row.
 *
 * Gutter can be a number (applies on all sides) or a tuple of numbers (vertical, horizontal).
 *
 * If itemsPerRow and itemWidth are defined, the Grid element will automatically
 * set its width to fit that many items in a row.
 *
 * Gutter is a multiple of gutterSize.
 * ItemWidth is a multiple of gridUnit.
 */
type GridProps = {
  itemsPerRow?: number;
  itemWidth?: number;
  gutter?: number | [vertical: number, horizontal: number];
  children?: React.ReactNode;
  style?: React.CSSProperties;
  "data-test-name"?: string;
  roleType?: "tablist" | "list";
};

const Grid = ({
  itemsPerRow,
  itemWidth,
  gutter = 1,
  children,
  style,
  "data-test-name": dataTestName,
  roleType,
}: GridProps): JSX.Element => {
  let margin: string;

  if (isArray(gutter)) {
    const verticalMargin = `${(gutter[0] * commonStyles.layout.gutterSize) / 2}rem`;
    const horizontalMargin = `${(gutter[1] * commonStyles.layout.gutterSize) / 2}rem`;
    margin = `${verticalMargin} ${horizontalMargin}`;
  } else {
    margin = `${(gutter * commonStyles.layout.gutterSize) / 2}rem`;
  }

  const gutterWidth = isArray(gutter) ? gutter[1] : gutter;
  const _style = {
    // Set a negative margin so the grid does not have padding on the extremes
    // (like margin top on the first row margin bottom on the last row, etc)
    margin: `-${margin}`,
    ...(itemsPerRow && itemWidth && { width: `${itemsPerRow * (itemWidth + gutterWidth)}rem` }),
  };

  const getChildRole = () => {
    let childRole: string;

    switch (roleType) {
      case "tablist":
        childRole = "tab";
        break;
      case "list":
        childRole = "listitem";
        break;
      default:
        return undefined;
    }
    return childRole;
  };

  return (
    <div style={{ ..._style, ...style }} data-test-name={dataTestName} role={roleType}>
      {React.Children.map(children, (child) => (
        <GridItem
          child={child}
          itemWidth={itemWidth}
          itemsPerRow={itemsPerRow}
          gutter={gutter}
          margin={margin}
          role={getChildRole()}
        />
      ))}
    </div>
  );
};

Grid.propTypes = {
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  itemsPerRow: PropTypes.number,
  itemWidth: PropTypes.number,
  ["data-test-name"]: PropTypes.string,
  roleType: PropTypes.string,
};

export default Grid;

const GridItem = ({
  child,
  itemWidth,
  itemsPerRow,
  gutter,
  margin,
  role,
}: Pick<GridProps, "itemWidth" | "itemsPerRow"> & {
  child?: React.ReactNode;
  margin: string;
  gutter: number | [vertical: number, horizontal: number];
  role?: string;
}) => {
  // account for conditionally rendered children
  if (!child) {
    return null;
  }

  const horizontalGutter = isArray(gutter)
    ? gutter[1] * (commonStyles.layout.gutterSize * 10)
    : gutter * (commonStyles.layout.gutterSize * 10);

  const style = {
    width: itemWidth
      ? `${itemWidth * commonStyles.layout.gridUnit}rem`
      : itemsPerRow
      ? `calc((100% - ${itemsPerRow * horizontalGutter}px) / ${itemsPerRow})`
      : undefined,
    verticalAlign: "top",
    display: "inline-block",
    margin,
  };

  return (
    <div style={style} role={role}>
      {child}
    </div>
  );
};
