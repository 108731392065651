/** @jsxImportSource theme-ui */
import { ComboBox, ComboBoxProps } from "./ComboBox";

export function NessieComboBox({
  options,
  labelText,
  labelPosition = "top",
  selectedValue,
  onSelectOption,
  className,
}: ComboBoxProps) {
  return (
    <ComboBox
      labelText={labelText}
      labelPosition={labelPosition}
      options={options}
      onSelectOption={onSelectOption}
      selectedValue={selectedValue}
      className={className}
      sx={{
        '[role="combobox"]': {
          height: "60px",
          border: "2px solid",
          borderColor: "dt_taro60",
          borderRadius: "dt_radius_xs",
          boxShadow: "none",
          padding: "dt_m",
          paddingLeft: "dt_l",
          fontWeight: "600",
          fontSize: "18px",
          ":focus-within": {
            borderColor: "dt_aqua50",
          },
        },
        '[role="listbox"]': {
          borderRadius: "dt_radius_s",
          transform: "translateY(10px)",
        },
        '[role="option"]': {
          "&:first-of-type": {
            borderTopRightRadius: "dt_radius_s",
            borderTopLeftRadius: "dt_radius_s",
          },
          "&:last-of-type": {
            borderBottomRightRadius: "dt_radius_s",
            borderBottomLeftRadius: "dt_radius_s",
          },
        },
        "& label": {
          fontSize: "18px",
          lineHeight: "30px",
          fontWeight: "bold",
          marginLeft: "dt_xs",
        },
      }}
    />
  );
}
