export const relative: React.CSSProperties = {
  position: "relative",
};

export const fillViewportFixed: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const fillContainerAbsolute: React.CSSProperties = {
  position: "absolute",
  overflow: "hidden",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

// using position: absolute to center horiz/vertically. width and height must
// be known, however.
export const centerAbsolute = (
  width: NonNullable<React.CSSProperties["width"]>,
  height: NonNullable<React.CSSProperties["height"]>,
): React.CSSProperties => ({
  ...fillContainerAbsolute,
  margin: "auto",
  width,
  height,
});

// use CSS transforms to position horiz/vertically
export const XYTransform = (
  horizontal: NonNullable<React.CSSProperties["left"]>,
  vertical: NonNullable<React.CSSProperties["top"]>,
): React.CSSProperties => ({
  position: "absolute",
  top: vertical,
  left: horizontal,
  transform: `translate(-${horizontal},-${vertical})`.replace(/--/g, ""), // no double minus signs
});

export const centerTransform = XYTransform("50%", "50%");
