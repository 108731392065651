/** @jsxImportSource theme-ui */
import { PureComponent } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import { theme } from "../theme";
import StackingContext from "./StackingContext";

type StackProps = typeof Stack.defaultProps & {
  value: number;
  children: (value: number) => React.ReactNode;
};

export default class Stack extends PureComponent<StackProps> {
  static propTypes = {
    /**
     * Function that takes the current z-index and returns a React Node.
     * (zIndex) => ReactNode.
     */
    children: PropTypes.func.isRequired,

    /**
     * Set the value of the stack. This will increment for children.
     */
    value: PropTypes.number,
  };

  static defaultProps = {
    value: theme.stackingOrder.STACKING_CONTEXT,
  };

  render() {
    const { children, value } = this.props;
    return (
      <StackingContext.Consumer>
        {(previousValue) => {
          const currentValue = Math.max(value, previousValue);
          const nextValue = currentValue + 1;
          return <StackingContext.Provider value={nextValue}>{children(currentValue)}</StackingContext.Provider>;
        }}
      </StackingContext.Consumer>
    );
  }
}
