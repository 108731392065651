/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PeopleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PeopleIcon: NessieFunctionComponent<PeopleIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2 3.2a3.262 3.262 0 00-2.684 1.399 5.537 5.537 0 012.101 5.617l-.082.39c-.129.623-.292 1.409-.537 2.127-.198.584-.48 1.225-.914 1.817.308.115.676.236 1.104.375l.062.02c.61.199 1.359.442 1.962.73.42.199.795.481 1.103.825h2.715a1.47 1.47 0 00.63-2.8c-.435-.207-1.009-.393-1.583-.58-1.134-.37-2.273-.74-2.359-1.268v-.539c0-.155.004-.341.123-.437.945-.761 1.223-2.095 1.476-3.312l.084-.396A3.275 3.275 0 0016.2 3.2zM6.542 9.835a3.743 3.743 0 117.316 0l-.095.452c-.29 1.391-.608 2.916-1.688 3.786-.136.11-.14.321-.14.5v.615c.098.604 1.4 1.027 2.695 1.449.657.213 1.313.427 1.81.663a1.683 1.683 0 01-.72 3.2H4.68a1.68 1.68 0 01-.72-3.2c.487-.231 1.125-.442 1.765-.653 1.287-.424 2.583-.852 2.682-1.459v-.639c0-.192-.02-.412-.164-.536-1.012-.878-1.323-2.367-1.605-3.722l-.096-.456z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PeopleIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PeopleIcon = nessiePropCheckerWrapper(PeopleIcon);
}

export default PeopleIcon;
