/** @jsxImportSource theme-ui */
import iPalette from "./Palette";
import iUnselectable from "./Unselectable";

export const palette = iPalette;
export const unselectable = iUnselectable;

import {
  shadowRegular,
  shadowMedium,
  shadowSemiStrong,
  shadowStrong,
  shadowRegularHighContrast,
  shadowMediumHighContrast,
  shadowStrongHighContrast,
} from "../../styles";

export const fonts = {
  family: "'HafferXH', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  sizeNormalText: "1.6rem",
  sizeH1: "2rem",
  sizeInputs: "1.6rem",
};

export const shadows = {
  regular: shadowRegular,
  medium: shadowMedium,
  semiStrong: shadowSemiStrong, // for class story cards (and cards in general)
  strong: shadowStrong, // reserved for modals and popover

  // For use ONLY in highContrast mode
  highContrast: {
    regular: shadowRegularHighContrast,
    medium: shadowMediumHighContrast,
    strong: shadowStrongHighContrast, // reserved for modals and popover
  },
};

// Units expressed in REMs
// This units are used all around for calculating the margins / gutters
// of our layouts and components, please make sure you assess the impact
// of changing this value since will affect most of our components.
export const layout = {
  gridUnit: 1,
  gutterSize: 1,
  maxWidth: "120rem",
  marginRight: "auto",
  marginLeft: "auto",
};

export const zIndex = {
  overlay: 110,
  dropdown: 120,
  modalOverlay: 130,
  globalErrorBanner: 140, // error banner should appear above everything else
};

export default {
  palette,
  fonts,
  shadows,
  zIndex,
  layout,
  unselectable,
};
