/** @jsxImportSource theme-ui */
import translate from "../../utils/translate";
import { standardFont, unselectable } from "../../styles";
import { RAW_cssValue, StylingLibCSSProperties } from "../../nessie/stylingLib";
import { KeyboardEvent, useState } from "react";

type TabsGroupOption<T> = {
  value: T;
  str: string; //| string[];
  id: string;
  tabPanelId: string;
};

type TabsGroupProps<T> = {
  value: T;
  options: TabsGroupOption<T>[];
  optionStyles?: StylingLibCSSProperties[];
  onChange?: (selected: T) => void;
  tabListStyle?: StylingLibCSSProperties;
  focusOnSelected?: boolean;
};

const TabsGroup = <T,>({
  value,
  options = [],
  optionStyles = [],
  onChange,
  tabListStyle,
  focusOnSelected,
}: TabsGroupProps<T>): JSX.Element => {
  const [currentId, setCurrentId] = useState("");
  const _handleClick = (optionValue: T) => {
    if (!onChange) {
      return;
    }

    onChange(optionValue);
  };

  return (
    <div
      sx={{ display: "flex", ...tabListStyle }}
      role={"tablist"}
      tabIndex={0}
      onKeyDown={(event) => handleTabChange(event, currentId)}
    >
      {options.map((option, index) => (
        <button
          key={option.id}
          role="tab"
          sx={{
            ...buttonBaseStyles,
            ...optionStyles[index],
          }}
          tabIndex={option.value === value ? 0 : -1}
          id={option.id}
          aria-controls={option.tabPanelId}
          onClick={() => _handleClick(option.value)}
          aria-selected={option.value === value}
          onFocus={() => setCurrentId(option.id)}
          // this optional autofocus is necessary because the WholeClassHeader componenent rerenders this component
          // on tab change making it lose the focus
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focusOnSelected && option.value === value}
        >
          {translate(option)}
        </button>
      ))}
    </div>
  );
};

export default TabsGroup;

const buttonBorderRadius = ".5rem";

const buttonBaseStyles = {
  ...standardFont,
  ...unselectable,
  display: "inline-block",
  flex: "1 1 0%",
  border: "none",
  fontWeight: 600,
  background: "transparent",
  borderColor: "dt_aqua50",
  borderStyle: "solid",
  color: "dt_aqua50",
  padding: "dt_m",
  paddingTop: "dt_s",
  paddingBottom: "dt_s",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap" as const,
  borderTopWidth: "1px",
  borderRightWidth: "1px",
  borderLeftWidth: 0,
  borderBottomWidth: "1px",
  "&[aria-selected=true]": { color: "white", backgroundColor: "dt_aqua50" },
  ":first-child": {
    borderTopWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    borderRightWidth: "1px",
    borderTopLeftRadius: RAW_cssValue(buttonBorderRadius),
    borderBottomLeftRadius: RAW_cssValue(buttonBorderRadius),
  },
  ":last-child": {
    borderTopWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    borderTopRightRadius: RAW_cssValue(buttonBorderRadius),
    borderBottomRightRadius: RAW_cssValue(buttonBorderRadius),
  },
};

/**
 * This is a general function that can be used in any tab element.
 * Just remember to make sure that the tablist element only contains tabs and nothing else
 */
export function handleTabChange(event: KeyboardEvent, currentTabId: string) {
  const currentElement = document.getElementById(currentTabId);

  if (!currentElement) return;

  const parentElement = currentElement.parentNode;
  let target;

  if (event.key === "ArrowRight" && currentElement !== parentElement?.lastChild) {
    target = currentElement.nextSibling as HTMLElement;
  } else if (event.key === "ArrowLeft" && currentElement !== parentElement?.firstChild) {
    target = currentElement.previousSibling as HTMLElement;
  }

  target?.click();
  target?.focus();
}
