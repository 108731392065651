/** @jsxImportSource theme-ui */
import * as React from "react";

import translate from "../../utils/translate";
import { translatablePropTypes, TranslatableProps } from "../../prop_shapes/translatable";

export type LabelFormElementProps = TranslatableProps & {
  labelWidthPercent: number;
  verticalLayout?: boolean;
  htmlFor?: string;
  children: React.ReactNode;
  labelStyles?: React.CSSProperties;
};

/**
 * Component for laying out an input together with a label.
 * It has two combinient layout configurations, vertical and horizontal
 */
const LabelFormElement = ({
  labelWidthPercent = 20,
  verticalLayout,
  children,
  labelStyles,
  htmlFor,
  ...otherProps
}: LabelFormElementProps): JSX.Element => {
  let labelWidth = `${labelWidthPercent}%`;
  let inputWidth = `${100 - labelWidthPercent}%`;

  const isVertical = verticalLayout;

  // When the layout is vertical we are expanding to 100%
  // the label and the input
  if (isVertical) {
    labelWidth = "100%";
    inputWidth = "100%";
  }

  return (
    <div style={styles.container}>
      <div style={{ ...styles.labelBlock, width: labelWidth, ...(isVertical && styles.labelBlockVertical) }}>
        <label style={{ ...styles.label, ...labelStyles }} htmlFor={htmlFor}>
          {translate(otherProps)}
        </label>
      </div>

      <div
        style={{ ...styles.formElementBlock, ...(isVertical && styles.formElementBlockVertical), width: inputWidth }}
      >
        {children}
      </div>
    </div>
  );
};

export default LabelFormElement;

LabelFormElement.propTypes = translatablePropTypes;
LabelFormElement.defaultProps = {
  labelWidthPercent: 20,
};

const styles = {
  container: {
    display: "block",
    position: "relative" as const,
  },
  labelBlock: {
    display: "inline-block",
    height: "100%",
    textAlign: "right" as const,
    paddingRight: "1rem",
    marginBottom: "0",
    verticalAlign: "middle",
  },
  labelBlockVertical: {
    display: "block",
    textAlign: "left" as const,
    marginBottom: "1rem",
    verticalAlign: "baseline",
  },

  formElementBlock: {
    display: "inline-block",
    verticalAlign: "middle",
    left: "20%",
  },
  formElementBlockVertical: {
    display: "block",
    verticalAlign: "baseline",
  },

  label: {
    fontSize: "1.4rem",
    fontWeight: "normal" as const,
  },
};
