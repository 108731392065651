/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type QrCodeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let QrCodeIcon: NessieFunctionComponent<QrCodeIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5.742C4 4.783 4.783 4 5.742 4H18.258C19.216 4 20 4.783 20 5.742V18.258c0 .959-.783 1.742-1.742 1.742H5.742A1.746 1.746 0 014 18.258V5.742zM5.16 16h.09V8h-.09V5.742c0-.315.267-.581.582-.581H8V6h8v-.84h2.258c.315 0 .58.267.58.582V8H18.5v8h.338v2.258a.589.589 0 01-.58.581H16V18H8v.84H5.742a.589.589 0 01-.581-.582V16zm2.542-8.252H6.651V6.697h1.052v1.051h1.05v.845h.824V6.697h1.05v1.896h1.601v2.493h-1.051V9.645H8.753v1.83h2.745v1.05h-1.396v1.668h.774v1.921h.622v1.051H9.05v-1.05h.774v-1.602H9.28v.732h-.766v.87h-.81v1.49H6.65v-1.778h.811v-.765h.766v-.869h.823v-1.667H7.702v.526H6.651v-1.578h1.051V9.645H6.651V8.593h1.051v-.845zm6.946 9.131h-1.052v-1.052h1.052v1.052zm1.808-8.286V6.696h1.051v2h-.899v.785h.9v1.05h-1.052v-.886h-.594v.887h-1.741v.806h-1.05V9.48h2.486v-.682H12.87V6.697h1.052v1.051h.89V6.697h1.05v1.051h-.164v.845h.758zm.152 5.6v1.835h.9v1.051h-1.95v-1.834h-.911v-.732h-.887v.732h-.708v.87H12v-1.053h.708v-.869h.888v-1.141h-1.052V12h2.103v2.193h1.961zm.768-1.667h-2.588v-1.052h2.588v1.052z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
QrCodeIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  QrCodeIcon = nessiePropCheckerWrapper(QrCodeIcon);
}

export default QrCodeIcon;
