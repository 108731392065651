/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type InviteIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let InviteIcon: NessieFunctionComponent<InviteIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.26 9.74a6.805 6.805 0 01-1.058 2.254c-1.748 1.288-2.898 3.358-2.898 5.658 0 1.38.414 2.622 1.104 3.726H1.736A.727.727 0 011 20.642v-2.576c0-.322.184-.598.46-.69 3.266-1.288 6.9-3.266 7.222-4.232v-.598c-.644-.69-1.196-1.656-1.518-2.806a1.766 1.766 0 01-.69-1.38c0-.414.138-.782.368-1.058v-2.3C6.842 2.61 8.268 1 11.212 1c3.036 0 4.37 1.61 4.37 4.002v2.3c.23.276.368.644.368 1.058 0 .598-.276 1.104-.69 1.38zm-2.392 8.004a5.556 5.556 0 015.566-5.566A5.556 5.556 0 0124 17.744a5.556 5.556 0 01-5.566 5.566 5.556 5.556 0 01-5.566-5.566zm6.256.69h1.84c.322 0 .644-.322.644-.644a.629.629 0 00-.644-.644h-1.84v-1.84a.629.629 0 00-.644-.644.629.629 0 00-.644.644v1.84h-1.84a.629.629 0 00-.644.644c0 .368.276.644.644.644h1.84v1.84c0 .368.276.644.644.644a.629.629 0 00.644-.644v-1.84z"
          />
          <mask maskUnits="userSpaceOnUse" x={1} y={1} width={23} height={23}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.26 9.74a6.805 6.805 0 01-1.058 2.254c-1.748 1.288-2.898 3.358-2.898 5.658 0 1.38.414 2.622 1.104 3.726H1.736A.727.727 0 011 20.642v-2.576c0-.322.184-.598.46-.69 3.266-1.288 6.9-3.266 7.222-4.232v-.598c-.644-.69-1.196-1.656-1.518-2.806a1.766 1.766 0 01-.69-1.38c0-.414.138-.782.368-1.058v-2.3C6.842 2.61 8.268 1 11.212 1c3.036 0 4.37 1.61 4.37 4.002v2.3c.23.276.368.644.368 1.058 0 .598-.276 1.104-.69 1.38zm-2.392 8.004a5.556 5.556 0 015.566-5.566A5.556 5.556 0 0124 17.744a5.556 5.556 0 01-5.566 5.566 5.556 5.556 0 01-5.566-5.566zm6.256.69h1.84c.322 0 .644-.322.644-.644a.629.629 0 00-.644-.644h-1.84v-1.84a.629.629 0 00-.644-.644.629.629 0 00-.644.644v1.84h-1.84a.629.629 0 00-.644.644c0 .368.276.644.644.644h1.84v1.84c0 .368.276.644.644.644a.629.629 0 00.644-.644v-1.84z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
InviteIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  InviteIcon = nessiePropCheckerWrapper(InviteIcon);
}

export default InviteIcon;
