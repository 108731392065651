/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import omitBy from "lodash/omitBy";
import commonInputStyles from "./Styles";
import translate from "../../utils/translate";

import { TranslatableProps } from "../../prop_shapes/translatable";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

type InputTextProps = Omit<JSX.IntrinsicElements["input"], "onChange" | "placeholder"> & {
  danger?: boolean;
  padded?: boolean;
  placeholder?: string | TranslatableProps;
  type?: "number" | "text" | "password" | "email";
  value?: string | number;
  onChange?: (value: string | number) => void;
  style?: React.CSSProperties;
  onEnter?: React.KeyboardEventHandler;
  onBackspace?: React.KeyboardEventHandler;
  onArrowLeft?: React.KeyboardEventHandler;
  onArrowRight?: React.KeyboardEventHandler;
  onArrowUp?: React.KeyboardEventHandler;
  onArrowDown?: React.KeyboardEventHandler;
  "data-test-name"?: string;
};

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(function InputText(
  {
    danger,
    style,
    padded,
    placeholder,
    type,
    value,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    onEnter,
    onBackspace,
    onArrowLeft,
    onArrowRight,
    onArrowUp,
    onArrowDown,
    ...otherProps
  }: InputTextProps,
  ref,
) {
  const [isActive, setActive] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | number = event.target.value;

    if (type === "number") {
      value = parseFloat(value);
    }

    if (onChange) onChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let handler;
    if (event.key === "Enter") handler = onEnter;
    else if (event.key === "Backspace") handler = onBackspace;
    else if (event.key === "ArrowLeft") handler = onArrowLeft;
    else if (event.key === "ArrowUp") handler = onArrowUp;
    else if (event.key === "ArrowDown") handler = onArrowDown;
    else if (event.key === "ArrowRight") handler = onArrowRight;

    handler && handler(event);
    onKeyDown && onKeyDown(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setActive(true);
    onFocus && onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setActive(false);
    onBlur && onBlur(event);
  };

  const _styles = {
    ...styles.inputText,
    ...(danger && styles.activeDanger),
    ...(!danger && isActive && styles.active),
    ...(padded && styles.padded),
    ...style,
  };

  const inputProps = omitBy(otherProps, (_, propName) => /^on[A-Z]/.test(propName)); // omit onEnter, onBackspace, etc.

  return (
    <input
      {...inputProps}
      className={`inputTextComponent ${otherProps.className}`}
      style={_styles}
      sx={inputStyle}
      ref={ref}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type={type || "text"}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={value}
      placeholder={placeholder ? translate(placeholder) : undefined}
    />
  );
});

InputText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.any,
  padded: PropTypes.any,
  danger: PropTypes.any,
  autoFocus: PropTypes.bool,
};

export default InputText;

const paddedInputPaddingRem = 1.2;

const styles: Record<string, React.CSSProperties> = {
  inputText: { ...commonInputStyles.common, ...commonInputStyles.inputText } as React.CSSProperties,
  active: commonInputStyles.active,
  activeDanger: commonInputStyles.activeDanger,
  padded: {
    padding: `${paddedInputPaddingRem}rem`,
  },
};

const placeholderStyle: ThemeUIStyleObject = {
  color: "dt_taro60",
};

const inputStyle = {
  "::placeholder": { ...placeholderStyle },
  ":-ms-input-placeholder": { ...placeholderStyle },
  "::-webkit-input-placeholder": { ...placeholderStyle },
};
