/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type ClassroomIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let ClassroomIcon: NessieFunctionComponent<ClassroomIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.466 11.24l-.138-.438c-.138-.44-.662-.672-1.17-.517l-.822.25.002-2.82c0-1.498-1.234-2.713-2.757-2.713L10.768 5c-1.524-.001-2.76 1.212-2.76 2.71l-.002 2.771-.663-.202c-.508-.155-1.032.076-1.17.516l-.139.438c-.139.44.16.922.668 1.077l1.262.385-.145 2.725a.849.849 0 00-.03.17l-.217 2.953a.839.839 0 00.786.891l.854.06a.845.845 0 00.908-.772l.13-1.795 3.738.002.129 1.796c.033.46.439.805.906.773l.854-.06a.839.839 0 00.787-.89l-.135-1.88a361.93 361.93 0 01-.152-3.924c.027-.006.055-.012.082-.02l1.338-.407c.508-.155.808-.636.67-1.076zm1.16-3.718c2.315.24 2.816 1.847 2.972 4.607-.04.03 1.966 1.707 1.246 1.876-.72.17-1.45-.15-1.45-.15s.567 6.03-1.303 2.578c-.025.002-1.564.875-3.39.062-.047-.002-.528-.213-.528-.213s-.124-2.11-.08-2.907c.003-.04 1.122-.202 1.475-.669.353-.466.343-1.11.172-1.784-.172-.674-.587-1.244-.996-1.36-.41-.115-.727.028-.727.028L17 9.11s.312-1.828 2.627-1.587zm-15.253 0c-2.314.24-2.815 1.847-2.971 4.607.04.03-1.966 1.707-1.246 1.876.72.17 1.45-.15 1.45-.15s-.567 6.03 1.303 2.578c.025.002 1.564.875 3.39.062.046-.002.528-.213.528-.213s.124-2.11.08-2.907c-.003-.04-1.122-.202-1.475-.669-.353-.466-.343-1.11-.172-1.784.172-.674.587-1.244.996-1.36.41-.115.728.028.728.028L7 9.11S6.688 7.28 4.373 7.522z"
          />
          <mask maskUnits="userSpaceOnUse" x={0} y={5} width={24} height={15}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.466 11.24l-.138-.438c-.138-.44-.662-.672-1.17-.517l-.822.25.002-2.82c0-1.498-1.234-2.713-2.757-2.713L10.768 5c-1.524-.001-2.76 1.212-2.76 2.71l-.002 2.771-.663-.202c-.508-.155-1.032.076-1.17.516l-.139.438c-.139.44.16.922.668 1.077l1.262.385-.145 2.725a.849.849 0 00-.03.17l-.217 2.953a.839.839 0 00.786.891l.854.06a.845.845 0 00.908-.772l.13-1.795 3.738.002.129 1.796c.033.46.439.805.906.773l.854-.06a.839.839 0 00.787-.89l-.135-1.88a361.93 361.93 0 01-.152-3.924c.027-.006.055-.012.082-.02l1.338-.407c.508-.155.808-.636.67-1.076zm1.16-3.718c2.315.24 2.816 1.847 2.972 4.607-.04.03 1.966 1.707 1.246 1.876-.72.17-1.45-.15-1.45-.15s.567 6.03-1.303 2.578c-.025.002-1.564.875-3.39.062-.047-.002-.528-.213-.528-.213s-.124-2.11-.08-2.907c.003-.04 1.122-.202 1.475-.669.353-.466.343-1.11.172-1.784-.172-.674-.587-1.244-.996-1.36-.41-.115-.727.028-.727.028L17 9.11s.312-1.828 2.627-1.587zm-15.253 0c-2.314.24-2.815 1.847-2.971 4.607.04.03-1.966 1.707-1.246 1.876.72.17 1.45-.15 1.45-.15s-.567 6.03 1.303 2.578c.025.002 1.564.875 3.39.062.046-.002.528-.213.528-.213s.124-2.11.08-2.907c-.003-.04-1.122-.202-1.475-.669-.353-.466-.343-1.11-.172-1.784.172-.674.587-1.244.996-1.36.41-.115.728.028.728.028L7 9.11S6.688 7.28 4.373 7.522z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ClassroomIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  ClassroomIcon = nessiePropCheckerWrapper(ClassroomIcon);
}

export default ClassroomIcon;
