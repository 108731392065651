/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PlayVideoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PlayVideoIcon: NessieFunctionComponent<PlayVideoIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.468 3.252h16.884a.38.38 0 01.36.432V20.46c0 .252-.144.432-.36.432H3.468c-.18 0-.36-.18-.36-.432V3.684c0-.252.144-.432.36-.432zm16.308 1.152H18.48v.972h1.296v-.972zm-2.808 0h-1.296v.972h1.296v-.972zm-2.88 0h-1.296v.972h1.296v-.972zm-2.952 0H9.84v.972h1.296v-.972zm-2.952 0H6.888v.972h1.296v-.972zm-2.772 0H4.116v.972h1.296v-.972zm-1.296 15.48h1.296v-.972H4.116v.972zm2.772 0h1.296v-.972H6.888v.972zm2.952 0h1.296v-.972H9.84v.972zm2.952 0h1.296v-.972h-1.296v.972zm2.916 0h1.296v-.972h-1.296v.972zm2.772 0h1.296v-.972H18.48v.972zm-2.16-2.124h3.456V6.564H4.116V17.76H16.32z"
          />
          <mask maskUnits="userSpaceOnUse" x={3} y={3} width={18} height={18}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.468 3.252h16.884a.38.38 0 01.36.432V20.46c0 .252-.144.432-.36.432H3.468c-.18 0-.36-.18-.36-.432V3.684c0-.252.144-.432.36-.432zm16.308 1.152H18.48v.972h1.296v-.972zm-2.808 0h-1.296v.972h1.296v-.972zm-2.88 0h-1.296v.972h1.296v-.972zm-2.952 0H9.84v.972h1.296v-.972zm-2.952 0H6.888v.972h1.296v-.972zm-2.772 0H4.116v.972h1.296v-.972zm-1.296 15.48h1.296v-.972H4.116v.972zm2.772 0h1.296v-.972H6.888v.972zm2.952 0h1.296v-.972H9.84v.972zm2.952 0h1.296v-.972h-1.296v.972zm2.916 0h1.296v-.972h-1.296v.972zm2.772 0h1.296v-.972H18.48v.972zm-2.16-2.124h3.456V6.564H4.116V17.76H16.32z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PlayVideoIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PlayVideoIcon = nessiePropCheckerWrapper(PlayVideoIcon);
}

export default PlayVideoIcon;
