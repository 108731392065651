/** @jsxImportSource theme-ui */
/* eslint-disable react/no-unused-prop-types */

// I don't know why react is saying there are unused prop types, but I'm just going to ignore them.

import ReactSelect, { ActionMeta, components as ReactSelectComponents } from "react-select";
import CreatableReactSelect from "react-select/creatable";

import isObject from "lodash/isObject";
import translate from "../../utils/translate";

import { NessieThemeProvider, textFieldsTextStyles, theme } from "../../nessie";
import { TranslatableProps } from "../../prop_shapes/translatable";
import { StylingLibCSSObject } from "../../nessie/stylingLib";
import React, { KeyboardEventHandler } from "react";

export type ChipSelectOptionComponent = typeof ReactSelectComponents.Option;
export type ChipSelectMenuListComponent = typeof ReactSelectComponents.MenuList;
export type ChipSelectMultiValueComponent = typeof ReactSelectComponents.MultiValue;
export type ChipSelectNoOptionsComponent = typeof ReactSelectComponents.NoOptionsMessage;

export type SelectOption<T> = { label?: string; value: T };

type MultiSelectProps<T> = {
  ["data-test-name"]?: string;
  ["aria-label"]?: string;
  autoFocus?: boolean;
  hideArrow?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  openOnFocus?: boolean;
  options?: SelectOption<T>[];
  placeholder?: string | TranslatableProps;
  tabIndex?: string;
  tether?: boolean;
  value?: SelectOption<T>[];
  filterOption?: (option: SelectOption<T>, rawInput: string) => boolean;
  menuListComponent?: ChipSelectMenuListComponent;
  multiValueComponent?: ChipSelectMultiValueComponent;
  noOptionsMessage?: ChipSelectNoOptionsComponent;
  onBlur?: () => void;
  onChange?: (value: SelectOption<T>[]) => void;
  onFocus?: () => void;
  optionComponent?: ChipSelectOptionComponent;
};

export const MultiSelect = function <T>({
  autoFocus,
  hideArrow,
  isClearable,
  isSearchable,
  options = [],
  placeholder,
  tabIndex,
  tether,
  value,
  openOnFocus,
  filterOption,
  menuListComponent,
  multiValueComponent,
  noOptionsMessage,
  optionComponent,
  onChange,
  onFocus,
  onBlur,
  ...props
}: MultiSelectProps<T>) {
  // only run placeholder through translator if it looks like it should be translated
  const translatedPlaceholder =
    placeholder && (isObject(placeholder) || /^[a-zA-Z]+\.[a-z]+/.test(placeholder))
      ? translate(placeholder)
      : placeholder;

  let customComponents: Record<string, React.FC> = {
    IndicatorSeparator: () => null,
  };

  if (optionComponent) {
    customComponents = { ...customComponents, Option: optionComponent };
  }

  if (menuListComponent) {
    customComponents = { ...customComponents, MenuList: menuListComponent };
  }

  if (hideArrow) {
    customComponents = { ...customComponents, DropdownIndicator: () => null };
  }

  if (multiValueComponent) {
    customComponents = { ...customComponents, MultiValue: multiValueComponent };
  }

  if (noOptionsMessage) {
    customComponents = { ...customComponents, NoOptionsMessage: noOptionsMessage };
  }

  const _onChange = (newValue: SelectOption<T>[], actionMeta: ActionMeta<SelectOption<T>>) => {
    if (!onChange) return;

    if (!value) return onChange(newValue);

    if (actionMeta?.action === "remove-value") {
      onChange(value?.filter((t) => t !== actionMeta?.removedValue));
    }

    if ((actionMeta?.action === "select-option" || actionMeta?.action === "deselect-option") && actionMeta?.option)
      return onChange([...value, actionMeta.option]);

    return onChange(newValue);
  };

  const _filterOption = ({ data }: { data: SelectOption<T> }, rawInput: string) => {
    return filterOption?.(data, rawInput) ?? true;
  };

  return (
    <NessieThemeProvider>
      <ReactSelect
        aria-label={props["aria-label"]}
        aria-labelledby="aria-label"
        autoFocus={autoFocus}
        components={customComponents}
        filterOption={_filterOption}
        isClearable={isClearable}
        isMulti={true}
        isSearchable={isSearchable}
        menuPortalTarget={tether ? document.body : null}
        onBlur={onBlur}
        onChange={_onChange}
        onFocus={onFocus}
        //@ts-expect-error `openOnFocus` is not a valid prop for react-select
        openOnFocus={openOnFocus}
        options={options}
        placeholder={translatedPlaceholder}
        styles={getStyles(true)}
        tabIndex={Number(tabIndex)}
        hideSelectedOptions={false}
        value={value}
        data-test-name={props["data-test-name"]}
        data-some-other-thing={"whateverj"}
      />
    </NessieThemeProvider>
  );
};

export const CreatableSelect = function ({
  autoFocus,
  isClearable,
  placeholder,
  tabIndex,
  value,
  multiValueComponent,
  onChange,
  onFocus,
  onBlur,
  ...props
}: MultiSelectProps<string>) {
  const [internalValue, setInternalValue] = React.useState(value || []);
  const [inputValue, setInputValue] = React.useState("");

  // only run placeholder through translator if it looks like it should be translated
  const translatedPlaceholder =
    placeholder && (isObject(placeholder) || /^[a-zA-Z]+\.[a-z]+/.test(placeholder))
      ? translate(placeholder)
      : placeholder;

  const handleChange = (newValue: SelectOption<string>[], actionMeta: ActionMeta<SelectOption<string>>) => {
    if (!onChange) return setInternalValue(newValue);

    if (actionMeta?.action === "remove-value") {
      const calculatedValue = internalValue?.filter((t) => t !== actionMeta?.removedValue) ?? [];
      setInternalValue(calculatedValue);
      onChange(calculatedValue);
      return;
    }

    if ((actionMeta?.action === "select-option" || actionMeta?.action === "deselect-option") && actionMeta?.option) {
      const calculatedValue = [...(internalValue ?? []), actionMeta.option];
      setInternalValue(calculatedValue);
      onChange(calculatedValue);
    }
    return onChange(newValue);
  };

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue || !internalValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
        console.group("Value Added");
        console.log(inputValue);
        console.groupEnd();
        setInputValue("");
        setInternalValue([...internalValue, { label: inputValue, value: inputValue }]);
        onChange && onChange([...internalValue, { label: inputValue, value: inputValue }]);
        event.preventDefault();
      default:
        return;
    }
  };

  let customComponents: Record<string, React.FC> = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => null,
  };

  if (multiValueComponent) {
    customComponents = { ...customComponents, MultiValue: multiValueComponent };
  }

  return (
    <NessieThemeProvider>
      <CreatableReactSelect
        aria-label={props["aria-label"]}
        aria-labelledby="aria-label"
        autoFocus={autoFocus}
        components={customComponents}
        inputValue={inputValue}
        isClearable={isClearable}
        isMulti={true}
        onBlur={onBlur}
        menuIsOpen={false}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onInputChange={handleInputChange}
        onFocus={onFocus}
        placeholder={translatedPlaceholder}
        styles={getStyles(false)}
        tabIndex={Number(tabIndex)}
        value={internalValue}
        data-test-name={props["data-test-name"]}
      />
    </NessieThemeProvider>
  );
};

const SELECT_HEIGHT = "60px";

const getStyles = (
  wrap: boolean,
): Record<string, (provided: StylingLibCSSObject, state: { isFocused: boolean }) => StylingLibCSSObject> => ({
  container: (provided) => ({
    ...provided,
    minWidth: "100%",
  }),
  control: (_provided, state) => {
    return {
      ...textFieldsTextStyles,
      border: "2px solid",
      borderColor: state.isFocused ? theme.colors.dt_aqua50 : theme.colors.dt_taro30,
      borderRadius: theme.radii.dt_radius_l,
      minHeight: SELECT_HEIGHT,
      color: theme.colors.dt_taro90,
      boxShadow: null,
      boxSizing: "border-box",
      cursor: "default",
      display: "flex",
      justifyContent: "space-between",
      label: "control",
      transition: "all 100ms",
      minWidth: "100%",
      paddingLeft: theme.space.dt_l,
      backgroundColor: theme.colors.dt_white,
    };
  },
  input: () => ({
    color: theme.colors.dt_taro90,
    wordWrap: "break-word",
    wordBreak: "break-all",
  }),
  valueContainer: () => ({
    display: "inline-flex",
    flexWrap: wrap ? "wrap" : "nowrap",
    overflowX: wrap ? "hidden" : "auto",
    overflowY: "hidden",
    alignItems: "center",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.colors.dt_taro50,
    margin: "0",
  }),
  dropdownIndicator: () => ({
    paddingRight: theme.space.dt_l,
    color: theme.colors.dt_taro40,
    lineHeight: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    border: "2px solid",
    borderColor: "#D3D7EC",
    boxSizing: "border-box",
    marginTop: "7px",
    boxShadow: "0px 6px 0px rgba(45, 64, 150, 0.06)",
    borderRadius: theme.radii.dt_radius_s,
    overflow: "hidden",
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: theme.radii.dt_radius_s,
    padding: 0,
  }),
  option: () => ({
    color: theme.colors.dt_taro50,
    fontSize: "15px",
    fontWeight: 600,
    paddingLeft: theme.space.dt_m,
    paddingTop: theme.space.dt_s,
    paddingBottom: theme.space.dt_s,
    ":hover": {
      backgroundColor: theme.colors.dt_taro20,
    },
  }),
  singleValue: () => ({
    marginBottom: 0,
    marginLeft: 0,
  }),
});
