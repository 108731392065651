/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type FullscreenIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let FullscreenIcon: NessieFunctionComponent<FullscreenIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.6 5.25A1.35 1.35 0 005.25 6.6v1.463a1.125 1.125 0 01-2.25 0V6.6A3.6 3.6 0 016.6 3h1.463a1.125 1.125 0 010 2.25H6.6zm8.213-1.125c0-.621.503-1.125 1.124-1.125H17.4A3.6 3.6 0 0121 6.6v1.463a1.125 1.125 0 01-2.25 0V6.6a1.35 1.35 0 00-1.35-1.35h-1.462a1.125 1.125 0 01-1.126-1.125zM4.124 14.813c.621 0 1.125.503 1.125 1.124V17.4c0 .746.604 1.35 1.35 1.35h1.463a1.125 1.125 0 010 2.25H6.6A3.6 3.6 0 013 17.4v-1.462c0-.622.504-1.126 1.125-1.126zm15.75 0c.621 0 1.125.503 1.125 1.124V17.4a3.6 3.6 0 01-3.6 3.6h-1.462a1.125 1.125 0 010-2.25H17.4a1.35 1.35 0 001.35-1.35v-1.462c0-.622.504-1.126 1.125-1.126z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
FullscreenIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  FullscreenIcon = nessiePropCheckerWrapper(FullscreenIcon);
}

export default FullscreenIcon;
