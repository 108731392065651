/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PhoneIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PhoneIcon: NessieFunctionComponent<PhoneIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.472 1H7.936C6.88 1 6 1.88 6 2.936v18.128C6 22.12 6.88 23 7.936 23h8.536c1.056 0 1.936-.88 1.936-1.936V2.936c0-1.056-.88-1.936-1.936-1.936zm-4.268 20.9c-.66 0-1.144-.528-1.144-1.144 0-.66.528-1.144 1.144-1.144.66 0 1.144.528 1.144 1.144.044.616-.484 1.144-1.144 1.144zm4.884-3.3H7.32V4.124h9.768V18.6z"
          />
          <mask maskUnits="userSpaceOnUse" x={6} y={1} width={13} height={22}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.472 1H7.936C6.88 1 6 1.88 6 2.936v18.128C6 22.12 6.88 23 7.936 23h8.536c1.056 0 1.936-.88 1.936-1.936V2.936c0-1.056-.88-1.936-1.936-1.936zm-4.268 20.9c-.66 0-1.144-.528-1.144-1.144 0-.66.528-1.144 1.144-1.144.66 0 1.144.528 1.144 1.144.044.616-.484 1.144-1.144 1.144zm4.884-3.3H7.32V4.124h9.768V18.6z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PhoneIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PhoneIcon = nessiePropCheckerWrapper(PhoneIcon);
}

export default PhoneIcon;
