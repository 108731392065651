/** @jsxImportSource theme-ui */
import { useState } from "react";
import * as React from "react";

import { fonts, shadows, palette } from "../commonStyles";
import translate from "../../utils/translate";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

type TextAreaProps = React.ComponentPropsWithRef<"textarea">;

/**
 * Styled textarea with focus/blur states
 */
const TextArea = ({ placeholder, style, ...props }: TextAreaProps): JSX.Element => {
  const [active, setActive] = useState(false);

  const onFocus = () => {
    setActive(true);
  };

  const onBlur = () => {
    setActive(false);
  };

  return (
    <textarea
      {...props}
      placeholder={translate(placeholder)}
      style={{
        ...textareaStyles.base,
        ...(active && textareaStyles.active),
        ...style,
      }}
      sx={textareaStyle}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
export default TextArea;

const inputLineHeight = 2.2;
const inputVerticalPadding = 0.6;

const textareaStyles = {
  base: {
    width: "100%",

    fontFamily: fonts.family,
    fontSize: fonts.sizeInputs,
    color: "black",
    fontWeight: "normal" as const,
    lineHeight: `${inputLineHeight}rem`,

    padding: `${inputVerticalPadding}rem 1rem`,

    borderTopRightRadius: "0.5rem",
    borderTopLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",

    boxShadow: `${shadows.medium} inset`,
    border: `1px solid ${palette.gray20}`,
    outline: "none",

    resize: "none" as const,
    margin: 0,
    textAlign: "left" as const,
  },
  active: {
    outline: 0,
    border: `1px solid ${palette.dojoBlue}`,
  },
};

const placeholderStyle: ThemeUIStyleObject = {
  color: "dt_taro40",
};

const textareaStyle = {
  "::placeholder": { ...placeholderStyle },
  ":-ms-input-placeholder": { ...placeholderStyle },
  "::-webkit-input-placeholder": { ...placeholderStyle },
};
