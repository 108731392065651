/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type CameraIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let CameraIcon: NessieFunctionComponent<CameraIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.895 7.174c1.469 0 1.945 1.27 1.984 1.945v8.213c0 1.429-1.27 1.905-1.944 1.984H4.063c-.635 0-1.15-.198-1.508-.595-.486-.524-.478-1.19-.476-1.396V9.357c0-1.627 1.39-2.103 2.143-2.143l3.293-.04c.16-2.817 1.667-3.055 1.826-3.055h5.436c1.666 0 2.261 1.746 2.38 3.055h2.738zM7.238 13.087a4.648 4.648 0 004.642 4.642 4.648 4.648 0 004.643-4.642 4.648 4.648 0 00-4.643-4.643 4.648 4.648 0 00-4.642 4.643zm11.11-1.746a.88.88 0 01-.873-.873.88.88 0 01.873-.873.88.88 0 01.873.873.88.88 0 01-.873.873zm-9.523 1.706a3.057 3.057 0 016.11 0 3.057 3.057 0 01-3.055 3.055 3.057 3.057 0 01-3.055-3.055z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={4} width={20} height={16}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.895 7.174c1.469 0 1.945 1.27 1.984 1.945v8.213c0 1.429-1.27 1.905-1.944 1.984H4.063c-.635 0-1.15-.198-1.508-.595-.486-.524-.478-1.19-.476-1.396V9.357c0-1.627 1.39-2.103 2.143-2.143l3.293-.04c.16-2.817 1.667-3.055 1.826-3.055h5.436c1.666 0 2.261 1.746 2.38 3.055h2.738zM7.238 13.087a4.648 4.648 0 004.642 4.642 4.648 4.648 0 004.643-4.642 4.648 4.648 0 00-4.643-4.643 4.648 4.648 0 00-4.642 4.643zm11.11-1.746a.88.88 0 01-.873-.873.88.88 0 01.873-.873.88.88 0 01.873.873.88.88 0 01-.873.873zm-9.523 1.706a3.057 3.057 0 016.11 0 3.057 3.057 0 01-3.055 3.055 3.057 3.057 0 01-3.055-3.055z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CameraIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  CameraIcon = nessiePropCheckerWrapper(CameraIcon);
}

export default CameraIcon;
