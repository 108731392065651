/** @jsxImportSource theme-ui */
import React, { useEffect } from "react";

import Modal from "react-modal";

import { ClassNames, GlobalCSS } from "../../nessie/stylingLib";

import styles from "./Styles";
import { modalPropTypes } from "./ModalPropTypes";

import logEvent from "../../utils/log_event";
import { isClient, isTesting } from "../../utils/env";

const MODAL_OPEN_CLASSNAME = "modal-open";

const ADD_SCROLLBAR_CLASSNAME = "modal-open-scrollable";

const REACT_APPLICATION_ID = "__next";

let mainAppElement: HTMLElement | null = null;
let bodyElement: HTMLElement | null = null;
if (isClient) {
  mainAppElement =
    document.getElementById(REACT_APPLICATION_ID) ||
    // fallback to "root" element Id to support StoryBook
    document.getElementById("root");
  bodyElement = document.body;
}

if (mainAppElement && typeof Modal.setAppElement === "function") {
  Modal.setAppElement(mainAppElement);
}

/**
 * Base modal wrapper around our custom version of react-modal
 * Provide the base configuration we need for our modals.
 */
type BaseModalProps = Omit<ReactModal.Props, "isOpen" | "style" | "shouldCloseOnOverlayClick" | "onRequestClose"> & {
  "data-test-name"?: string;
  amplitudeKey?: string;
  label: string;
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  overlayStyle?: React.CSSProperties;
  requestHideOnOverlayClick?: boolean;
  style?: React.CSSProperties;
  onRequestHide?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const BaseModal = ({
  "data-test-name": dataTestName,
  amplitudeKey,
  children,
  className,
  overlayStyle,
  requestHideOnOverlayClick,
  style,
  isOpen = true,
  label,
  aria,
  onRequestHide,
  ...propsToPass
}: BaseModalProps): JSX.Element => {
  useEffect(() => {
    // Figure out if the modal is open or closed and ensure the proper side effects
    if (isOpen === false) {
      modalIsNotOpen(amplitudeKey);
    } else {
      modalIsOpen(amplitudeKey);
    }

    return () => modalIsNotOpen(amplitudeKey);
  }, [amplitudeKey, isOpen]);

  const combinedOverlayStyles = {
    ...styles.modalBackdrop,
    ...overlayStyle,
  };
  const combinedContentStyles = {
    ...styles.modal,
    ...style,
  };

  return (
    // as of React 16, events bubble up through Portals, meaning clicks on the modal body will
    // bubble up to React parents, which might be buttons. To prevent triggering parent buttons
    // unintentionally, we stopPropagation here.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <ClassNames>
        {({ css, cx }) => (
          <Modal
            {...propsToPass}
            data={{ "test-name": dataTestName }}
            isOpen={isOpen == null ? true : isOpen}
            // pass true here so that react-modal calls our onRequestClose handler on every overlay click.
            // our onRequestClose handler will perform the shouldCloseOnOverlayClick logic.
            shouldCloseOnOverlayClick={true}
            // as of React 16, events bubble up through Portals, meaning clicks on the modal overlay will
            // bubble up to React parents, which might be buttons. To prevent triggering parent buttons
            // unintentionally, we stopPropagation here.
            onRequestClose={(e: React.MouseEvent | React.KeyboardEvent) => {
              e.stopPropagation();

              // allow modal to be closed when the user uses the escape key
              if ((e as React.KeyboardEvent)?.key === "Escape" && onRequestHide) {
                onRequestHide(e);
              }

              if (!!requestHideOnOverlayClick && onRequestHide) {
                onRequestHide(e);
              }
            }}
            className={cx(css(combinedContentStyles), className)}
            overlayClassName={css(combinedOverlayStyles)}
            contentLabel={label}
            aria={{ modal: true, ...aria }}
          >
            {children}
          </Modal>
        )}
      </ClassNames>
      <GlobalCSS
        styles={{
          ".ReactModal__Overlay": {
            zIndex: 130,
            transition: "opacity 250ms 0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            height: "100%",
            overflowX: "auto",
          },
          "body.modal-open": {
            overflow: "hidden",
          },
          "body.modal-open-scrollable.modal-open .ReactModal__Overlay": {
            overflowY: "hidden",
          },
          ".PhotoModal-ExpandedPhotoOverlay": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        }}
      />
    </div>
  );
};
BaseModal.propTypes = modalPropTypes;

export default BaseModal;

// Open modals need the following things to happen:
// - Make sure the body has the proper class to keep it from scrolling
// - Make sure the modal is at the front of the open modals array, which is
//   used to handle the escape key.

function modalIsOpen(amplitudeKey?: string) {
  if (bodyElement) {
    bodyElement.classList.add(MODAL_OPEN_CLASSNAME);
    if (mainAppElement) {
      const { height } = mainAppElement.getBoundingClientRect();
      if (window && window.innerHeight < height) {
        bodyElement.classList.add(ADD_SCROLLBAR_CLASSNAME);
      }
    } else if (!isTesting) {
      console.warn("mainAppElement needs to be defined for modal to work correctly");
    }

    if (amplitudeKey) {
      logEvent(`${amplitudeKey}.open`);
    }
  }
}

function modalIsNotOpen(amplitudeKey?: string) {
  if (bodyElement) {
    bodyElement.classList.remove(MODAL_OPEN_CLASSNAME);
    bodyElement.classList.remove(ADD_SCROLLBAR_CLASSNAME);

    if (amplitudeKey) {
      logEvent(`${amplitudeKey}.close`);
    }
  }
}
