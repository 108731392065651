/** @jsxImportSource theme-ui */
import { ColoredCircle } from "../..";
import { CheckmarkIcon } from "../../nessie/icons";
import { StylingLibCSSObject } from "../../nessie/stylingLib";
import translate from "../../utils/translate";

interface IProps {
  selected?: boolean;
  unselected?: boolean;
  absent?: boolean;
  bubbleStyle?: StylingLibCSSObject;
  ["data-test-name"]?: string;
}
const SelectedIcon = ({ selected, unselected, absent, ["data-test-name"]: dataTestName, bubbleStyle }: IProps) => {
  let type, contents, circleLabel;

  // selected: show blue check
  if (selected) {
    contents = (
      <div sx={{ lineHeight: 0 }}>
        <CheckmarkIcon color="white" />
      </div>
    );
    type = "primary";
    circleLabel = translate({ str: "dojo.teacher_web:common.selected", fallback: "selected" });

    // not selected: show gray check
  } else if (unselected) {
    // but, no bubble if absent
    if (absent) return null;

    contents = (
      <div sx={{ lineHeight: 0 }}>
        <CheckmarkIcon color="white" sx={{ display: "block", height: "24px", width: "24px" }} />
      </div>
    );
    type = "secondary";
    circleLabel = translate({ str: "dojo.teacher_web:common.unselected", fallback: "unselected" });
  }

  return (
    <ColoredCircle
      sx={bubbleStyle}
      data-test-name={dataTestName}
      type={type as "success" | "danger" | "warning" | "primary" | "secondary"}
      aria-label={circleLabel}
    >
      {contents}
    </ColoredCircle>
  );
};

export default SelectedIcon;
