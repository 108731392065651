/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type PortraitFemaleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let PortraitFemaleIcon: NessieFunctionComponent<PortraitFemaleIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.53 17.494c-2.891-1.12-6.108-1.048-6.361-1.916v-1.3c2.891 0 4.048-.94 4.048-.94-1.193-.687-1.59-5.458-1.59-5.458 0-1.446-.615-2.784-1.59-3.651-.362-.434-.868-.76-1.519-.94A4.398 4.398 0 0011.964 3c-.579 0-1.12.108-1.627.325a3.86 3.86 0 00-1.3.76A5.026 5.026 0 007.3 7.88s-.397 4.77-1.59 5.457c0 0 1.156.94 4.048.94v1.301c-.253.868-3.47.796-6.361 1.916a.633.633 0 00-.398.614v2.277c0 .362.29.651.65.651h16.7c.36 0 .65-.289.65-.65v-2.278a.76.76 0 00-.47-.614z"
          />
          <mask maskUnits="userSpaceOnUse" x={3} y={3} width={18} height={19}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.53 17.494c-2.891-1.12-6.108-1.048-6.361-1.916v-1.3c2.891 0 4.048-.94 4.048-.94-1.193-.687-1.59-5.458-1.59-5.458 0-1.446-.615-2.784-1.59-3.651-.362-.434-.868-.76-1.519-.94A4.398 4.398 0 0011.964 3c-.579 0-1.12.108-1.627.325a3.86 3.86 0 00-1.3.76A5.026 5.026 0 007.3 7.88s-.397 4.77-1.59 5.457c0 0 1.156.94 4.048.94v1.301c-.253.868-3.47.796-6.361 1.916a.633.633 0 00-.398.614v2.277c0 .362.29.651.65.651h16.7c.36 0 .65-.289.65-.65v-2.278a.76.76 0 00-.47-.614z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PortraitFemaleIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  PortraitFemaleIcon = nessiePropCheckerWrapper(PortraitFemaleIcon);
}

export default PortraitFemaleIcon;
