/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type CheckIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let CheckIcon: NessieFunctionComponent<CheckIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.075 4.925c-3.9-3.9-10.21-3.9-14.15 0-3.9 3.9-3.9 10.21 0 14.15 3.9 3.9 10.21 3.9 14.15 0 3.9-3.94 3.9-10.25 0-14.15zm-2.21 4.02l-5.669 7.437c-.201.241-.442.362-.764.402h-.04a.99.99 0 01-.764-.362l-2.412-2.854a1.122 1.122 0 01-.241-.724c.04-.241.16-.482.362-.683.402-.322 1.045-.282 1.367.12l1.648 1.93 4.944-6.432c.322-.402.965-.483 1.367-.201.201.16.322.402.362.643.04.241 0 .523-.16.724z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.075 4.925c-3.9-3.9-10.21-3.9-14.15 0-3.9 3.9-3.9 10.21 0 14.15 3.9 3.9 10.21 3.9 14.15 0 3.9-3.94 3.9-10.25 0-14.15zm-2.21 4.02l-5.669 7.437c-.201.241-.442.362-.764.402h-.04a.99.99 0 01-.764-.362l-2.412-2.854a1.122 1.122 0 01-.241-.724c.04-.241.16-.482.362-.683.402-.322 1.045-.282 1.367.12l1.648 1.93 4.944-6.432c.322-.402.965-.483 1.367-.201.201.16.322.402.362.643.04.241 0 .523-.16.724z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  CheckIcon = nessiePropCheckerWrapper(CheckIcon);
}

export default CheckIcon;
