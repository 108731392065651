const TINY = 8;
const SMALL = 12;
const STANDARD = 16;
const LARGE = 24;

export const noPadding = { padding: 0 };
export const noPaddingTop = { paddingTop: 0 };
export const noPaddingLeft = { paddingLeft: 0 };
export const noPaddingRight = { paddingRight: 0 };
export const noPaddingBottom = { paddingBottom: 0 };
export const noPaddingVertical = { paddingTop: 0, paddingBottom: 0 };
export const noPaddingHorizontal = { paddingLeft: 0, paddingRight: 0 };

export const padding = { padding: STANDARD };
export const paddingTop = { paddingTop: STANDARD };
export const paddingLeft = { paddingLeft: STANDARD };
export const paddingRight = { paddingRight: STANDARD };
export const paddingBottom = { paddingBottom: STANDARD };
export const paddingVertical = { paddingTop: STANDARD, paddingBottom: STANDARD };
export const paddingHorizontal = { paddingLeft: STANDARD, paddingRight: STANDARD };

export const paddingTiny = { padding: TINY };
export const paddingTopTiny = { paddingTop: TINY };
export const paddingLeftTiny = { paddingLeft: TINY };
export const paddingRightTiny = { paddingRight: TINY };
export const paddingBottomTiny = { paddingBottom: TINY };
export const paddingVerticalTiny = { paddingTop: TINY, paddingBottom: TINY };
export const paddingHorizontalTiny = { paddingLeft: TINY, paddingRight: TINY };

export const paddingSmall = { padding: SMALL };
export const paddingTopSmall = { paddingTop: SMALL };
export const paddingLeftSmall = { paddingLeft: SMALL };
export const paddingRightSmall = { paddingRight: SMALL };
export const paddingBottomSmall = { paddingBottom: SMALL };
export const paddingVerticalSmall = { paddingTop: SMALL, paddingBottom: SMALL };
export const paddingHorizontalSmall = { paddingLeft: SMALL, paddingRight: SMALL };

export const paddingLarge = { padding: LARGE };
export const paddingTopLarge = { paddingTop: LARGE };
export const paddingLeftLarge = { paddingLeft: LARGE };
export const paddingRightLarge = { paddingRight: LARGE };
export const paddingBottomLarge = { paddingBottom: LARGE };
export const paddingVerticalLarge = { paddingTop: LARGE, paddingBottom: LARGE };
export const paddingHorizontalLarge = { paddingLeft: LARGE, paddingRight: LARGE };

export const noMargin = { margin: 0 };
export const noMarginTop = { marginTop: 0 };
export const noMarginLeft = { marginLeft: 0 };
export const noMarginRight = { marginRight: 0 };
export const noMarginBottom = { marginBottom: 0 };
export const noMarginVertical = { marginTop: 0, marginBottom: 0 };
export const noMarginHorizontal = { marginLeft: 0, marginRight: 0 };

export const margin = { margin: STANDARD };
export const marginTop = { marginTop: STANDARD };
export const marginLeft = { marginLeft: STANDARD };
export const marginRight = { marginRight: STANDARD };
export const marginBottom = { marginBottom: STANDARD };
export const marginVertical = { marginTop: STANDARD, marginBottom: STANDARD };
export const marginHorizontal = { marginLeft: STANDARD, marginRight: STANDARD };

export const marginTiny = { margin: TINY };
export const marginTopTiny = { marginTop: TINY };
export const marginLeftTiny = { marginLeft: TINY };
export const marginRightTiny = { marginRight: TINY };
export const marginBottomTiny = { marginBottom: TINY };
export const marginVerticalTiny = { marginTop: TINY, marginBottom: TINY };
export const marginHorizontalTiny = { marginLeft: TINY, marginRight: TINY };

export const marginSmall = { margin: SMALL };
export const marginTopSmall = { marginTop: SMALL };
export const marginLeftSmall = { marginLeft: SMALL };
export const marginRightSmall = { marginRight: SMALL };
export const marginBottomSmall = { marginBottom: SMALL };
export const marginVerticalSmall = { marginTop: SMALL, marginBottom: SMALL };
export const marginHorizontalSmall = { marginLeft: SMALL, marginRight: SMALL };

export const marginLarge = { margin: LARGE };
export const marginTopLarge = { marginTop: LARGE };
export const marginLeftLarge = { marginLeft: LARGE };
export const marginRightLarge = { marginRight: LARGE };
export const marginBottomLarge = { marginBottom: LARGE };
export const marginVerticalLarge = { marginTop: LARGE, marginBottom: LARGE };
export const marginHorizontalLarge = { marginLeft: LARGE, marginRight: LARGE };
