/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type MoviesIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let MoviesIcon: NessieFunctionComponent<MoviesIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.983 19.81c-.312-1.471-1.07-2.541-2.319-3.21-1.471-.758-3.389-.803-5.128-.535a8.006 8.006 0 001.695-4.95A8.088 8.088 0 009.115 3C4.612 3 1 6.656 1 11.115a8.088 8.088 0 008.115 8.116 8.127 8.127 0 003.612-.847c2.051-.67 5.306-1.338 7.18-.313.802.402 1.248 1.115 1.47 2.096.09.401.402.669.803.669h.179c.446-.178.713-.58.624-1.025zM4.656 13.078a1.98 1.98 0 01-1.962-1.962 1.98 1.98 0 011.962-1.962 1.98 1.98 0 011.962 1.962 1.98 1.98 0 01-1.962 1.962zm4.46 4.46a1.98 1.98 0 01-1.963-1.963 1.98 1.98 0 011.962-1.962 1.98 1.98 0 011.962 1.962 1.98 1.98 0 01-1.962 1.962zm-.848-6.422c0-.49.401-.891.892-.891.49 0 .892.4.892.891 0 .49-.402.892-.892.892-.535 0-.892-.401-.892-.892zm.847-2.541a1.98 1.98 0 01-1.962-1.962A1.98 1.98 0 019.115 4.65a1.98 1.98 0 011.962 1.962 1.95 1.95 0 01-1.962 1.962zm4.46 4.503a1.98 1.98 0 01-1.963-1.962 1.98 1.98 0 011.962-1.962 1.98 1.98 0 011.962 1.962 1.95 1.95 0 01-1.962 1.962z"
          />
          <mask maskUnits="userSpaceOnUse" x={1} y={3} width={22} height={18}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.983 19.81c-.312-1.471-1.07-2.541-2.319-3.21-1.471-.758-3.389-.803-5.128-.535a8.006 8.006 0 001.695-4.95A8.088 8.088 0 009.115 3C4.612 3 1 6.656 1 11.115a8.088 8.088 0 008.115 8.116 8.127 8.127 0 003.612-.847c2.051-.67 5.306-1.338 7.18-.313.802.402 1.248 1.115 1.47 2.096.09.401.402.669.803.669h.179c.446-.178.713-.58.624-1.025zM4.656 13.078a1.98 1.98 0 01-1.962-1.962 1.98 1.98 0 011.962-1.962 1.98 1.98 0 011.962 1.962 1.98 1.98 0 01-1.962 1.962zm4.46 4.46a1.98 1.98 0 01-1.963-1.963 1.98 1.98 0 011.962-1.962 1.98 1.98 0 011.962 1.962 1.98 1.98 0 01-1.962 1.962zm-.848-6.422c0-.49.401-.891.892-.891.49 0 .892.4.892.891 0 .49-.402.892-.892.892-.535 0-.892-.401-.892-.892zm.847-2.541a1.98 1.98 0 01-1.962-1.962A1.98 1.98 0 019.115 4.65a1.98 1.98 0 011.962 1.962 1.95 1.95 0 01-1.962 1.962zm4.46 4.503a1.98 1.98 0 01-1.963-1.962 1.98 1.98 0 011.962-1.962 1.98 1.98 0 011.962 1.962 1.95 1.95 0 01-1.962 1.962z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MoviesIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  MoviesIcon = nessiePropCheckerWrapper(MoviesIcon);
}

export default MoviesIcon;
