/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type StudentIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let StudentIcon: NessieFunctionComponent<StudentIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.388 9.803c-.207-.667-.988-1.02-1.746-.785l-1.226.379.003-4.28a4.113 4.113 0 00-4.112-4.114L10.11 1a4.114 4.114 0 00-4.117 4.11l-.002 4.206-.989-.306c-.757-.235-1.539.115-1.746.783l-.206.665c-.207.667.238 1.399.996 1.634l1.882.584-.216 4.133a1.307 1.307 0 00-.046.257l-.322 4.482A1.266 1.266 0 006.517 22.9l1.274.091a1.266 1.266 0 001.353-1.172l.196-2.724 5.574.003.192 2.725a1.266 1.266 0 001.352 1.173l1.274-.09a1.265 1.265 0 001.174-1.352l-.202-2.85c-.07-1.478-.165-4.149-.226-5.955.04-.01.08-.02.121-.032l1.997-.617c.757-.234 1.204-.965.997-1.633l-.205-.665z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
StudentIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  StudentIcon = nessiePropCheckerWrapper(StudentIcon);
}

export default StudentIcon;
