/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type HelpIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let HelpIcon: NessieFunctionComponent<HelpIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.86 2A9.853 9.853 0 002 11.86a9.853 9.853 0 009.86 9.86 9.853 9.853 0 009.86-9.86c0-5.451-4.449-9.86-9.86-9.86zm1.202 15.271c0 .28-.12.521-.36.761-.24.2-.561.321-.922.321-.401 0-.722-.12-.962-.32-.24-.241-.4-.482-.4-.762v-.481c0-.28.12-.521.4-.761.24-.201.561-.321.962-.321.4 0 .721.12.922.32.24.24.36.481.36.762v.481zm2.646-7.255c-.16.32-.36.561-.601.802-.24.2-.481.4-.762.601-.24.16-.48.36-.721.521-.2.2-.401.401-.522.601-.12.2-.2.481-.2.762v.32c0 .201-.08.401-.28.642-.2.2-.481.32-.802.36h-.08c-.32 0-.601-.08-.802-.28-.2-.2-.32-.44-.32-.681 0-.562.08-1.043.24-1.443.16-.401.36-.722.561-1.002.24-.28.481-.521.762-.722.24-.2.48-.36.721-.56.2-.161.4-.362.521-.562.12-.16.2-.4.2-.641 0-.441-.12-.722-.36-.922-.24-.2-.561-.32-1.002-.32-.28 0-.521 0-.722.04-.2.04-.36.08-.52.16-.161.08-.281.2-.442.32-.12.12-.28.32-.44.521-.12.2-.321.32-.602.441-.28.08-.56.08-.882-.08a.943.943 0 01-.52-.521c-.04-.2-.04-.4 0-.641.04-.2.16-.401.32-.682.2-.28.441-.52.762-.761.32-.24.721-.481 1.242-.642.481-.16 1.082-.28 1.764-.28.641 0 1.242.08 1.683.28.481.2.842.481 1.162.802.281.32.481.681.642 1.122.12.401.2.842.2 1.243.04.52-.04.882-.2 1.202z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.86 2A9.853 9.853 0 002 11.86a9.853 9.853 0 009.86 9.86 9.853 9.853 0 009.86-9.86c0-5.451-4.449-9.86-9.86-9.86zm1.202 15.271c0 .28-.12.521-.36.761-.24.2-.561.321-.922.321-.401 0-.722-.12-.962-.32-.24-.241-.4-.482-.4-.762v-.481c0-.28.12-.521.4-.761.24-.201.561-.321.962-.321.4 0 .721.12.922.32.24.24.36.481.36.762v.481zm2.646-7.255c-.16.32-.36.561-.601.802-.24.2-.481.4-.762.601-.24.16-.48.36-.721.521-.2.2-.401.401-.522.601-.12.2-.2.481-.2.762v.32c0 .201-.08.401-.28.642-.2.2-.481.32-.802.36h-.08c-.32 0-.601-.08-.802-.28-.2-.2-.32-.44-.32-.681 0-.562.08-1.043.24-1.443.16-.401.36-.722.561-1.002.24-.28.481-.521.762-.722.24-.2.48-.36.721-.56.2-.161.4-.362.521-.562.12-.16.2-.4.2-.641 0-.441-.12-.722-.36-.922-.24-.2-.561-.32-1.002-.32-.28 0-.521 0-.722.04-.2.04-.36.08-.52.16-.161.08-.281.2-.442.32-.12.12-.28.32-.44.521-.12.2-.321.32-.602.441-.28.08-.56.08-.882-.08a.943.943 0 01-.52-.521c-.04-.2-.04-.4 0-.641.04-.2.16-.401.32-.682.2-.28.441-.52.762-.761.32-.24.721-.481 1.242-.642.481-.16 1.082-.28 1.764-.28.641 0 1.242.08 1.683.28.481.2.842.481 1.162.802.281.32.481.681.642 1.122.12.401.2.842.2 1.243.04.52-.04.882-.2 1.202z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
HelpIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  HelpIcon = nessiePropCheckerWrapper(HelpIcon);
}

export default HelpIcon;
