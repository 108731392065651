/** @jsxImportSource theme-ui */
import * as React from "react";
import { shadows } from "../commonStyles";
import { handleActionKeyDown } from "../../utils/keyboard";
import { UnstyledButton } from "../buttons";
import { RAW_cssValue } from "../../nessie/stylingLib";

interface CardTileButtonProps {
  highContrast?: boolean;
  tileStyle?: React.CSSProperties;
  "data-test-name"?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  "aria-label"?: string;
  "aria-pressed"?: boolean;
  title?: string;
}

export const CardTileButton = ({
  highContrast,
  tileStyle,
  title,
  "aria-label": ariaLabel,
  "aria-pressed": ariaPressed,
  children,
  "data-test-name": dataTestName,
  onClick,
  disabled,
  className,
}: CardTileButtonProps) => {
  return (
    <UnstyledButton
      disabled={disabled}
      aria-label={ariaLabel}
      aria-pressed={ariaPressed}
      title={title}
      onKeyDown={handleActionKeyDown({ onAction: onClick })}
      onClick={onClick}
      data-test-name={dataTestName}
      style={tileStyle}
      className={className}
      sx={{
        textAlign: "center",
        fontSize: "1.2rem",
        position: "relative",
        height: "8rem",
        width: "16rem",
        marginX: "dt_m",
        marginY: "dt_s",
        backgroundColor: "white",
        borderRadius: RAW_cssValue("5px"),
        display: "inline-block",
        boxShadow: highContrast ? shadows.highContrast.regular : shadows.regular,
        ":hover": {
          boxShadow: highContrast ? shadows.highContrast.medium : shadows.medium,
        },
        ":disabled": {
          WebkitFilter: "grayscale(100%)",
          filter: "grayscale(100%)",
          cursor: "default",
          opacity: 0.5,
        },
      }}
    >
      {children}
    </UnstyledButton>
  );
};
