/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type SelectIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let SelectIcon: NessieFunctionComponent<SelectIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.512 2.14c.332.04.572.07.72.1.336.048.384.288.336.336 0 .144-.096.24-.192.336 0 .044-.08.088-.502.318l-.122.066c-1.632.96-3.6 2.496-5.808 4.608-2.16 2.112-4.176 4.416-5.904 6.912-.72 1.056-1.152 1.632-1.392 1.824-.144.144-.336.192-.672.192-.288 0-.672-.048-1.152-.144-.96-.192-1.152-.384-1.248-.528-.096-.144-.288-.576-.528-1.248-.432-1.104-.912-2.16-1.536-3.216-.384-.576-.48-.96-.432-1.248.096-.384.432-.672 1.008-.912.576-.24 1.008-.336 1.344-.24.384.096.864.432 1.344 1.008.432.528.864 1.344 1.248 2.544a68.898 68.898 0 013.264-3.984 47.107 47.107 0 013.792-3.792c1.296-1.152 2.304-1.968 2.928-2.352.672-.384 1.104-.624 1.344-.672.24-.048.72-.048 1.44 0 .275.037.515.066.72.092zM1.776 19.232h13.632v-7.536c.576-.624 1.152-1.248 1.728-1.824v9.36a1.72 1.72 0 01-1.728 1.728H1.776a1.72 1.72 0 01-1.728-1.728V5.6c0-.96.768-1.728 1.728-1.728h13.488l-.144.144a16.94 16.94 0 00-1.007.938c-.22.217-.443.435-.673.646H1.776v13.632z"
          />
          <mask maskUnits="userSpaceOnUse" x={0} y={2} width={24} height={19}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.512 2.14c.332.04.572.07.72.1.336.048.384.288.336.336 0 .144-.096.24-.192.336 0 .044-.08.088-.502.318l-.122.066c-1.632.96-3.6 2.496-5.808 4.608-2.16 2.112-4.176 4.416-5.904 6.912-.72 1.056-1.152 1.632-1.392 1.824-.144.144-.336.192-.672.192-.288 0-.672-.048-1.152-.144-.96-.192-1.152-.384-1.248-.528-.096-.144-.288-.576-.528-1.248-.432-1.104-.912-2.16-1.536-3.216-.384-.576-.48-.96-.432-1.248.096-.384.432-.672 1.008-.912.576-.24 1.008-.336 1.344-.24.384.096.864.432 1.344 1.008.432.528.864 1.344 1.248 2.544a68.898 68.898 0 013.264-3.984 47.107 47.107 0 013.792-3.792c1.296-1.152 2.304-1.968 2.928-2.352.672-.384 1.104-.624 1.344-.672.24-.048.72-.048 1.44 0 .275.037.515.066.72.092zM1.776 19.232h13.632v-7.536c.576-.624 1.152-1.248 1.728-1.824v9.36a1.72 1.72 0 01-1.728 1.728H1.776a1.72 1.72 0 01-1.728-1.728V5.6c0-.96.768-1.728 1.728-1.728h13.488l-.144.144a16.94 16.94 0 00-1.007.938c-.22.217-.443.435-.673.646H1.776v13.632z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SelectIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  SelectIcon = nessiePropCheckerWrapper(SelectIcon);
}

export default SelectIcon;
