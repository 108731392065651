/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type SunIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let SunIcon: NessieFunctionComponent<SunIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12c0-2.176 1.765-4 4-4 2.177 0 4 1.824 4 4 0 2.177-1.765 4-4 4-2.176 0-4-1.765-4-4zm2 0c0 1.118.882 2 2 2 1.059 0 2-.882 2-2 0-1.118-.882-2-2-2-1.118 0-2 .882-2 2zM12 1a1 1 0 011 1v3.5a1 1 0 11-2 0V2a1 1 0 011-1zM2 11a1 1 0 100 2h3.5a1 1 0 100-2H2zm16.5 0a1 1 0 100 2H22a1 1 0 100-2h-3.5zM13 18.5a1 1 0 10-2 0V22a1 1 0 102 0v-3.5zM4.222 4.222a1 1 0 011.414 0l2.475 2.475A1 1 0 116.697 8.11L4.222 5.636a1 1 0 010-1.414zm13.081 11.667a1 1 0 00-1.414 1.414l2.475 2.475a1 1 0 001.414-1.414l-2.475-2.475zm2.475-11.667a1 1 0 010 1.414l-2.475 2.475a1 1 0 11-1.414-1.414l2.475-2.475a1 1 0 011.414 0zM8.111 17.303a1 1 0 10-1.414-1.414l-2.475 2.475a1 1 0 101.414 1.414l2.475-2.475z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SunIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  SunIcon = nessiePropCheckerWrapper(SunIcon);
}

export default SunIcon;
