/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type XCircleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let XCircleIcon: NessieFunctionComponent<XCircleIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.075 4.925c-3.9-3.9-10.21-3.9-14.15 0-3.9 3.9-3.9 10.21 0 14.15 3.9 3.9 10.21 3.9 14.15 0 3.9-3.94 3.9-10.25 0-14.15zM15.98 15.98a1.19 1.19 0 01-1.648 0L12 13.648 9.668 15.98a1.19 1.19 0 01-1.648 0 1.19 1.19 0 010-1.648L10.352 12 8.02 9.668a1.19 1.19 0 010-1.648 1.19 1.19 0 011.648 0L12 10.352l2.332-2.332a1.19 1.19 0 011.648 0 1.19 1.19 0 010 1.648L13.648 12l2.332 2.332a1.12 1.12 0 010 1.648z"
          />
          <mask maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.075 4.925c-3.9-3.9-10.21-3.9-14.15 0-3.9 3.9-3.9 10.21 0 14.15 3.9 3.9 10.21 3.9 14.15 0 3.9-3.94 3.9-10.25 0-14.15zM15.98 15.98a1.19 1.19 0 01-1.648 0L12 13.648 9.668 15.98a1.19 1.19 0 01-1.648 0 1.19 1.19 0 010-1.648L10.352 12 8.02 9.668a1.19 1.19 0 010-1.648 1.19 1.19 0 011.648 0L12 10.352l2.332-2.332a1.19 1.19 0 011.648 0 1.19 1.19 0 010 1.648L13.648 12l2.332 2.332a1.12 1.12 0 010 1.648z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
XCircleIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  XCircleIcon = nessiePropCheckerWrapper(XCircleIcon);
}

export default XCircleIcon;
