/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import { Tile } from "./Tile";
import Styles from "../commonStyles";

/*
  Note - each child will be wrapped and padded.
  This enables "multi-section" sidebars, such as the parent side
  "connected classes" tile and the teacher site "approve teachers" tile
*/

type SidebarTileProps = {
  header: React.ReactNode;
  headerStyle?: React.CSSProperties;
  sections?: React.ReactNode[];
  sectionStyle?: React.CSSProperties;
  children?: React.ReactNode;
};

export const SidebarTile = ({ header, headerStyle, sections, sectionStyle, children }: SidebarTileProps) => {
  sections = sections || [children];
  const contents = sections.map((section, i) => (
    <div key={`child${i}`} style={{ ...styles.section, ...sectionStyle }}>
      {section}
    </div>
  ));

  return (
    <Tile
      sx={{
        a: {
          color: "dt_aqua50",
        },
      }}
    >
      <h2 style={{ ...styles.header, ...headerStyle }}>{header}</h2>
      {contents}
    </Tile>
  );
};
SidebarTile.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  sections: PropTypes.arrayOf(PropTypes.node),
  headerStyle: PropTypes.object,
  sectionStyle: PropTypes.object,
};

const styles = {
  header: {
    fontSize: "1.6rem",
    fontWeight: 600,
    padding: "1.2rem 1.6rem 1rem",
    margin: 0,
  },
  section: {
    borderTop: `1px solid ${Styles.palette.gray20}`,
    padding: "1.2rem 1.6rem",
  },
};
