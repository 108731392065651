/** @jsxImportSource theme-ui */
import * as React from "react";
import { NessieSpaceSizes } from "./theme";

type ImageProps = {
  src?: string;
  width?: string | number;
  height?: string | number;
  margin?: NessieSpaceSizes;
  alt?: string;
};

export const Image: React.FunctionComponent<ImageProps> = ({
  src,
  width = "100%",
  height = "auto",
  margin,
  alt = "", // better default is to have an empty alt tag
}) => {
  return <img alt={alt} src={src} sx={{ display: "block", width, height, margin }} />;
};
