/** @jsxImportSource theme-ui */
import extend from "lodash/extend";
import * as React from "react";
import PropTypes from "prop-types";

const STUDENT_USER_AVATAR_REGEX = /\d{3}-\d{3}-\d{3}-\d{3}-\d{3}\.png$|\/monsterWorldAvatar\//;

export type StudentAvatarProps = {
  src: string;
  style?: React.CSSProperties;
  "data-test-name"?: string;
  className?: string;
  // should we check if the src url has a domain or prefix it with the `avatars.classdojo.com` domain if not
  checkSourceUrl?: boolean;
};

/**
 * Student Avatar
 */
const StudentAvatar = ({ style, src, checkSourceUrl = true, ...rest }: StudentAvatarProps): JSX.Element => {
  if (checkSourceUrl && !/\/\//.test(src) && !/\.com|\.net/.test(src)) {
    src = `https://avatars.classdojo.com${src}`;
  }

  const avatarStyles = {
    backgroundImage: `url("${src}")`,
    ...styles.avatar,
  };

  // Since these have weird padding on the sides
  if (STUDENT_USER_AVATAR_REGEX.test(src)) {
    extend(avatarStyles, styles.studentUserAvatar);
  }

  const containerStyles = {
    ...styles.defaultContainer,
    ...style,
  };

  return (
    <div {...rest} style={containerStyles}>
      <div style={avatarStyles} />
      <img alt="" src={src} style={styles.img} />
    </div>
  );
};

StudentAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default StudentAvatar;

const styles = {
  defaultContainer: {
    height: "6rem",
    width: "6rem",
    display: "inline-block",
    position: "relative" as const,
  },
  avatar: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    minHeight: "100%",
    minWidth: "100%",
  },
  img: {
    opacity: 0,
    position: "absolute" as const,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  studentUserAvatar: {
    backgroundSize: "cover",
  },
};
