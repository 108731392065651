/** @jsxImportSource theme-ui */
/* eslint-disable */
(function () {
  var a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p,
    q = {}.hasOwnProperty,
    r = function (a, b) {
      function c() {
        this.constructor = a;
      }
      for (var d in b) q.call(b, d) && (a[d] = b[d]);
      return (c.prototype = b.prototype), (a.prototype = new c()), (a.__super__ = b.prototype), a;
    };
  !(function () {
    var a, b, c, d, e, f, g;
    for (
      g = ["ms", "moz", "webkit", "o"], c = 0, e = g.length;
      e > c && ((f = g[c]), !window.requestAnimationFrame);
      c++
    )
      (window.requestAnimationFrame = window[f + "RequestAnimationFrame"]),
        (window.cancelAnimationFrame = window[f + "CancelAnimationFrame"] || window[f + "CancelRequestAnimationFrame"]);
    return (
      (a = null),
      (d = 0),
      (b = {}),
      requestAnimationFrame
        ? window.cancelAnimationFrame
          ? void 0
          : ((a = window.requestAnimationFrame),
            (window.requestAnimationFrame = function (c, e) {
              var f;
              return (
                (f = ++d),
                a(function () {
                  return b[f] ? void 0 : c();
                }, e),
                f
              );
            }),
            (window.cancelAnimationFrame = function (a) {
              return (b[a] = !0);
            }))
        : ((window.requestAnimationFrame = function (a, b) {
            var c, d, e, f;
            return (
              (c = new Date().getTime()),
              (f = Math.max(0, 16 - (c - e))),
              (d = window.setTimeout(function () {
                return a(c + f);
              }, f)),
              (e = c + f),
              d
            );
          }),
          (window.cancelAnimationFrame = function (a) {
            return clearTimeout(a);
          }))
    );
  })(),
    (String.prototype.hashCode = function () {
      var a, b, c, d, e;
      if (((b = 0), 0 === this.length)) return b;
      for (c = d = 0, e = this.length; e >= 0 ? e > d : d > e; c = e >= 0 ? ++d : --d)
        (a = this.charCodeAt(c)), (b = (b << 5) - b + a), (b &= b);
      return b;
    }),
    (o = function (a) {
      var b, c;
      for (
        b = Math.floor(a / 3600), c = Math.floor((a - 3600 * b) / 60), a -= 3600 * b + 60 * c, a += "", c += "";
        c.length < 2;

      )
        c = "0" + c;
      for (; a.length < 2; ) a = "0" + a;
      return (b = b ? b + ":" : ""), b + c + ":" + a;
    }),
    (m = function (a) {
      return k(a.toFixed(0));
    }),
    (p = function (a, b) {
      var c, d;
      for (c in b) q.call(b, c) && ((d = b[c]), (a[c] = d));
      return a;
    }),
    (n = function (a, b) {
      var c, d, e;
      d = {};
      for (c in a) q.call(a, c) && ((e = a[c]), (d[c] = e));
      for (c in b) q.call(b, c) && ((e = b[c]), (d[c] = e));
      return d;
    }),
    (k = function (a) {
      var b, c, d, e;
      for (
        a += "", c = a.split("."), d = c[0], e = "", c.length > 1 && (e = "." + c[1]), b = /(\d+)(\d{3})/;
        b.test(d);

      )
        d = d.replace(b, "$1,$2");
      return d + e;
    }),
    (l = function (a) {
      return "#" === a.charAt(0) ? a.substring(1, 7) : a;
    }),
    (j = (function () {
      function a(a, b) {
        null == a && (a = !0), (this.clear = null != b ? b : !0), a && AnimationUpdater.add(this);
      }
      return (
        (a.prototype.animationSpeed = 32),
        (a.prototype.update = function (a) {
          var b;
          return (
            null == a && (a = !1),
            a || this.displayedValue !== this.value
              ? (this.ctx && this.clear && this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height),
                (b = this.value - this.displayedValue),
                Math.abs(b / this.animationSpeed) <= 0.001
                  ? (this.displayedValue = this.value)
                  : (this.displayedValue = this.displayedValue + b / this.animationSpeed),
                this.render(),
                !0)
              : !1
          );
        }),
        a
      );
    })()),
    (e = (function (a) {
      function b() {
        return b.__super__.constructor.apply(this, arguments);
      }
      return (
        r(b, a),
        (b.prototype.displayScale = 1),
        (b.prototype.setTextField = function (a) {
          return (this.textField = a instanceof i ? a : new i(a));
        }),
        (b.prototype.setMinValue = function (a, b) {
          var c, d, e, f, g;
          if (((this.minValue = a), null == b && (b = !0), b)) {
            for (this.displayedValue = this.minValue, f = this.gp || [], g = [], d = 0, e = f.length; e > d; d++)
              (c = f[d]), g.push((c.displayedValue = this.minValue));
            return g;
          }
        }),
        (b.prototype.setOptions = function (a) {
          return (
            null == a && (a = null),
            (this.options = n(this.options, a)),
            this.textField && (this.textField.el.style.fontSize = a.fontSize + "px"),
            this.options.angle > 0.5 && (this.gauge.options.angle = 0.5),
            this.configDisplayScale(),
            this
          );
        }),
        (b.prototype.configDisplayScale = function () {
          var a, b, c, d, e;
          return (
            (d = this.displayScale),
            this.options.highDpiSupport === !1
              ? delete this.displayScale
              : ((b = window.devicePixelRatio || 1),
                (a =
                  this.ctx.webkitBackingStorePixelRatio ||
                  this.ctx.mozBackingStorePixelRatio ||
                  this.ctx.msBackingStorePixelRatio ||
                  this.ctx.oBackingStorePixelRatio ||
                  this.ctx.backingStorePixelRatio ||
                  1),
                (this.displayScale = b / a)),
            this.displayScale !== d &&
              ((e = this.canvas.G__width || this.canvas.width),
              (c = this.canvas.G__height || this.canvas.height),
              (this.canvas.width = e * this.displayScale),
              (this.canvas.height = c * this.displayScale),
              (this.canvas.style.width = e + "px"),
              (this.canvas.style.height = c + "px"),
              (this.canvas.G__width = e),
              (this.canvas.G__height = c)),
            this
          );
        }),
        b
      );
    })(j)),
    (i = (function () {
      function a(a) {
        this.el = a;
      }
      return (
        (a.prototype.render = function (a) {
          return (this.el.innerHTML = m(a.displayedValue));
        }),
        a
      );
    })()),
    (a = (function (a) {
      function b(a, b) {
        (this.elem = a),
          (this.text = null != b ? b : !1),
          (this.value = 1 * this.elem.innerHTML),
          this.text && (this.value = 0);
      }
      return (
        r(b, a),
        (b.prototype.displayedValue = 0),
        (b.prototype.value = 0),
        (b.prototype.setVal = function (a) {
          return (this.value = 1 * a);
        }),
        (b.prototype.render = function () {
          var a;
          return (
            (a = this.text ? o(this.displayedValue.toFixed(0)) : k(m(this.displayedValue))), (this.elem.innerHTML = a)
          );
        }),
        b
      );
    })(j)),
    (b = {
      create: function (b) {
        var c, d, e, f;
        for (f = [], d = 0, e = b.length; e > d; d++) (c = b[d]), f.push(new a(c));
        return f;
      },
    }),
    (h = (function (a) {
      function b(a) {
        (this.gauge = a),
          (this.ctx = this.gauge.ctx),
          (this.canvas = this.gauge.canvas),
          b.__super__.constructor.call(this, !1, !1),
          this.setOptions();
      }
      return (
        r(b, a),
        (b.prototype.displayedValue = 0),
        (b.prototype.value = 0),
        (b.prototype.options = { strokeWidth: 0.035, length: 0.1, color: "#000000" }),
        (b.prototype.setOptions = function (a) {
          return (
            null == a && (a = null),
            p(this.options, a),
            (this.length = this.canvas.height * this.options.length),
            (this.strokeWidth = this.canvas.height * this.options.strokeWidth),
            (this.maxValue = this.gauge.maxValue),
            (this.minValue = this.gauge.minValue),
            (this.animationSpeed = this.gauge.animationSpeed),
            (this.options.angle = this.gauge.options.angle)
          );
        }),
        (b.prototype.render = function () {
          var a, b, c, d, e, f, g, h, i;
          return (
            (a = this.gauge.getAngle.call(this, this.displayedValue)),
            (b = this.canvas.width / 2),
            (c = 0.9 * this.canvas.height),
            (h = Math.round(b + this.length * Math.cos(a))),
            (i = Math.round(c + this.length * Math.sin(a))),
            (f = Math.round(b + this.strokeWidth * Math.cos(a - Math.PI / 2))),
            (g = Math.round(c + this.strokeWidth * Math.sin(a - Math.PI / 2))),
            (d = Math.round(b + this.strokeWidth * Math.cos(a + Math.PI / 2))),
            (e = Math.round(c + this.strokeWidth * Math.sin(a + Math.PI / 2))),
            (this.ctx.fillStyle = this.options.color),
            this.ctx.beginPath(),
            this.ctx.arc(b, c, this.strokeWidth, 0, 2 * Math.PI, !0),
            this.ctx.fill(),
            this.ctx.beginPath(),
            this.ctx.moveTo(f, g),
            this.ctx.lineTo(h, i),
            this.ctx.lineTo(d, e),
            this.ctx.fill()
          );
        }),
        b
      );
    })(j)),
    (c = (function () {
      function a(a) {
        this.elem = a;
      }
      return (
        (a.prototype.updateValues = function (a) {
          return (this.value = a[0]), (this.maxValue = a[1]), (this.avgValue = a[2]), this.render();
        }),
        (a.prototype.render = function () {
          var a, b;
          return (
            this.textField && this.textField.text(m(this.value)),
            0 === this.maxValue && (this.maxValue = 2 * this.avgValue),
            (b = (this.value / this.maxValue) * 100),
            (a = (this.avgValue / this.maxValue) * 100),
            $(".bar-value", this.elem).css({ width: b + "%" }),
            $(".typical-value", this.elem).css({ width: a + "%" })
          );
        }),
        a
      );
    })()),
    (g = (function (a) {
      function b(a) {
        (this.canvas = a),
          b.__super__.constructor.call(this),
          (this.percentColors = null),
          "undefined" != typeof G_vmlCanvasManager &&
            (this.canvas = window.G_vmlCanvasManager.initElement(this.canvas)),
          (this.ctx = this.canvas.getContext("2d")),
          (this.gp = [new h(this)]),
          this.setOptions(),
          this.render();
      }
      return (
        r(b, a),
        (b.prototype.elem = null),
        (b.prototype.value = [20]),
        (b.prototype.maxValue = 80),
        (b.prototype.minValue = 0),
        (b.prototype.displayedAngle = 0),
        (b.prototype.displayedValue = 0),
        (b.prototype.lineWidth = 40),
        (b.prototype.paddingBottom = 0.1),
        (b.prototype.percentColors = null),
        (b.prototype.options = {
          colorStart: "#6fadcf",
          colorStop: void 0,
          gradientType: 0,
          strokeColor: "#e0e0e0",
          pointer: { length: 0.8, strokeWidth: 0.035 },
          angle: 0.15,
          lineWidth: 0.44,
          fontSize: 40,
          limitMax: !1,
        }),
        (b.prototype.setOptions = function (a) {
          var c, d, e, f;
          for (
            null == a && (a = null),
              b.__super__.setOptions.call(this, a),
              this.configPercentColors(),
              this.lineWidth = this.canvas.height * (1 - this.paddingBottom) * this.options.lineWidth,
              this.radius = this.canvas.height * (1 - this.paddingBottom) - this.lineWidth,
              this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height),
              this.render(),
              f = this.gp,
              d = 0,
              e = f.length;
            e > d;
            d++
          )
            (c = f[d]), c.setOptions(this.options.pointer), c.render();
          return this;
        }),
        (b.prototype.configPercentColors = function () {
          var a, b, c, d, e, f, g;
          if (((this.percentColors = null), void 0 !== this.options.percentColors)) {
            for (
              this.percentColors = new Array(), f = [], c = d = 0, e = this.options.percentColors.length - 1;
              e >= 0 ? e >= d : d >= e;
              c = e >= 0 ? ++d : --d
            )
              (g = parseInt(l(this.options.percentColors[c][1]).substring(0, 2), 16)),
                (b = parseInt(l(this.options.percentColors[c][1]).substring(2, 4), 16)),
                (a = parseInt(l(this.options.percentColors[c][1]).substring(4, 6), 16)),
                f.push(
                  (this.percentColors[c] = { pct: this.options.percentColors[c][0], color: { r: g, g: b, b: a } }),
                );
            return f;
          }
        }),
        (b.prototype.set = function (a) {
          var b, c, d, e, f, g, i;
          if ((a instanceof Array || (a = [a]), a.length > this.gp.length))
            for (b = c = 0, g = a.length - this.gp.length; g >= 0 ? g > c : c > g; b = g >= 0 ? ++c : --c)
              this.gp.push(new h(this));
          for (b = 0, f = !1, d = 0, e = a.length; e > d; d++)
            (i = a[d]),
              i > this.maxValue && ((this.maxValue = 1.1 * this.value), (f = !0)),
              (this.gp[b].value = i),
              this.gp[b++].setOptions({ maxValue: this.maxValue, angle: this.options.angle });
          return (this.value = a[a.length - 1]), f && this.options.limitMax ? void 0 : AnimationUpdater.run();
        }),
        (b.prototype.getAngle = function (a) {
          return (
            (1 + this.options.angle) * Math.PI +
            ((a - this.minValue) / (this.maxValue - this.minValue)) * (1 - 2 * this.options.angle) * Math.PI
          );
        }),
        (b.prototype.getColorForPercentage = function (a, b) {
          var c, d, e, f, g, h, i;
          if (0 === a) c = this.percentColors[0].color;
          else
            for (
              c = this.percentColors[this.percentColors.length - 1].color, e = f = 0, h = this.percentColors.length - 1;
              h >= 0 ? h >= f : f >= h;
              e = h >= 0 ? ++f : --f
            )
              if (a <= this.percentColors[e].pct) {
                b === !0
                  ? ((i = this.percentColors[e - 1]),
                    (d = this.percentColors[e]),
                    (g = (a - i.pct) / (d.pct - i.pct)),
                    (c = {
                      r: Math.floor(i.color.r * (1 - g) + d.color.r * g),
                      g: Math.floor(i.color.g * (1 - g) + d.color.g * g),
                      b: Math.floor(i.color.b * (1 - g) + d.color.b * g),
                    }))
                  : (c = this.percentColors[e].color);
                break;
              }
          return "rgb(" + [c.r, c.g, c.b].join(",") + ")";
        }),
        (b.prototype.getColorForValue = function (a, b) {
          var c;
          return (c = (a - this.minValue) / (this.maxValue - this.minValue)), this.getColorForPercentage(c, b);
        }),
        (b.prototype.render = function () {
          var a, b, c, d, e, f, g, h, i;
          for (
            i = this.canvas.width / 2,
              d = this.canvas.height * (1 - this.paddingBottom),
              a = this.getAngle(this.displayedValue),
              this.textField && this.textField.render(this),
              this.ctx.lineCap = "butt",
              void 0 !== this.options.customFillStyle
                ? (b = this.options.customFillStyle(this))
                : null !== this.percentColors
                ? (b = this.getColorForValue(this.displayedValue, !0))
                : void 0 !== this.options.colorStop
                ? ((b =
                    0 === this.options.gradientType
                      ? this.ctx.createRadialGradient(i, d, 9, i, d, 70)
                      : this.ctx.createLinearGradient(0, 0, i, 0)),
                  b.addColorStop(0, this.options.colorStart),
                  b.addColorStop(1, this.options.colorStop))
                : (b = this.options.colorStart),
              this.ctx.strokeStyle = b,
              this.ctx.beginPath(),
              this.ctx.arc(i, d, this.radius, (1 + this.options.angle) * Math.PI, a, !1),
              this.ctx.lineWidth = this.lineWidth,
              this.ctx.stroke(),
              this.ctx.strokeStyle = this.options.strokeColor,
              this.ctx.beginPath(),
              this.ctx.arc(i, d, this.radius, a, (2 - this.options.angle) * Math.PI, !1),
              this.ctx.stroke(),
              g = this.gp,
              h = [],
              e = 0,
              f = g.length;
            f > e;
            e++
          )
            (c = g[e]), h.push(c.update(!0));
          return h;
        }),
        b
      );
    })(e)),
    (d = (function (a) {
      function b(a) {
        (this.canvas = a),
          b.__super__.constructor.call(this),
          "undefined" != typeof G_vmlCanvasManager &&
            (this.canvas = window.G_vmlCanvasManager.initElement(this.canvas)),
          (this.ctx = this.canvas.getContext("2d")),
          this.setOptions(),
          this.render();
      }
      return (
        r(b, a),
        (b.prototype.lineWidth = 15),
        (b.prototype.displayedValue = 0),
        (b.prototype.value = 33),
        (b.prototype.maxValue = 80),
        (b.prototype.minValue = 0),
        (b.prototype.options = {
          lineWidth: 0.1,
          colorStart: "#6f6ea0",
          colorStop: "#c0c0db",
          strokeColor: "#eeeeee",
          shadowColor: "#d5d5d5",
          angle: 0.35,
        }),
        (b.prototype.getAngle = function (a) {
          return (
            (1 - this.options.angle) * Math.PI +
            ((a - this.minValue) / (this.maxValue - this.minValue)) *
              (2 + this.options.angle - (1 - this.options.angle)) *
              Math.PI
          );
        }),
        (b.prototype.setOptions = function (a) {
          return (
            null == a && (a = null),
            b.__super__.setOptions.call(this, a),
            (this.lineWidth = this.canvas.height * this.options.lineWidth),
            (this.radius = this.canvas.height / 2 - this.lineWidth / 2),
            this
          );
        }),
        (b.prototype.set = function (a) {
          return (
            (this.value = a), this.value > this.maxValue && (this.maxValue = 1.1 * this.value), AnimationUpdater.run()
          );
        }),
        (b.prototype.render = function () {
          var a, b, c, d, e, f;
          return (
            (a = this.getAngle(this.displayedValue)),
            (f = this.canvas.width / 2),
            (c = this.canvas.height / 2),
            this.textField && this.textField.render(this),
            (b = this.ctx.createRadialGradient(f, c, 39, f, c, 70)),
            b.addColorStop(0, this.options.colorStart),
            b.addColorStop(1, this.options.colorStop),
            (d = this.radius - this.lineWidth / 2),
            (e = this.radius + this.lineWidth / 2),
            (this.ctx.strokeStyle = this.options.strokeColor),
            this.ctx.beginPath(),
            this.ctx.arc(f, c, this.radius, (1 - this.options.angle) * Math.PI, (2 + this.options.angle) * Math.PI, !1),
            (this.ctx.lineWidth = this.lineWidth),
            (this.ctx.lineCap = "round"),
            this.ctx.stroke(),
            (this.ctx.strokeStyle = b),
            this.ctx.beginPath(),
            this.ctx.arc(f, c, this.radius, (1 - this.options.angle) * Math.PI, a, !1),
            this.ctx.stroke()
          );
        }),
        b
      );
    })(e)),
    (f = (function (a) {
      function b() {
        return b.__super__.constructor.apply(this, arguments);
      }
      return (
        r(b, a),
        (b.prototype.strokeGradient = function (a, b, c, d) {
          var e;
          return (
            (e = this.ctx.createRadialGradient(a, b, c, a, b, d)),
            e.addColorStop(0, this.options.shadowColor),
            e.addColorStop(0.12, this.options._orgStrokeColor),
            e.addColorStop(0.88, this.options._orgStrokeColor),
            e.addColorStop(1, this.options.shadowColor),
            e
          );
        }),
        (b.prototype.setOptions = function (a) {
          var c, d, e, f;
          return (
            null == a && (a = null),
            b.__super__.setOptions.call(this, a),
            (f = this.canvas.width / 2),
            (c = this.canvas.height / 2),
            (d = this.radius - this.lineWidth / 2),
            (e = this.radius + this.lineWidth / 2),
            (this.options._orgStrokeColor = this.options.strokeColor),
            (this.options.strokeColor = this.strokeGradient(f, c, d, e)),
            this
          );
        }),
        b
      );
    })(d)),
    (window.AnimationUpdater = {
      elements: [],
      animId: null,
      addAll: function (a) {
        var b, c, d, e;
        for (e = [], c = 0, d = a.length; d > c; c++) (b = a[c]), e.push(AnimationUpdater.elements.push(b));
        return e;
      },
      add: function (a) {
        return AnimationUpdater.elements.push(a);
      },
      run: function () {
        var a, b, c, d, e;
        for (a = !0, e = AnimationUpdater.elements, c = 0, d = e.length; d > c; c++) (b = e[c]), b.update() && (a = !1);
        return a
          ? cancelAnimationFrame(AnimationUpdater.animId)
          : (AnimationUpdater.animId = requestAnimationFrame(AnimationUpdater.run));
      },
    }),
    "function" == typeof window.define && null != window.define.amd
      ? define(function () {
          return { Gauge: g, Donut: f, BaseDonut: d, TextRenderer: i };
        })
      : "undefined" != typeof module && null != module.exports
      ? (module.exports = { Gauge: g, Donut: f, BaseDonut: d, TextRenderer: i })
      : ((window.Gauge = g), (window.Donut = f), (window.BaseDonut = d), (window.TextRenderer = i));
}).call(this);
