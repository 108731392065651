/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";

/**
 * This is a triangle. You can specify its size, color, and direction.
 */
type TriangleProps = {
  direction?: string;
  size?: string;
  color?: string;
  style?: React.CSSProperties;
  styles?: React.CSSProperties[];
};

const Triangle = ({ direction = "up", size = "1rem", color = "black", style, styles }: TriangleProps): JSX.Element => {
  const _getStyles = () => {
    const styles: React.CSSProperties = {
      width: "0",
      height: "0",
      borderStyle: "solid",
      borderTopWidth: size,
      borderRightWidth: size,
      borderBottomWidth: size,
      borderLeftWidth: size,
      borderTopColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: "transparent",
      borderLeftColor: "transparent",
      // WebkitFilter: "drop-shadow(0 -1px 5px rgba(0, 0, 0, 0.3))",
      // filter: "url(shadow.svg#drop-shadow)"
    };

    if (direction === "up") {
      styles.borderTopWidth = "0";
      styles.borderBottomColor = color;
      delete styles.filter;
      delete styles.WebkitFilter;
    }

    if (direction === "down") {
      styles.borderBottomWidth = "0";
      styles.borderTopColor = color;
    }

    if (direction === "right") {
      styles.borderRightWidth = "0";
      styles.borderLeftColor = color;
    }

    if (direction === "left") {
      styles.borderLeftWidth = "0";
      styles.borderRightColor = color;
    }

    return styles;
  };

  let _styles = [_getStyles()];

  if (style) _styles.push(style);
  if (styles) _styles = _styles.concat(styles);

  return <div style={Object.assign({}, ..._styles)} />;
};

Triangle.propTypes = {
  // Direction in which the caret should be pointing
  direction: PropTypes.oneOf(["up", "right", "down", "left"]),
  // Overall size of the caret
  size: PropTypes.string,
  // Background color
  color: PropTypes.string,
};

export default Triangle;
