/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import translate from "../../utils/translate";

type MainContentWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

const MAIN_CONTENT_ID = "skip-to-main-content-target";

/**
 * For accessibility reasons keyboard users need to be able to skip to main content
 * for that to happen, the main content needs to be identified.
 * Please use this wrapper everytime you add a new route.
 */
function MainContentWrapper({ className, children }: MainContentWrapperProps) {
  const handleClick = () => {
    const mainContent = document.getElementById(MAIN_CONTENT_ID);
    mainContent?.focus();
  };
  useEffect(() => {
    const pageBody = document.body;

    const skipToMainContentButton = document.createElement("button");
    skipToMainContentButton.onclick = handleClick;
    skipToMainContentButton.textContent = translate({
      str: "dojo.common:actions.skip_to_main",
      fallback: "SKIP TO MAIN CONTENT",
    });
    skipToMainContentButton.setAttribute("style", buttonStyles);
    skipToMainContentButton.setAttribute("id", "skip-to-main-content-button");

    pageBody.prepend(skipToMainContentButton);
    return () => {
      skipToMainContentButton.remove();
    };
  }, []);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <main className={className} id={MAIN_CONTENT_ID} tabIndex={0}>
      {children}
    </main>
  );
}

export default MainContentWrapper;

// As this element is injected in the dom, the focus/active styles need to be added in the base.css file
const buttonStyles = `
    border: none; 
    background: #eaecf5;
    color: #2c2a50;
    font-weight: bold;
    padding: 12px;
    font-size: 16px;
    border-radius: 0;
    border-bottom-right-radius: 5px;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: all 200ms linear;
    z-index: 1000;
    text-decoration: underline;
`;
