/** @jsxImportSource theme-ui */
import { createRef, Component } from "react";
import PropTypes from "prop-types";

import { isTesting } from "../../../utils/env";
import Gauge, { DojoGaugeOptions, GaugeInterface } from "./gaugejs";
import { isAnimationEnabled } from "../../../utils/animationEnabledState";

import { ClassDojoStyles } from "../../../index";

const HEIGHT = 100;
const WIDTH = 100;

const DEFAULT_PROPS: DojoGaugeOptions = {
  pointer: undefined,
  angle: 0.5, // The length of each line
  lineWidth: 0.15, // The line thickness
  limitMax: true, // If true, the pointer will not go past the end of the gauge
  colorStart: "#93d453",
  colorStop: "#93d453",
  strokeColor: "rgba(147, 212, 83, 0.16)",
};

type DojoGaugeProps = {
  value: number;
  options?: DojoGaugeOptions;
  width?: number;
  height?: number;
  color?: string;
};

class DojoGauge extends Component<DojoGaugeProps> {
  static propTypes = {
    value: PropTypes.number.isRequired,
    options: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
  };

  constructor(props: DojoGaugeProps) {
    super(props);
  }

  componentDidMount(): void {
    const { width = 0, height = 0, options, color } = this.props;
    if (!isTesting) {
      const base = this.baseRef.current;
      const el = this.elRef.current;

      const ctx = base?.getContext("2d");
      if (ctx) {
        const x = width / 2;
        const y = height / 2;
        const radius = (width - 20) / 2; // line thickness is 10
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.fillStyle = "white";
        ctx.fill();
      }

      if (el) {
        this.gauge = new Gauge(el);
        const props = color
          ? { ...DEFAULT_PROPS, colorStart: color, colorStop: color, strokeColor: ClassDojoStyles.palette.gray20 }
          : DEFAULT_PROPS;
        this.gauge.setOptions({ ...props, ...options });
        if (isAnimationEnabled()) this.gauge.animationSpeed = 1;
      }
    }

    // Gauge doesn't handle 0 nicely, so we're setting it to a non-zero before potentially
    // setting it to 0
    this._setValue(0.00000001);
    this._setValue();
  }

  componentDidUpdate(): void {
    this._setValue();
  }

  render(): JSX.Element {
    return (
      <div>
        <canvas
          aria-hidden={true}
          width={this.props.width || WIDTH}
          height={this.props.height || HEIGHT}
          style={{ ...canvasStyle, zIndex: 0 }}
          ref={this.baseRef}
        />
        <canvas
          aria-hidden={true}
          width={this.props.width || WIDTH}
          height={this.props.height || HEIGHT}
          style={{ ...canvasStyle, zIndex: 1 }}
          ref={this.elRef}
        />
      </div>
    );
  }

  baseRef = createRef<HTMLCanvasElement>();
  elRef = createRef<HTMLCanvasElement>();
  gauge: GaugeInterface;

  _setValue(val?: number): void {
    val = val || this.props.value;
    if (isNaN(val)) val = 0;
    if (this.gauge) this.gauge.set((val * 80) / 100);
  }
}

export default DojoGauge;

const canvasStyle = {
  transform: "rotate(180deg)",
  top: 0,
  position: "absolute" as const,
};
