/** @jsxImportSource theme-ui */
import { Root, Trigger, Item, Content, Portal } from "@radix-ui/react-dropdown-menu";
import React, { useState } from "react";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { DownCarrotIcon } from "../icons";
import { NessieThemeColors } from "./theme";
import { GlobalCSS } from "../../nessie/stylingLib";
// eslint-disable-next-line no-restricted-imports
import { css } from "@emotion/react";
export interface MenuOptions {
  label: string;
  onClick?: () => void;
  // this is very important to keep the menu open while opening a modal.
  // keyboard users need to be brought back to the menu when the modal closes.
  opensAModal?: boolean;
  color?: string;
  icon?: React.ReactElement;
  href?: string;
}
interface DropdownMenuProps {
  align?: "center" | "end" | "start";
  caret?: boolean;
  color?: NessieThemeColors;
  side?: "top" | "right" | "bottom" | "left";
  label: string;
  trigger?: React.ReactElement;
  options: MenuOptions[];
  disabled?: boolean;
  optionsContainerMaxHeight?: number;
  /**
   * this prop allow you to choose the container of the menu. Defaults to body.
   */
  portalContainer?: HTMLElement | null;
}

export function DropdownMenu({
  label,
  trigger,
  options,
  color,
  side,
  caret,
  align,
  disabled,
  optionsContainerMaxHeight,
  portalContainer,
}: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  function handleEscape(event: React.KeyboardEvent) {
    event.stopPropagation();
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  }

  const handleFocusOutside = (e: Event) => e.preventDefault();
  return (
    // this on key down is necessary to handle the escape to close
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} onKeyDown={handleEscape}>
      <GlobalCSS
        styles={css`
          [data-radix-popper-content-wrapper]:focus-within {
            z-index: 1000 !important;
          }
        `}
      />
      <Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)} modal={false}>
        <Trigger sx={DropdownMenuTriggerButtonStyles} asChild disabled={disabled}>
          <UnstyledButton
            sx={{ ...DropdownMenuTriggerButtonStyles, color }}
            type="button"
            aria-label={label}
            aria-expanded={isOpen}
            disabled={disabled}
            onKeyDown={(e) => {
              // without this, the click with enter also clicks on the first option and never opens the options
              if (!isOpen && e.key === "Enter") {
                e.preventDefault();
                setIsOpen(true);
              }
            }}
          >
            {trigger}
            {(caret || !trigger) && !disabled ? (
              <div className={"DropdownMenuCarretIconSvg"}>
                <DownCarrotIcon size="s" color={color} />
              </div>
            ) : null}
          </UnstyledButton>
        </Trigger>
        <Portal container={portalContainer}>
          <Content
            loop
            side={side}
            sx={{ ...optionsListStyles, maxHeight: optionsContainerMaxHeight || "unset" }}
            align={align}
            onFocusOutside={handleFocusOutside}
          >
            {options.map((option) => {
              let isMouse = false;
              const Tag = option.href ? "a" : "button";

              return (
                <Item
                  key={option.label}
                  onSelect={(e) => {
                    // Keyboard users need the menu to keep open when they open a modal.
                    // According to a11y regulation, the focus should return to the button that opened the modal once said modal is closed
                    // The only way to do that is by keeping the menu open.
                    // Radix allows us to keep the menu open by adding prevent default to the onSelect prop.
                    // The problem is that the radix menu items interprets a click with a mouse differently from a click with a keyboard
                    // because of that, it only moves focus to the opened modal if the click is done with a keyboard.
                    // on top of that, radix does not pass any property to the onSelect event object that allows us to verify if it was a mouse click or a keyboard click.
                    // so, to fix that, we had to add an onClick listener to grab the pointer type
                    if (option.opensAModal && !isMouse) e.preventDefault();
                    if (isMouse) isMouse = false;
                  }}
                  onClick={(e) => {
                    if ((e.nativeEvent as PointerEvent).pointerType) isMouse = true;
                  }}
                  asChild
                  role={option.href ? "link" : "button"}
                >
                  <Tag
                    sx={{ ...listItemStyles, color: option.color || "dt_taro90" }}
                    onClick={option.onClick}
                    href={option.href}
                  >
                    {option.icon}
                    {option.label}
                  </Tag>
                </Item>
              );
            })}
          </Content>
        </Portal>
      </Root>
    </div>
  );
}

const DropdownMenuTriggerButtonStyles: ThemeUIStyleObject = {
  fontWeight: "600",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  minWidth: "40px",
  minHeight: "40px",
  '&[aria-expanded="true"] .DropdownMenuCarretIconSvg': {
    transform: "rotate(180deg)",
  },
  '&[disabled="true"],  &[disabled]': {
    cursor: "not-allowed",
    opacity: "0.6",
  },
  ".DropdownMenuCarretIconSvg": {
    transition: "transform 0.3s linear",
    marginLeft: "dt_xxs",
    display: "flex",
    alignItems: "center",
  },
};

const optionsListStyles: ThemeUIStyleObject = {
  backgroundColor: "dt_white",
  boxShadow: "0 1px 5px 0px rgba(0, 0, 0, 0.3)",
  marginTop: "dt_xs",
  borderRadius: RAW_cssValue("5px"),
  overflowY: "auto",
  zIndex: 100,
};

const listItemStyles: ThemeUIStyleObject = {
  backgroundColor: "transparent",
  "&:hover, &:focus-within": {
    backgroundColor: "dt_blueberry20",
  },
  border: "2px solid",
  borderColor: "transparent",
  whiteSpace: "nowrap",
  fontSize: "16px",
  paddingLeft: "dt_s",
  paddingRight: "dt_xl",
  paddingY: "dt_s",
  minHeight: "40px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "dt_s",
  ":focus:not(:hover)": {
    borderColor: "dt_taro60",
  },
  ":focus": {
    outline: "none",
  },
  // this needs to be last child and not last of type now that we have two different types as children
  "&:not(:last-child)": {
    borderBottom: "1px solid",
    borderBottomColor: "dt_taro30",
  },

  "&:last-child": {
    borderBottomLeftRadius: RAW_cssValue("5px"),
    borderBottomRightRadius: RAW_cssValue("5px"),
  },
  "&:first-child": {
    borderTopLeftRadius: RAW_cssValue("5px"),
    borderTopRightRadius: RAW_cssValue("5px"),
  },
};
