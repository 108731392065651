/** @jsxImportSource theme-ui */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import * as React from "react";
import { Portal } from "../../components/Portal";
import Dock from "react-dock";
import { theme } from "../../components/theme";
import { Action, Title, DetailAction, DetailText, Subheading, BodyText } from "../../components/Text";
import { RAW_cssValue } from "../RAW_cssValue";
import { ErrorMessageProps, ValidationContext } from "./types";

type ValidationCollectorProps = {
  // eslint-disable-next-line camelcase
  __stylingLibInternal_type: any;
  // eslint-disable-next-line camelcase
  __stylingLibInternal_validationContext: ValidationContext;
};

export const ValidationCollector = React.forwardRef(function ValidationCollector(
  {
    __stylingLibInternal_type: type,
    __stylingLibInternal_validationContext: validationContext,
    ...props
  }: ValidationCollectorProps,
  ref,
) {
  const [errors, setErrors] = React.useState<ErrorMessageProps[]>([]);

  // Wait until children mounts so that we can collect the errors
  React.useEffect(() => {
    setErrors(validationContext.errors);
  }, [validationContext.errors]);

  const WrappedComponent = type;
  const el = <WrappedComponent {...props} ref={ref} />;

  if (errors.length > 0) {
    return <ValidationErrorPanel el={el} location={validationContext.location} validationErrors={errors} />;
  }
  return el;
});

type ValidationErrorPanelProps = {
  validationErrors: ErrorMessageProps[];
  location?: string;
  el: JSX.Element;
};

const ValidationErrorPanel = ({ location, validationErrors, el }: ValidationErrorPanelProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  if (validationErrors && validationErrors.length > 0) {
    return (
      <React.Fragment>
        <Portal>
          <div
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            sx={{ backgroundColor: "dt_watermelon50", cursor: "not-allowed", position: "relative" }}
          >
            {!expanded && <SeeMoreButton onClick={() => setExpanded(true)} />}
            {expanded && (
              <Dock position="right" fluid isVisible={true} dimStyle={{ opacity: 0.2 }}>
                <div sx={{ padding: "dt_m", cursor: "auto", position: "relative" }}>
                  <div
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      setExpanded(false);
                    }}
                    sx={{ cursor: "pointer", display: "inline-block", position: "relative" }}
                  >
                    <Action>Close</Action>
                  </div>
                  <div>
                    <Title>'sx' theme validation error</Title>
                    <div
                      sx={{
                        padding: "dt_s",
                        marginTop: "dt_s",
                        border: "dt_border_card",
                        borderRadius: "dt_radius_s",
                        boxShadow: "dt_shadow_shadezies",
                      }}
                    >
                      <Action compact kind="inactive">
                        <span aria-hidden>👉</span>
                        Check
                        <a
                          style={{ color: "#4975F6" }}
                          href="https://components.classdojo.com/?path=/docs/nessie-tokens--palette#palette-tokens"
                          target="_blank"
                        >
                          this
                        </a>{" "}
                        for a full list of tokens
                      </Action>
                      <div sx={{ marginTop: "dt_xs" }} />
                      <Action compact kind="inactive">
                        <span aria-hidden>👉</span>
                        Check
                        <a
                          style={{ color: "#4975F6" }}
                          href="https://components.classdojo.com/?path=/docs/nessie-guidelines-and-examples-how-tos--skip-design-tokens-validation#skip-design-tokens-validation"
                          target="_blank"
                        >
                          this
                        </a>{" "}
                        for more info on when & how to skip tokens validation
                      </Action>
                      <div sx={{ marginTop: "dt_xs" }} />
                      <Action compact kind="inactive">
                        <span aria-hidden>👉</span>
                        Check
                        <a
                          style={{ color: "#4975F6" }}
                          href="https://components.classdojo.com/?path=/docs/nessie-start-here--page"
                          target="_blank"
                        >
                          this
                        </a>{" "}
                        for more documentation about styling and nessie
                      </Action>
                    </div>
                    <div sx={{ marginBottom: "dt_m" }} />
                    {validationErrors.map((validationError, index) => {
                      return (
                        <div key={index}>
                          <ErrorMessage {...validationError} location={location} />
                          <div
                            sx={{ border: "dt_border_card", width: "100%", marginTop: "dt_s", marginBottom: "dt_s" }}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Dock>
            )}
          </div>
        </Portal>
        {React.cloneElement(el, { style: { ...el.props.style, outline: "4px dotted red" } })}
      </React.Fragment>
    );
  }
  return el;
};

type SeeMoreButtonProps = {
  onClick: () => void;
};

const SeeMoreButton = ({ onClick }: SeeMoreButtonProps) => (
  <div
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      onClick();
    }}
    sx={{
      backgroundColor: "dt_watermelon50",
      border: "dt_border_card",
      borderRadius: "dt_radius_s",
      color: "dt_white",
      cursor: "pointer",
      zIndex: "9999",
      position: "fixed",
      top: "10px",
      left: "10px",
      paddingLeft: "dt_l",
      paddingRight: "dt_l",
      paddingTop: "dt_xs",
      paddingBottom: "dt_xs",
    }}
  >
    See more
  </div>
);

const ErrorMessage = (props: ErrorMessageProps) => {
  let optionsContent = null;
  let title = null;

  if ("colors" === props.scale) {
    optionsContent = <ColorOptions colors={props.options} />;
    title = (
      <>
        You are trying to use a color value that doesn't exist in the theme&nbsp;'
        <DetailText sx={{ color: "dt_watermelon50" }}>{props.cssPropValue}</DetailText>'
      </>
    );
  } else if ("space" === props.scale) {
    optionsContent = <SpacingOptions spacingTokens={props.options} />;
    title = (
      <>
        You are trying to use a spacing value that doesn't exist in the theme&nbsp;'
        <DetailText sx={{ color: "dt_watermelon50" }}>{props.cssPropValue}</DetailText>'
      </>
    );
  } else {
    optionsContent = (props.options || []).map((option) => <div key={option}>{option}</div>);
    title = (
      <>
        Theme value not found&nbsp;'<DetailText sx={{ color: "dt_watermelon50" }}>{props.cssPropValue}</DetailText>'
      </>
    );
  }

  return (
    <div>
      <Subheading>{title}</Subheading>

      <div sx={{ marginTop: "s" }} />
      <div>
        Component: <DetailAction inline>{props.location}</DetailAction>
      </div>
      <div>
        Css prop: <DetailAction inline>{props.cssPropName}</DetailAction>
      </div>
      <div>
        Css value found: <DetailAction inline>{props.cssPropValue}</DetailAction>
      </div>

      <div sx={{ marginTop: "dt_m" }} />
      <div>
        <BodyText sx={{ marginBottom: "dt_s" }}>Available tokens</BodyText>
        {optionsContent}
      </div>
    </div>
  );
};

//
// Scales options
//

const SpacingOptions = (props: { spacingTokens: string[] }) => {
  return (
    <div>
      {props.spacingTokens.map((spaceToken) => {
        return (
          <div
            key={spaceToken}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "dt_xs",
            }}
          >
            <div
              sx={{
                width: theme.space[spaceToken as any],
                backgroundColor: "dt_watermelon40",
                borderColor: "dt_taro20",
                height: "10px",
              }}
            ></div>
            <div>{spaceToken}</div>
          </div>
        );
      })}
    </div>
  );
};

const ColorOptions = (props: { colors: string[] }) => {
  return (
    <div sx={{ display: "flex", flexWrap: "wrap" }}>
      {props.colors.map((color) => {
        return (
          <div
            key={color}
            sx={{
              display: "flex",
              width: "120px",
              height: "60px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "dt_xs",
            }}
          >
            <div
              sx={{
                backgroundColor: color,
                border: "1px solid",
                borderColor: "dt_taro20",
                width: "30px",
                height: "30px",
                borderRadius: RAW_cssValue("50%"),
              }}
            ></div>
            <div>{color}</div>
          </div>
        );
      })}
    </div>
  );
};
