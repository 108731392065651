import PropTypes from "prop-types";

export type TranslatableProps = {
  text?: string | React.ReactNode;
  str?: string; //| string[];
  htmlStr?: string;
  fallback?: string;
  componentFallback?: React.ReactNode;
  subs?: Record<string, unknown>;
  lowercase?: unknown;
  tmpText?: string;
};

export const translatablePropTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  str: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  htmlStr: PropTypes.string,
  fallback: PropTypes.string,
  componentFallback: PropTypes.node,
  subs: PropTypes.object,
  lowercase: PropTypes.any,
  tmpText: PropTypes.string,
};

export const translatableValidator = PropTypes.oneOfType([PropTypes.string, PropTypes.shape(translatablePropTypes)]);
