/** @jsxImportSource theme-ui */
import extend from "lodash/extend";
import * as React from "react";
import PropTypes from "prop-types";

import Triangle from "./Triangle";
import { shadows, palette } from "../commonStyles";
import { AbsolutePositioner } from "../positioning";
import UnstyledButton from "../buttons/UnstyledButton";

/**
 * Simple Overlay element, no logic exposed, just a markup component.
 * Renders our basic version of Overlay elements which is basically a shadow
 * plus minor details.
 */

export const tooltipMaxWidth = "25rem";

type TooltipProps = {
  backgroundColor?: string;
  textColor?: string;
  padding?: string;
  maxWidth?: string;
  textSize?: string | null;
  style?: React.CSSProperties;
  styles?: React.CSSProperties[];
  children?: React.ReactNode;
  caret?: "none" | "top" | "bottom" | "left" | "right";
  caretOffset?: string;
  caretSize?: string;
  showCaretBorder?: boolean;
  onClick?: React.MouseEventHandler;
};

const Tooltip = ({
  backgroundColor = palette.dojoPurple,
  textColor = "#ffffff",
  padding = "1rem",
  maxWidth = tooltipMaxWidth,
  textSize = "1.6rem",
  style,
  styles,
  children,
  caret = "bottom",
  caretOffset = "50%",
  caretSize = "0.8rem",
  showCaretBorder = true,
  onClick,
}: TooltipProps): JSX.Element => {
  const fontSize = textSize === null ? undefined : textSize;

  const containerStyles = [_styles.container, style]
    .concat(styles)
    .concat({ backgroundColor, color: textColor, fontSize, padding });

  const ButtonOrDivComponent = onClick ? UnstyledButton : "div";
  return (
    <ButtonOrDivComponent onClick={onClick} style={{ ..._styles.wrapper, maxWidth }}>
      {caret !== "none" && (
        <TooltipDefaultCaret
          caret={caret}
          caretOffset={caretOffset}
          caretSize={caretSize}
          backgroundColor={backgroundColor}
          showCaretBorder={showCaretBorder}
        />
      )}
      <div style={extend({}, ...containerStyles)} role="status" aria-live="polite">
        {children}
      </div>
    </ButtonOrDivComponent>
  );
};

Tooltip.propTypes = {
  caret: PropTypes.oneOf(["top", "bottom", "left", "right", "none"]),
  caretOffset: PropTypes.string,
  caretSize: PropTypes.string,
  textSize: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  maxWidth: PropTypes.string,
  style: PropTypes.object,
  showCaretBorder: PropTypes.bool,
};

export default Tooltip;

type TooltipDefaultCaretProps = Pick<TooltipProps, "caretOffset" | "caretSize" | "backgroundColor"> & {
  caret: "top" | "bottom" | "left" | "right";
  showCaretBorder: boolean;
};

const TooltipDefaultCaret = ({
  caret,
  caretOffset,
  caretSize,
  backgroundColor,
  showCaretBorder,
}: TooltipDefaultCaretProps): JSX.Element => {
  const color = backgroundColor || "white";

  // init with default values for "bottom"
  let attachPoint = "center top";
  let targetAttachPoint = `${caretOffset} bottom`;
  let direction = "down";
  let triangleStyle: React.CSSProperties = { marginTop: "-2px" };

  if (caret === "top") {
    attachPoint = "center bottom";
    targetAttachPoint = `${caretOffset} top`;
    direction = "up";
    triangleStyle = { marginBottom: "-1px" };
  } else if (caret === "left") {
    attachPoint = "right middle";
    targetAttachPoint = `left ${caretOffset}`;
    direction = "left";
    triangleStyle = { marginRight: "-2px" };
  } else if (caret === "right") {
    attachPoint = "left middle";
    targetAttachPoint = `right ${caretOffset}`;
    direction = "right";
    triangleStyle = { marginLeft: "-2px" };
  }

  return (
    <>
      {showCaretBorder === true && (
        <AbsolutePositioner attachPoint={attachPoint} targetAttachPoint={targetAttachPoint}>
          <Triangle direction={direction} color="#dadada" size={caretSize} />
        </AbsolutePositioner>
      )}
      <AbsolutePositioner attachPoint={attachPoint} targetAttachPoint={targetAttachPoint}>
        <Triangle style={triangleStyle} direction={direction} color={color} size={caretSize} />
      </AbsolutePositioner>
    </>
  );
};

const _styles = {
  wrapper: {
    maxWidth: "25rem",
    display: "inline-block",
    position: "relative" as const,
  },
  container: {
    boxShadow: shadows.strong,
    backgroundColor: "white",
    borderRadius: "5px",
    textAlign: "left",
  },
};
