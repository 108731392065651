/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type MicrophoneIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let MicrophoneIcon: NessieFunctionComponent<MicrophoneIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.64 17.04h.64c2.08 0 3.8-1.72 3.8-3.8V5.88c0-2.08-1.72-3.8-3.8-3.8h-.64c-2.08 0-3.8 1.72-3.8 3.8v7.36c0 2.08 1.72 3.8 3.8 3.8zm-1.48 1.36h3.6c1.52 0 2.8 1.24 2.72 2.8 0 .36-.28.68-.68.68H8.04c-.36 0-.68-.28-.68-.68 0-1.56 1.28-2.8 2.8-2.8zm1.48-14.92c-1.32 0-2.4 1.08-2.4 2.4v6.4h5.44v-6.4c0-1.32-1.08-2.4-2.4-2.4h-.64z"
          />
          <mask maskUnits="userSpaceOnUse" x={7} y={2} width={10} height={20}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.64 17.04h.64c2.08 0 3.8-1.72 3.8-3.8V5.88c0-2.08-1.72-3.8-3.8-3.8h-.64c-2.08 0-3.8 1.72-3.8 3.8v7.36c0 2.08 1.72 3.8 3.8 3.8zm-1.48 1.36h3.6c1.52 0 2.8 1.24 2.72 2.8 0 .36-.28.68-.68.68H8.04c-.36 0-.68-.28-.68-.68 0-1.56 1.28-2.8 2.8-2.8zm1.48-14.92c-1.32 0-2.4 1.08-2.4 2.4v6.4h5.44v-6.4c0-1.32-1.08-2.4-2.4-2.4h-.64z"
            />
          </mask>
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MicrophoneIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  MicrophoneIcon = nessiePropCheckerWrapper(MicrophoneIcon);
}

export default MicrophoneIcon;
