/** @jsxImportSource theme-ui */
import { isProd } from "../../utils/env";

/**
 * This can be used to wrap any value that is not a design token from the theme,
 * and it will bypass validation. This allows for one of designs to be implemented,
 * while at the same time, make it explicit that the values are not standard and
 * make it easy to search for
 */
export const RAW_cssValue = (val?: string | number | null): any => {
  if (isProd) {
    return val;
  } else {
    return new __CssValueClass(val);
  }
};

export class __CssValueClass {
  val?: string | number | null;
  constructor(val?: string | number | null) {
    this.val = val;
  }
}
