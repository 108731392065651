/** @jsxImportSource theme-ui */
import { StylingLibCSSObject } from "../stylingLib";
import { Space } from "./Space";
import { BodyText } from "./Text";

type RadioInputProps = {
  id: string;
  name: string; // same by group
  labelText?: string;
  detail?: React.ReactNode;
  inputPosition?: "right" | "left";
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  ["data-test-name"]?: string;
  checked: boolean;
  className?: string;
  labelDecorator?: React.ReactNode;
  describedById?: string;
};

export const RadioInput = ({
  id,
  name,
  labelText,
  detail,
  onChange,
  inputPosition = "left",
  value,
  disabled,
  checked,
  ["data-test-name"]: dataTestName,
  className,
  labelDecorator,
  describedById,
}: RadioInputProps) => {
  const wrapperStyles: StylingLibCSSObject =
    inputPosition === "right"
      ? { flexDirection: "row-reverse", justifyContent: "space-between" }
      : { flexDirection: "row", justifyContent: "normal" };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange(value);
  };
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "flex-start",
        ...wrapperStyles,
      }}
      // adding classname so devs can style input wrapper
      className={className}
    >
      <div sx={inputRadioContainerStyles}>
        <input
          id={id}
          type="radio"
          value={value}
          disabled={disabled}
          onChange={handleChange}
          data-test-name={dataTestName}
          checked={checked}
          name={name}
          sx={invisibleInputStyles}
          aria-describedby={describedById}
        />
        <div sx={inputRadioStyles}>
          <div sx={inputRadioCenterStyles}></div>
        </div>
      </div>
      <Space kind="inline" size="s" />
      <div>
        <div sx={{ display: "flex", alignItems: "baseline" }}>
          {labelText && (
            <BodyText as="label" htmlFor={id}>
              {labelText}
            </BodyText>
          )}
          {/* need to set lineHeight of whatever element comes to be the same as BodyText */}
          <div sx={{ ">*": { lineHeight: "22px" } }}>{labelDecorator && labelDecorator}</div>
        </div>
        <Space size="xxs" />
        {detail && detail}
      </div>
    </div>
  );
};

const inputRadioContainerStyles: StylingLibCSSObject = {
  position: "relative",
  width: "24px",
  height: "24px",
  borderRadius: "dt_radius_round",
  flex: "none",
  "[type='radio']:focus + div": {
    outline: "2px solid",
    outlineColor: "dt_blueberry60",
    outlineOffset: "2px",
  },
  "[type='radio']:not(:focus-visible) + div": {
    outline: "none", // allowing only keyboard focus
  },
  "[type='radio']:checked + div": {
    borderColor: "dt_aqua50",
  },
  "[type='radio']:checked + div > div": {
    opacity: 1,
    transform: "scale(1)",
  },
  "[type='radio']:disabled + div": {
    backgroundColor: "dt_taro30",
    width: "100%",
    height: "100%",
    opacity: 1,
    transform: "scale(1)",
  },
  // Disabled check mode is not a state we should use but it is part of the design
  "[type='radio']:disabled": {
    ":checked + div": {
      borderColor: "dt_taro60",
    },
    ":checked + div > div": {
      backgroundColor: "dt_taro60",
      opacity: 1,
      transform: "scale(1)",
    },
  },
};

const invisibleInputStyles: StylingLibCSSObject = {
  position: "absolute",
  opacity: 0,
  width: "100%",
  height: "100%",
  cursor: "pointer",
};

const inputRadioStyles: StylingLibCSSObject = {
  width: "100%",
  height: "100%",
  border: "2px solid",
  borderRadius: "dt_radius_round",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderColor: "dt_taro40",
};

const inputRadioCenterStyles: StylingLibCSSObject = {
  width: "16px",
  height: "16px",
  backgroundColor: "dt_aqua50",
  borderRadius: "dt_radius_round",
  transition: "all 0.2s ease",
  opacity: 0,
  transform: "scale(0)",
};
