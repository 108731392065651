/** @jsxImportSource theme-ui */
import * as React from "react";
import PropTypes from "prop-types";
import Styles from "../commonStyles";

interface TileProps extends React.HTMLProps<HTMLDivElement> {
  width?: string;
  padding?: string;
  margin?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Tile = ({ padding, margin, width, style, ...props }: TileProps) => {
  const computedStyle = { ...styles.tile, ...{ margin, padding, width }, ...style };
  return <div style={computedStyle} {...props} />;
};
Tile.propTypes = {
  width: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  style: PropTypes.object,
};

const styles = {
  tile: {
    border: `1px solid ${Styles.palette.gray20}`,
    boxShadow: Styles.shadows.regular,
    borderRadius: "5px",
    backgroundColor: "white",
  },
};
