/** @jsxImportSource theme-ui */
import * as React from "react";
// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import {
  NessieFunctionComponent,
  NessieComponentProps,
  colorNessieResponsivePropType,
  oneOfNessieResponsivePropType,
} from "../../propTypes";
import { NessieThemeColors } from "../../theme";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
type MergeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    "data-test-name"?: string;
    "aria-label"?: string;
  };

let MergeIcon: NessieFunctionComponent<MergeIconProps> = ({
  size = "m",
  color = "shale",
  onClick,
  cursor,
  display = "inline-block",
  ["data-test-name"]: dataTestName,
  ["aria-label"]: ariaLabel,
  ...props
}) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-test-name={dataTestName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          {...props}
        >
          <path
            d="M17.517 11.457V9.879h-2.455c-.806 0-1.31.323-1.746.902-.49.65-.84 1.552-1.265 2.653l-.01.025c-.392 1.02-.856 2.225-1.59 3.158-.792 1.011-1.916 1.728-3.531 1.728H3.018a1.058 1.058 0 010-2.116H6.92c.873 0 1.414-.342 1.867-.918.506-.646.868-1.545 1.289-2.638l.047-.122c.38-.99.825-2.146 1.503-3.045.761-1.009 1.85-1.744 3.436-1.744h2.455V6.184a.53.53 0 01.85-.421l3.464 2.636a.53.53 0 010 .843l-3.465 2.636a.53.53 0 01-.85-.42zm-7.634-3.69c.536 0 1.042.561 1.043 1.056 0 .496-.52 1.06-1.043 1.06-8.848 0-4.224-.003-6.865-.004a1.056 1.056 0 01-1.059-1.056 1.06 1.06 0 011.059-1.06z"
            clipRule="evenodd"
            fillRule="evenodd"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};

const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MergeIcon.nessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(["xs", "s", "m", "l", "xl", "xxl"]),
}; // ------------------------------------------
// ------------------------------------------
// DEV ONLY better prop checking

if (process.env.NODE_ENV === "development") {
  MergeIcon = nessiePropCheckerWrapper(MergeIcon);
}

export default MergeIcon;
