/** @jsxImportSource theme-ui */
import omit from "lodash/omit";
import uniqueId from "lodash/uniqueId";
import { useState, InputHTMLAttributes } from "react";
import { Space } from "../../nessie";
import { StylingLibCSSObject } from "../../nessie/stylingLib";

/**
 * FIXME: Improve this and rename is confusing and only being used for radio/checkbox
 * Component for combining a label with a radio or checkbox input
 */

type SettingsInputsProps = InputHTMLAttributes<HTMLInputElement> & {
  inputSx?: StylingLibCSSObject;
  labelSx?: StylingLibCSSObject;
};

export default function SettingsInputs(props: SettingsInputsProps) {
  const [defaultId] = useState(() => uniqueId("SettingsInputs"));
  const id = props.id || defaultId;

  return (
    <div sx={{ display: "flex" }}>
      <input {...omit(props, "inputSx", "labelSx", "children")} id={id} sx={props.inputSx} />
      <Space kind="inline" size="dt_s" />
      <label htmlFor={id} sx={props.labelSx}>
        {props.children}
      </label>
    </div>
  );
}
