import PropTypes from "prop-types";
import extend from "lodash/extend";
import { translatablePropTypes } from "./translatable";

export const buttonPropTypes = extend(
  {
    amplitudeKey: PropTypes.string,

    // Deprecated prop, please remove this once the Link is not relying anymore
    // on react-router for automated navigation.
    route: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    allowDoubleClicks: PropTypes.bool,

    // Icons
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    // Color palettes
    danger: PropTypes.any,
    secondary: PropTypes.any,
    disabled: PropTypes.any,
    success: PropTypes.any,
    color: PropTypes.string, // hex string

    // States
    completed: PropTypes.bool, // when an action has been taken and a success message is displayed

    // Styles
    link: PropTypes.any,
    nav: PropTypes.any,
    inverted: PropTypes.any,
    fullWidth: PropTypes.any,
    caret: PropTypes.any,
    round: PropTypes.any,
    light: PropTypes.any,

    /**
     * A css height value specified as string.
     * IMPORTANT:
     * Not intended to be used by consumers of the component library, only
     * exposed for other components that are building an abstraction using
     * a button component and need to change their height.
     */
    dangerouslySetHeight: PropTypes.string,

    highContrast: PropTypes.bool,

    "data-test-name": PropTypes.string,
  },
  translatablePropTypes,
);

export const buttonValidator = PropTypes.shape(buttonPropTypes);
